.news-page {
  background: $box-color;
  padding: $dist;

  @include breakpoint(null, $bp-l) {
    padding: 20px;
  }

  &__preview-text {
    @include resp-margin(bottom);

    p {
      font-size: $fs-h3;
    }
  }

  small {
    font-size: $fs-tiny;
    font-weight: $fw-medium;
    &:after {
      content: "";
      height: 2px;
      width: $dist*3;
      background: $box-color-dark;
      display: block;
      margin-bottom: $dist*0.5;
      margin-top: $dist*0.5;
    }
  }

  &__text {
    @extend %cf;

    .news-page__image {
      display: block;
      width: span(4 of 12);
      float: right;
      margin-left: $dist*0.5;
      margin-bottom: $dist*0.25;
      @include image-hover();

      @include breakpoint(null, $bp-s) {
        width: 100%;
        margin-left: 0;
        margin-bottom: 0;
        float: none;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
        max-width: unset !important;
      }
    }
  }

  &__meta {
    margin-top: $dist*0.5;
    padding-top: $dist*0.5;
    border-top: 2px solid $border-color-dark;
    font-size: $fs-tiny;
    color: $fc;

    a {
      color: $fc;
      text-decoration: none;

      &:hover {
        color: $ac;
      }
    }

    ul {
      @extend %cf;
      margin: $dist*0.25 0 0 0;
      padding: 0;

      li {
        display: block;

        &.news-page__prev {
          float: left;
          font-size: 0;

          a:before {
            @include fontawesome($fa-var-long-arrow-alt-left);
            font-weight: $fw-medium;
            position: relative;
            bottom: -1px;
            font-size: $fs;
          }
        }

        &.news-page__next {
          float: right;
          font-size: 0;

          a:after {
            @include fontawesome($fa-var-long-arrow-alt-right);
            font-weight: $fw-medium;
            position: relative;
            bottom: -1px;
            font-size: $fs;
          }
        }
      }
    }
  }
}