.navigation {
	position: relative;
	z-index: 500;

	// - default
	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			position: relative;
			line-height: 1;
			font-size: $fs;

			&[class*="--current"],
			&[class*="--section"],
			&:hover {
				& > a {
					color: $ac;
				}
			}

			a {
				display: block;
				color: $fc;
				white-space: nowrap;
				text-decoration: none;
				line-height: 1;
			}
		}
	}

	.navigation__sub {
		display: block;
		position: absolute;
		opacity: 0;
		visibility: hidden;
		transition: opacity 0.1s linear, visibility 0.1s linear;
		background: $ac;

		&-header{
			display: none;
		}

		li {
			text-align: left;
		}
	}

	// - vertical navigation
	&--vertical {
		.navigation__sub {
			transform: translateX(100%);
			top: 0;
			right: 0;
		}

		ul {
			li {
				&:hover {
					& > .navigation__sub {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
	}

	// - horizontal navigation
	&--horizontal {
		.navigation__top {
			font-size: 0;
			text-align: right;
		}

		.navigation__top-link {
			display: inline-block;
			&:hover {
				& > .navigation__sub {
					opacity: 1;
					visibility: visible;
					top: $fs;
					
				}
			}

			& > .navigation__sub {
				left: 0;
			}

			.navigation__sub {
				min-width: 100%;

				.navigation__sub {
					transform: translateX(100%);
					top: 0;
					right: 0;
				}

				li {
					&:hover {
						& > .navigation__sub {
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}

			&:nth-last-of-type(-n+2) {
				& > .navigation__sub {
					left: auto;
					right: 0;

					.navigation__sub {
						transform: translateX(-100%);
						right: auto;
						left: 0;
					}
				}
			}
		}
	}

	.mobile-navigation-button {
		display: none;
		cursor: pointer;
		font-size: $fs-h3;
		color: $fc;
		float: right;

		&:hover {
			color: $ac;
		}
	}

	&--main {
		.navigation__top-link {
			font-size: $fs;
			font-weight: $fw-h-medium;
			background: $box-color-dark;

			&:hover {
				background: $ac;

				a {
					color: #FFFFFF;
				}
			}

			&--current,
			&--section {
				background: $ac;

				a {
					color: $page-bg !important;
				}
			}

			a {
				display: block;
				color: #FFFFFF;
				line-height: 45px;
				height: 45px;
				padding: 0 $dist*0.5;
			}
		}

		&.navigation--horizontal {
			.navigation__top-link {
				&:hover {
					& > .navigation__sub {
						//top: $fs-h3;
						top: 2.8rem;
					}
				}
			}
		}

		@include breakpoint(null, $bp-nav) {
			.mobile-navigation-button {
				display: block;
				width: 40px;
				height: 40px;
				line-height: 40px;
				border: 3px solid $ac;
				text-align: center;

				i {
					line-height: 40px;
					display: block;
					margin-top: -0.125em
				}
			}

			.navigation__top {
				display: none;
			}
		}
	}

	&--header {
		@include breakpoint(null, $bp-nav) {
			display: none;
		}

		.navigation__top {
			&-link {
				margin-right: $dist*0.5;

				&:last-of-type {
					margin-right: 0;
				}

				a {
					font-weight: $fw-bold;
					font-size: $fs-small;
				}
			}
		}
	}

	&--footer {
		ul {
			li {
				margin-right: $dist*0.5;

				&:last-of-type {
					margin-right: 0;
				}
			}
		}
	}

	&--mobile {
		// - overwrite defaults
		ul {
			li {
				@extend %cf;

				&:hover {
					& > .navigation__sub {
						display: none;
						transform: translateX(0);
					}
				}
			}
		}

		.navigation__sub {
			clear: both;
			position: static !important;
			display: none;
			border-top: 1px solid $border-color;
			opacity: 1 !important;
			visibility: visible !important;
			transform: none !important;

			&--active {
				display: block !important;
			}

			.navigation__sub-link {
				@extend %cf;
				border-bottom: 1px solid $border-color;

				&--has-sub {
					& > a {
						float: left;
						width: calc(100% - 1em - #{$dist*0.5});
					}

					& > .navigation__sub-toggle {
						padding: $dist*0.25;
						width: calc(1em + #{$dist*0.5});
					}
				}

				&:last-of-type {
					border-bottom: 0;
				}
			}
		}

		.navigation__sub-toggle {
			display: block;
			float: left;
			cursor: pointer;
			color: $fc-light;
			line-height: 1;

			&:hover,
			&--active {
				color: $fc;
			}

			&--active {
				i:before {
					content: fa-content($fa-var-minus-circle);
				}
			}
		}

		// - mobile navigation styling | level 1
		.navigation__top-link {
			border-bottom: 1px solid $border-color;

			& > a {
				padding: $dist*0.5;
				font-weight: $fw-bold;
			}

			&--has-sub {
				& > a {
					float: left;
					width: calc(100% - 1em - #{$dist*0.5});
				}
			}

			& > .navigation__sub-toggle {
				padding: $dist*0.5 $dist*0.25;
				width: calc(1em + #{$dist*0.5});
			}

			// - level 2
			& > .navigation__sub {
				a {
					padding: $dist*0.25 $dist*0.5;
					color:white;
				}

				& > li {
					// - level 3
					& > .navigation__sub {
						& > li {
							& > a {
								background: darken($page-bg, 5%);
							}

							& > .navigation__sub-toggle {
								background: darken($page-bg, 5%);
							}

							// - level 4
							& > .navigation__sub {
								& > li {
									& > a {
										background: darken($page-bg, 10%);
									}
								}
							}
						}
					}
				}
			}
		}
	}
}