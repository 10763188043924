#grasenhiller_homepage {
	.modular-content {
		.module--textimage {
			background: $box-color-dark;
			@include resp-padding(h);
			@include resp-padding(v);

			&,
			& * {
				color: #FFFFFF;
			}

			.textimage__title {
				&:after {
					content: "";
					display: block;
					width: $dist*3;
					margin: $dist 0;
					height: 2px;
					background: $page-bg;

					@include breakpoint(null, $bp-m) {
						margin: $dist*0.5 0;
					}
				}
			}

			.textimage__image {
				img {
					background: $box-color-dark;
					border: 0;
				}
			}
		}
	}
}