@use "sass:math";

#grasenhiller_homepage {
  .page-slider {
    .page-slider__caption {
      display: none !important;
    }

    .slick-arrow {
      @include center(v);
      z-index: 20;
      display: block;
      color: #ffffff;
      font-size: $fs-small;
      padding: $dist*0.5 17px;
      background: $box-color-dark;
      font-weight: $fw-medium;
      cursor: pointer;

      @include breakpoint(null, $bp-nav) {
        display: none !important;
      }

      &:hover {
        background: $ac;
      }

      &.fa-chevron-left {
        left: 3px;
      }

      &.fa-chevron-right {
        right: 3px;
      }
    }

    .slick-dots {
      position: absolute;
      z-index: 20;
      bottom: $dist*0.5;
      top: 20px;
      right: 20px;
      font-size: 0;
      text-align: center;

      li {
        display: inline-block;
        margin-right: math.div($dist, 6);

        &:last-of-type {
          margin-right: 0;

          &:first-of-type {
            display: none;
          }
        }

        button {
          cursor: pointer;
          display: block;
          width: math.div($dist, 1.5);
          height: math.div($dist, 1.5);
          background: $box-color-dark;
          border-radius: 100%;
          font-size: 0;
          border: 0;
          box-shadow: none;

          &:hover {
            background: $ac;
          }
        }

        &.slick-active {
          button {
            background: $ac;

            &:hover {
              background: darken($hc, 10%);
            }
          }
        }
      }
    }

    &__slide {
      position: relative;

      img {
        display: block;
        width: 100%;
        height: auto;
        border: 3px solid $ac;

        &[data-lazy] {
          display: none;
        }
      }
    }
  }
}

.page--banner-small {
  .page-slider {
    margin-bottom: $dist;

    .slick-arrow {
      @include center(v);
      z-index: 20;
      color: #ffffff;
      font-size: $fs-h3;
      display: none;

      &:hover {
        color: $ac;
      }

      &.fa-chevron-left {
        left: $dist*0.5;
      }

      &.fa-chevron-right {
        right: $dist*0.5;
      }
    }

    .slick-dots {
      position: absolute;
      z-index: 20;
      bottom: $dist*0.5;
      left: 0;
      right: 0;
      font-size: 0;
      text-align: center;
      display: none;

      li {
        display: inline-block;
        margin-right: 2px;

        &:last-of-type {
          margin-right: 0;

          &:first-of-type {
            display: none;
          }
        }

        button {
          cursor: pointer;
          display: block;
          width: math.div($dist, 3);
          height: math.div($dist, 3);
          background: $box-color;
          border-radius: 100%;
          font-size: 0;
          border: 0;
          box-shadow: none;

          &:hover {
            background: $box-color-dark;
          }
        }

        &.slick-active {
          button {
            background: $hc;

            &:hover {
              background: darken($hc, 10%);
            }
          }
        }
      }
    }

    &__slide {
      position: relative;
      background: $box-color-dark;
      @extend %cf;

      img {
        display: block;
        height: auto;
        float: right;
        width: 50%;

        @include breakpoint(null, $bp-m) {
          width: 100%;
        }
      }
    }

    &__caption {
      width: 50%;
      float: left;
      background: $box-color-dark;
      padding: $dist;

      @include breakpoint(null, $bp-m) {
        padding: 20px;
      }

      @include breakpoint(null, $bp-m) {
        width: 100%;
      }
    }

    &__caption-content {

      .banner-description  {
        display: none;
      }

      & > h3,
      & > h1 {
        font-size: $fs-h1;
        font-weight: $fw-h-medium;
        font-family: $ff-h;
        color: #ffffff;
        text-transform: uppercase;

        &:after {
          content: "";
          width: $dist*3;
          height: 2px;
          background: $page-bg;
          display: block;
          margin-top: $dist;

          @include breakpoint(null, $bp-m) {
            margin-top: $dist*0.5;
          }
        }

        @include breakpoint(null, $bp-m) {
          font-size: $fs-h2;
        }
      }

      & > p {
        @include resp-margin(top);
        font-size: $fs-h5;
        font-weight: $fw;
        color: #ffffff;
        max-width: math.div($max-width, 1.5);

        @include breakpoint(null, $bp-m) {
          font-size: $fs;
        }

        span {
          box-decoration-break: clone;
        }
      }

      & > a {
        @include resp-margin(top);
        display: inline-block;
        color: $page-bg;
        font-size: $fs-h5;

        &:before {
          @include fontawesome($fa-var-long-arrow-alt-right);
          font-weight: $fw-medium;
          margin-right: math.div($dist, 6);
        }

        @include breakpoint(null, $bp-m) {
          font-size: $fs;
        }
      }

      &.page-slider__caption-content--font-color-light {
        & > h3,
        & > h1,
        & > p,
        & > a {
          color: #ffffff;
        }
      }
    }
  }
}
