#grasenhiller_productlandingpage {
  .page-title {
    display: none;
  }

  main .page-title {
    display: block;
  }

  .page-body {
    display: flex;
    flex-direction: column;

    margin-top: $dist;
  }

  .page-header {
    margin-top: $dist;

    img {
      @include resp-img();
    }
  }

  .page-content {
    .page-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @include breakpoint(null, $bp-xl) {
        flex-direction: column-reverse;
      }
    }

    .page-sidebar {
      margin-top: $dist;

      @include breakpoint($bp-xl, null) {
        width: 150px;
      }

      @include breakpoint(null, $bp-xl) {
        position: static;

        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
      }

      a {
        width: 100%;
        margin-bottom: $dist * 0.5;

        :last-child {
          margin-bottom: 0;
        }

        @include image-hover();

        @include breakpoint(null, $bp-xl) {
          width: 23%;
          height: 100%;
        }

        img {
          @include resp-img();

          @include breakpoint(null, $bp-xl) {
            width: 100%;
            height: 100%;
          }
        }

        p {
          padding: $dist * 0.25;
          background: $box-color;
        }
      }
    }

    .page-text {
      margin-top: $dist;

      @include breakpoint($bp-xl, null) {
        max-width: calc(100% - 150px - $dist);
      }
    }

    .page-modules {
      margin-top: $dist;
    }

    .page-products {
      &__item {
        margin-top: $dist;

        h2 {
          font-size: $fs-h4;
          margin-bottom: $dist * 0.5;
        }

        .products-per-page {
          display: none;
        }
      }
    }
  }

  .contact-box {
    border: 2px solid $border-color-dark;
    padding: $dist * 0.5;
    margin-top: $dist;
    background: $box-color;
  }
}
