.modular-content {
	.module--form {
		border: 3px solid $border-color-dark;
		padding: $dist;

		@include breakpoint(null, $bp-l) {
			padding: 20px;
		}

		.module__title {
			margin-bottom: $dist*0.5;
			text-transform: uppercase;
		}
	}
	#UserForm_Form_40 {

		.form-step {
			@extend %cf;

			.field {
				width: calc(50% - #{$dist * 0.5 });
				float: left;

				@include breakpoint(null, $bp-nav) {
					width: 100%;
					margin-right: 0;
				}

				&:first-of-type {
					margin-right: $dist;
				}

				input {
					width: 100%;
				}
			}

			.checkbox {
				width: 100% !important;
			}
		}

		nav {
			text-align: right;
		}
	}
}