@use "sass:math";

#grasenhiller_wikiholder {
  .wikiholder-navigation {
    @include resp-margin(bottom, 1.5);

    position: relative;
    padding: $dist*0.5;
    border: 1px solid $border-color;

    ul {
      display: flex;
      justify-content: space-between;
      overflow-x: scroll;
      margin: 0 $dist*0.25;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none !important;
      -ms-overflow-style: -ms-autohiding-scrollbar;

      &::-webkit-scrollbar {
        display: none;
      }

      li {
        position: relative;
        flex-grow: 1;
        text-align: center;
        cursor: default;
        color: $fc-light;

        &:not(:first-of-type) {
          margin-left: $dist*0.5;
        }

        a {
          position: relative;
          display: block;
          transition: transform $duration ease-in;
          font-weight: $fw-bold;
        }

        a:hover {
          cursor: pointer;
          transform: scale(1.5);
        }
      }
    }

    @include breakpoint(null, 700px) {
      padding-right: $dist * 1.3;

      &:after {
        @include fontawesome($fa-var-arrow-right);
        @include center(v);

        position: absolute;
        right: 0;
        margin-right: $dist*0.5;
        font-size: 0.9em;
      }
    }
  }

  .wikiholder-items {
    padding: $dist*0.5;

    &--grouped {
      &:not(:first-child) {
        @include resp-margin(top, 1.5);
      }

      h3 {
        font-size: $fs-h3;
        margin-bottom: $dist*0.5;
        display: inline-block;
        position: relative;

        &:after {
          content: "";
          position: relative;
          min-width: $dist;
          width: 100%;
          height: 2px;
          background: $fc;
          display: block;
          margin-top: 15px;
        }
      }

      ul {
        li {
          font-size: $fs-h6;
          font-weight: $fw-medium;

          &:not(:first-of-type) {
            margin-top: math.div($dist, 3);
          }

          a {
            &:after {
              @include fontawesome($fa-var-long-arrow-right);
              margin-left: 0.5em;
              opacity: 0;
              transition: all $duration ease-in;
            }

            &:hover {
              &:after {
                opacity: 1;
                margin-left: 1em;
              }
            }
          }
        }

        @include breakpoint($bp-s) {
          column-count: 2;
          column-gap: $dist*0.5;
        }
      }
    }
  }
}