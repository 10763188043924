.news-preview {
  @extend %cf;
  @include resp-margin(bottom);
  background: $box-color;
  padding: $dist;

  &:last-of-type {
    margin-bottom: 0;
  }

  @include breakpoint(null, $bp-l) {
    padding: 20px;
  }

  @include breakpoint($bp-m, null) {
    &__image {
      width: span(4 of 12);
      float: left;
      margin-right: gutter(12);
    }

    &__text {
      width: span(8 of 12 last);
      float: left;
    }
  }

  @include breakpoint(null, $bp-m) {
    &__image {
      margin-bottom: $dist*0.5;
    }
  }

  &__image {
    a {
      display: block;

      &:hover {
        text-decoration: none;
        border-bottom: 0;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__text {

    small {
      font-size: $fs-tiny;
      font-weight: $fw-medium;
      text-transform: uppercase;
    }

    & > h2 {
      margin-bottom: 0;
      margin-top: 0 !important;
      text-transform: uppercase;

      &:before {
        content: "";
        height: 2px;
        background: $box-color-dark;
        width: $dist*3;
        display: block;
        margin: $dist*0.5 0;
      }

      a {
        color: $fc !important;
        text-decoration: none !important;

        &:hover {
          background: none !important;
        }
      }
    }

    & > p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    & > a {
      display: inline-block;
      @include resp-margin(top, 0.5);
      font-size: 0;
      color: $fc !important;

      &:before {
        @include fontawesome($fa-var-long-arrow-alt-right);
        font-weight: $fw-medium;
        font-size: $fs;
      }

      &:hover {
        background: none !important;
        color: $ac !important;
      }
    }
  }
}