// - Only in Columns above breakpoint
@include breakpoint($bp-columns, null) {
  .modular-content-column > .module--form {
    .module__title {
      margin-bottom: calc(#{$dist * 1.5} - 0.5rem);
    }

    div.field {
      &.size-1-of-3 {
        width: span(2 of 6);
        margin-right: gutter(6);
      }

      &.size-1-of-2 {
        width: span(3 of 6);
        margin-right: gutter(6);
      }

      &.size-2-of-3 {
        float: left;
        width: span(4 of 6);
        margin-right: gutter(6);
      }
    }
  }
}