@use "sass:math";

.navigation__box {
	position: relative;
	width: 100%;
	border: 3px solid $ac;
	border-bottom: 2px solid $ac;
	margin-bottom: $dist;

	&:after {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 1px;
		background: $ac;
		z-index: 50;
	}

	&-item {
		&--current {
			background: $ac;
			color: #FFFFFF;

			& * {
				color: #FFFFFF;
			}

			span:after {
				background: #FFFFFF !important;
			}
		}
	}

	.mobile-box-button {
		width: 100%;
		text-align: center;
		padding: math.div($dist, 3);

		@include breakpoint($bp-nav, null) {
			display: none;
		}

		span {
			color: $ac;
		}
	}

	i {
		display: block;
		color: $ac;
		font-size: $fs-h5;
		width: 100%;
		text-align: center;
		padding-bottom: math.div($dist, 3);

		@include breakpoint($bp-nav, null) {
			display: none;
		}
	}

	i.fa-angle-up {
		display: none;
	}

	ul {
		@extend %cf;

		@include breakpoint(null, $bp-nav) {
			display: none;
		}

		li {
			float: left;
			width: 25%;
			margin: 0  !important;
			display: block;
			border-right: 1px solid $border-color;
			border-bottom: 1px solid $border-color;

			@include breakpoint($bp-m, null) {
				&:nth-child(4n) {
					border-right: 0;
				}
			}

			@include breakpoint($bp-nav, $bp-m) {
				width: calc(100% / 3);

				&:nth-child(3n) {
					border-right: 0;
				}
			}

			@include breakpoint(null, $bp-nav) {
				width: 50%;

				&:nth-child(2n) {
					border-right: 0;
				}
			}

			a {
				display: block;
				width: 100%;
				height: 100%;
				padding: $dist*0.5;

				@include breakpoint(null, $bp-l) {
					padding: math.div($dist, 3);
				}

				&:hover {
					background: $ac;
					color: $page-bg;

					span {
						&:after {
							background: $page-bg;
						}
					}
				}

				span {
					font-size: $fs-tiny;
					font-weight: $fw-medium;

					&:after {
						content: "";
						height: 2px;
						display: block;
						width: $dist*3;
						background: $fc;
						margin: $dist*0.5 0 12px 0;

						@include breakpoint(null, $bp-l) {
							margin: math.div($dist, 3) 0;
						}
					}
				}

				h3 {
					text-transform: uppercase;
					font-size: $fs-h3;

					@include breakpoint(null, $bp-l) {
						font-size: $fs-h4;
					}

					@include breakpoint(null, $bp-m) {
						font-size: $fs;
					}

					@include breakpoint(null, $bp-s) {
						font-size: $fs-small;
					}
				}
			}
		}
	}
}

@include breakpoint(null, $bp-nav) {
	.navigation__box-active {
		.mobile-box-button {
			border-bottom: 1px solid $border-color;
		}

		ul {
			display: block;
		}

		i.fa-angle-up {
			border-top: 1px solid $border-color;
			padding-top: math.div($dist, 3);
			display: block;
			margin-top: -1px;
		}

		i.fa-angle-down {
			display: none;
		}
	}
}