#silverstripe_userdefinedform {
	.main {
		@extend %cf;

		@include breakpoint($bp-m, null) {
			form {
				float: left;
				margin-right: gutter(12);
				width: span(8 of 12);
				clear: none;
				margin-top: 0;
				padding-right: gutter(8) * 2;

				& + .main__text {
					width: span(4 of 12);
					float: left;
				}
			}
		}

		@include breakpoint(null, $bp-m) {
			form {
				& + .main__text {
					margin-top: $dist;
				}
			}
		}
	}
}