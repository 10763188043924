@use "sass:math";

@import "_variables";
@import "_mixins";

// - Clearfix
%cf {
	*zoom: 1;

	&:before {
		content: "";
		display: table;
	}

	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

.cf {
	@extend %cf;
}

// - Decent Link
%decent-link {
	& {
		color: $fc;
		text-decoration: none;

		&:hover {
			color: $ac;
			text-decoration: underline;
		}
	}
}

// - Container
%outer-container {
	width: calc(100% - #{$dist*0.5});
	max-width: $max-width;
	margin-left: auto;
	margin-right: auto;
}

// - Buttons
%button {
	display: inline-block;
	color: #FFFFFF;
	padding: math.div($dist, 3) $dist*0.5;
	background: $box-color-dark;
	transition: all linear $duration;
	cursor: pointer;
	text-decoration: none;
	border: 0;
	line-height: $line-height;
	font-weight: $fw-light;

	&:hover {
		background: $ac;
		color: #ffffff;
	}

	&:focus {
		outline: none;
		border: none;
	}
}

%button--small {
	font-size: $fs-small;
}

%button--white {
	background: #FFFFFF;
	color: $fc;

	&:hover {
		color: #FFFFFF;
	}
}

%button--neutral {
	background: $ac-sec;
	color: #ffffff;

	&:hover {
		color: #ffffff;
		background: $ac-sec-hover;
	}
}

.button {
	@extend %button;

	&--small {
		@extend %button--small;
	}

	&--white {
		@extend %button--white;
	}

	&--neutral {
		@extend %button--neutral;
	}
}

// - Styling für Tabellen
@mixin table-styling {
	table {
		border-collapse: collapse;
		border-spacing: 0;
		width: 100%;
		max-width: 100%;

		thead {
			th {
				border-bottom: 2px solid $border-color !important;
				border-right: 0 !important;
			}

			& + tbody {
				tr {
					th:first-child {
						border-right: 0;
					}
				}
			}
		}

		tfoot {
			th {
				border-top: 2px solid $border-color;
				border-right: 0 !important;
				border-bottom: 0 !important;
			}
		}

		td {
			border-bottom: 1px solid $border-color;
		}

		th,
		td {
			text-align: left;
			padding: 0.5rem 0.75rem;

			& > *:first-child {
				margin-top: 0 !important;
			}
		}

		tr {
			th:first-child {
				border-right: 2px solid $border-color;
				border-bottom: 1px solid $border-color;
			}
		}
	}

	.table--two-heads {
		table {
			tbody {
				tr {
					th:first-child {
						border-right: 2px solid $border-color !important;
						border-bottom: 1px solid $border-color;
					}
				}
			}
		}
	}

	.table--striped tbody tr:nth-child(odd) {
		background-color: $box-color;
	}

	.table--unstyled {
		width: 100%;

		tr, th, td {
			border: 0 !important;
			background: 0 !important;
			padding: 0 !important;
		}

		tr {
			td,
			th {
				padding: 0 0.25rem 0 0!important;

				&:last-of-type {
					padding-right: 0 !important;
				}
			}
		}
	}
}

.table--responsive {
	overflow-x: auto;
}

@include breakpoint(null, $bp-m) {
	.table--responsive {
		width: 100%;

		th,
		td {
			white-space: nowrap;
		}
	}
}

// - Formatierter Text
%formatted-text {
	$margin-top: 0.75em;
	$margin-top-big: 1em;
	$margin-top-huge: 1.5em;

	.text--button {
		@extend %button;
	}

	.text--button-2 {
		@extend %button;
		@extend %button--neutral;
	}

	ul.list--checklist {
		list-style: none;
		margin-left: 0 !important;

		li {
			display: block;
			@include icon-before($fa-var-check);

			&:before {
				margin-top: 0.125em;
			}
		}
	}

	.text--discreet {
		color: $fc-light !important;
	}

	.text--highlight {
		color: $hc;
	}

	.text--background,
	.text--background-2,
	.text--background-3 {
		padding: $dist;

		& > *:last-child {
			margin-bottom: 0;
		}
	}

	.text--background,
	.text--inline-bg {
		background: $hc;
		color: #ffffff;
	}

	.text--background-2,
	.text--inline-bg-2 {
		background: $ac;
		color: #ffffff;
	}

	.text--background-3,
	.text--inline-bg-3 {
		background: $box-color;
	}

	.text--small {
		font-size: $fs-small;
	}

	.text--medium {
		font-size: $fs-h4;
	}

	.text--big {
		font-size: $fs-h2;
	}

	p {
		min-height: $line-height;
		word-break: break-all;
		word-break: break-word;
		-webkit-hyphens: auto;
		-moz-hyphens: auto;
		-ms-hyphens: auto;
		hyphens: auto;
		overflow: hidden;

		&.text-center {
			text-align: center;
		}

		&.text-left {
			text-align: left;
		}

		&.text-right {
			text-align: right;
		}

		&.text-justify {
			text-align: justify;
		}
	}

	code:not([class*="language-"]) {
		display: block;
		overflow-x: scroll;
		overflow-y: hidden;
		width: 100%;
		font-family: monospace, san-serif;
		font-size: $fs-small;
		padding: $dist*0.5;
		background: #ffffff;
		border-radius: $radius;
	}

	pre:not([class*="language-"]) {
		white-space: normal;
		font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
		background: $box-color;
		padding: $line-height-int + rem;
		border-radius: $radius;
	}

	pre[class*="language-"] {
		padding: $line-height-int + rem;
		margin: $margin-top 0 0 0;
		border-radius: $radius;
	}

	img,
	.embed {
		&[style*="max-width:"] {
			@include resp-img;
		}

		&.center,
		&.leftAlone,
		&.rightAlone {
			display: block;

			& + * {
				clear: both;
			}
		}

		&.center {
			margin-left: auto;
			margin-right: auto;
		}

		&.leftAlone {
			float: left;
		}

		&.rightAlone {
			float: right;
		}

		&.left {
			float: left;
			margin: 1em $line-height 0.5em 0;
		}

		&.right {
			float: right;
			margin: 1em 0 0.5em $line-height;
		}
	}

	h1 {
		font-size: $fs-h1;
	}

	h2 {
		font-size: $fs-h2;
	}

	h3 {
		font-size: $fs-h3;
	}

	h4 {
		font-size: $fs-h4;
	}

	h5 {
		font-size: $fs-h5;
	}

	h6 {
		font-size: $fs-h6;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $ff-h;
		font-weight: $fw-h-bold;
		margin-top: $margin-top-huge !important;
	}

	& > *:first-child {
		margin-top: 0 !important;
	}

	& > *:last-child {
		margin-bottom: 0;
	}

	p {
		margin-top: $margin-top;

		& > *:first-child {
			margin-top: 0 !important;
		}

		.btn {
			margin-top: $margin-top;
		}

		& + ul,
		& + ol,
		& + h1,
		& + h2,
		& + h3,
		& + h4,
		& + h5,
		& + h6,
		& + table,
		& + div,
		& + blockquote,
		& + code,
		& + pre {
			margin-top: $margin-top;
		}
	}

	blockquote {
		margin-top: $margin-top;

		& > *:first-child {
			margin-top: 0 !important;
		}

		& + ul,
		& + ol,
		& + h1,
		& + h2,
		& + h3,
		& + h4,
		& + h5,
		& + h6,
		& + table,
		& + div,
		& + p,
		& + code,
		& + pre {
			margin-top: $margin-top;
		}
	}

	code {
		margin-top: $margin-top;

		& > *:first-child {
			margin-top: 0 !important;
		}

		& + ul,
		& + ol,
		& + h1,
		& + h2,
		& + h3,
		& + h4,
		& + h5,
		& + h6,
		& + table,
		& + div,
		& + p,
		& + code,
		& + pre {
			margin-top: $margin-top;
		}
	}

	pre {
		margin-top: $margin-top;

		& > *:first-child {
			margin-top: 0 !important;
		}

		& + ul,
		& + ol,
		& + h1,
		& + h2,
		& + h3,
		& + h4,
		& + h5,
		& + h6,
		& + table,
		& + div,
		& + p,
		& + code,
		& + pre {
			margin-top: $margin-top;
		}
	}

	h1, h2, h3, h4, h5, h6 {
		& + h1,
		& + h2,
		& + h3,
		& + h4,
		& + h5,
		& + h6,
		& + p,
		& + ul,
		& + ol,
		& + table,
		& + div,
		& + blockquote,
		& + code,
		& + pre {
			margin-top: $margin-top;
		}
	}

	h1, h2, h3, h4, h5, h6 {
		& + div,
		& + table,
		& + blockquote,
		& + code,
		& + pre {
			margin-top: $margin-top-big;
		}
	}

	ul {
		margin-left: 20px !important;
		li {
			list-style-type: disc;

			ul {
				li {
					list-style-type: circle;

					ul {
						li {
							list-style-type: square;
						}
					}
				}
			}
		}
	}

	ol {
		li {
			list-style-type: decimal;
		}
	}

	ul, ol {
		list-style: outside;
		margin: 0 0 1em 2.25em;

		li {
			line-height: $line-height;
			margin-top: math.div($dist, 6);

			&:first-of-type {
				margin-top: 0;
			}

			ul,
			ol {
				margin-top: $margin-top;
				margin-bottom: 0;
			}
		}

		& + p,
		& + h1,
		& + h2,
		& + h3,
		& + h4,
		& + h5,
		& + h6,
		& + table,
		& + div,
		& + blockquote,
		& + code,
		& + pre {
			margin-top: $margin-top;
		}
	}

	div {
		& > *:first-child {
			margin-top: 0 !important;
		}

		& + p,
		& + ul,
		& + ol,
		& + h1,
		& + h2,
		& + h3,
		& + h4,
		& + h5,
		& + h6,
		& + table,
		& + div,
		& + blockquote,
		& + code,
		& + pre {
			margin-top: $margin-top;
		}
	}

	table {
		& > *:first-child {
			margin-top: 0;
		}

		& + p,
		& + ul,
		& + ol,
		& + h1,
		& + h2,
		& + h3,
		& + h4,
		& + h5,
		& + h6,
		& + table,
		& + div,
		& + blockquote,
		& + code,
		& + pre {
			margin-top: $margin-top;
		}
	}

	hr {
		outline: 0;
		border: 0;
		display: block;
		height: 1px;
		@include resp-margin(v);
		margin-left: 0;
		margin-right: 0;
		background: $border-color;
	}

	blockquote {
		position: relative;
		min-height: math.div($fs-h2-int + 0.15, $fs-root-int) + rem;

		&:before {
			@include fontawesome($fa-var-quote-left);
			font-size: $fs-h2;
			position: absolute;
			left: 0;
			top: 0.15rem;
		}

		padding-left: calc(#{$fs-h2} + #{$dist*0.5});
		font-style: italic;
		color: $fc-light;
	}

	@include table-styling;

	.responsive-iframe-container {
		@extend %cf;

		.responsive-iframe {
			&--1_1 {
				@include aspect-ratio(1, 1);
			}

			&--4_3 {
				@include aspect-ratio(4, 3);
			}

			&--3_2 {
				@include aspect-ratio(3, 2);
			}

			&--5_3 {
				@include aspect-ratio(5, 3);
			}

			&--16_9 {
				@include aspect-ratio(16, 9);
			}

			&--3_1 {
				@include aspect-ratio(3, 1);
			}
		}

		iframe {
			width: 100%;
			height: 100%;
		}
	}

	a:not(.button):not(.btn):not(.text--button):not(.text--button-2) {
		color: $ac;
		text-decoration: underline;

		&:hover {
			background: $ac;
			color: #ffffff;
			text-decoration: none;
		}
	}
}

.formatted-text,
[class*="__text"] {
	@extend %formatted-text;
}