.pagination {
	text-align: center;
	@include resp-padding(top);

	&:before {
		display: block;
		content: '';
		border-top: 1px solid $border-color;
		@include resp-margin(bottom);
	}

	a,
	span {
		display: inline-block;
		width: $dist*1.4;
		height: $dist*1.4;
		line-height: $dist*1.4;
		background: $box-color-dark;
		color: $page-bg;

		@include breakpoint(null, $bp-l) {
			font-size: $fs-small;
		}
	}

	a {
		text-decoration: none;
		transition: all $duration;

		&:hover {
			background: $ac;
			color: #FFFFFF;
		}
	}

	span.pagination__current {
		background: $hc;
		color: #FFFFFF;
	}
}