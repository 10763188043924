@import "../../../node_modules/susy/sass/_susy.scss";
@import "../../../vendor/grasenhiller/silverstripe-tweaks/client/libraries/fontawesome/scss/_variables";
@import "globals/_fonts";
@import "globals/_variables";
@import "globals/_extends";
@import "globals/_mixins";
@import "globals/_text-styling";

// - Set box-sizing to border-box
*,
*:after,
*:before {
	box-sizing: border-box;
}

// - Fix small gutters of images
img {

	vertical-align: top;
}

// - Remove focus
*:focus {
	outline: 0;
}

// - IE + Edge ... whoop whoop
main,
.main {
	display: block;
}

// - Set page bg color
html {
	background: $box-color-dark;
}

body {
	background: $page-bg;
}

.outer-wrapper {
	@extend %outer-container;
}

.outer-wrapper .modular-content .module--as-popup {
	display: none !important;
}

.modular-content {
	.module {
		position: relative;
		@include resp-margin(bottom);

		&:hover {
			.module__editlink {
				opacity: 0.625;
			}
		}

		&__editlink {
			opacity: 0.25;
			position: absolute;
			top: 0;
			right: 0;
			padding: 0.5em 1em;
			color: #ffffff;
			border-radius: 5px;
			background: $ac;
			transition: opacity $duration linear, background $duration linear;

			i {
				margin-right: 0.5em;
			}

			&:hover {
				opacity: 1 !important;
			}
		}

		&:last-of-type {
			margin-bottom: 0;
		}

		&--light-background {
			background: $box-color;
			@include resp-padding(v);
			@include resp-padding(h);

		}

		&--separator-top {
			@include resp-padding(top);
			border-top: 1px solid $border-color;

			.module__editlink {
				top: $dist;

				@include breakpoint(null, $bp-m) {
					top: $dist*0.5;
				}
			}
		}

		&--separator-bottom {
			@include resp-padding(bottom);
			border-bottom: 1px solid $border-color;
		}

		&:first-of-type {
			&.module--separator-top {
				padding-top: 0;
				border-top: 0;
			}
		}

		&:last-of-type {
			margin-bottom: 0;

			&.module--separator-bottom {
				padding-bottom: 0;
				border-bottom: 0;
			}
		}

		&__title {
			@include resp-margin(bottom);
			text-transform: uppercase;

			&:after {
				content: "";
				height: 2px;
				width: $dist*3;
				background: $box-color-dark;
				margin-top: $dist*0.5;
				display: block;
			}

			h3 {
				font-size: $fs-h2;

				@include breakpoint(null, $bp-l) {
					font-size: $fs-h3;
				}
			}
		}
	}

	.modular-content-columns {
		@extend %cf;
		@include resp-margin(bottom);

		.modular-content-column {
			opacity: 1;
		}
	}

	& > *:last-child {
		margin-bottom: 0;
	}

	&.featherlight-inner {
		margin-top: 0 !important;
	}

	
@import "modular-content-partials/_accordion.scss";
@import "modular-content-partials/_column.scss";
@import "modular-content-partials/_downloads.scss";
@import "modular-content-partials/_form.scss";
@import "modular-content-partials/_gallery.scss";
@import "modular-content-partials/_grid.scss";
@import "modular-content-partials/_headline.scss";
@import "modular-content-partials/_latest-news-page.scss";
@import "modular-content-partials/_newsletter.scss";
@import "modular-content-partials/_textimage.scss";
}

@import "partials/_banner-caption-block.scss";
@import "partials/_callback-form.scss";
@import "partials/_contact-person.scss";
@import "partials/_cookie-consent.scss";
@import "partials/_filter-form.scss";
@import "partials/_footer.scss";
@import "partials/_form.scss";
@import "partials/_header.scss";
@import "partials/_iframe.scss";
@import "partials/_language-switch.scss";
@import "partials/_latest-news.scss";
@import "partials/_loader.scss";
@import "partials/_mobile-navigation.scss";
@import "partials/_navigation.scss";
@import "partials/_page-media.scss";
@import "partials/_page-slider.scss";
@import "partials/_page-title.scss";
@import "partials/_pagination.scss";
@import "partials/_ppp.scss";
@import "partials/_product-information.scss";
@import "partials/_product-navigation.scss";
@import "partials/_products-listing.scss";
@import "partials/_team-member.scss";
@import "partials/_totop.scss";
@import "partials/_userforms.scss";
@import "pages/_aboutus.scss";
@import "pages/_basic-holder.scss";
@import "pages/_homepage.scss";
@import "pages/_news-holder.scss";
@import "pages/_news-page.scss";
@import "pages/_newsletter.scss";
@import "pages/_product-landing-holder.scss";
@import "pages/_product-landing-page.scss";
@import "pages/_products-listing-page.scss";
@import "pages/_team-page.scss";
@import "pages/_userforms.scss";
@import "pages/_wiki-holder.scss";
@import "pages/_wiki-page.scss";
