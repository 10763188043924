// !klaro
html .klaro {

    .cookie-modal .cm-modal.cm-klaro {
        background-color: white;
        border-radius: 0;
        border: 0px;
        padding: 2rem;
        
        z-index: 1000002;
        box-sizing: border-box;
        box-shadow: 0 0 20px -10px #000000;

        // @include for-tablet-landscape-up {
        //     padding: var(--grid-gutter-sm);
        //     max-width: 800px;
        // }       
        h1, p {
            color: black;

            a {
                color: black;
                text-decoration: underline;
            }
        }

        .cm-body {
            .cm-purposes .cm-purpose {
                input[type=checkbox] + label:before {
                    display: none;
                }
                .cm-list-title {
                    color: black;
                    font-size: inherit;
                    font-weight: 300;
                }
                .cm-list-description {
                    padding:0;
                    font-size: var(--font-size-sm);
                }
                .cm-caret a {
                    color: var(--blue);
                }
            }

            .cm-toggle-all {
                display: none;
            }
            .slider {
                box-shadow: none;
            }
            .cm-list-input.required:checked+.cm-list-label .slider {
                background-color: black;
            }
            .cm-list-input:checked+.cm-list-label .slider {
                background-color: black;
            }

        }
        .cm-footer {
            padding: 1em 0;
        }
        .cm-footer .cm-footer-buttons {
            flex-flow: column;
            // @include for-tablet-landscape-up {
            //     flex-flow: row;
            // }
            @include breakpoint($bp-l, null) {
                flex-flow: row;
            }
            button.cm-btn {

                cursor: pointer;
                padding: 6px 8px;
                &.cm-btn-decline,
                &.cm-btn-success {
                    background-color: var(--gray-light);
                    color: black;
                    &:hover {
                        background-color: var(--blue-light);
                    }
                }
                &.cm-btn-accept-all {
                    background-color: black;
                    color: white;
                    //text-transform: uppercase;
                    &:hover {
                        background-color: black;
                    }   
                }
                justify-content: center;
                // @include for-tablet-landscape-up {
                //     justify-content: flex-start;
                // }
            }
        }
    }  
    .cm-powered-by {
        display: none;
    }
}

.cookieconsent-config {

    cursor: pointer;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 9999999999;
    padding: 10px;
    width: 40px;
    height: 40px;
    line-height: 20px;
    text-align: center;
    background: #fff;
    box-shadow: 0 0 20px -10px #000;
}