.language-switcher {
	float: right;
	font-size: 0;
	margin-right: $dist*0.5;

	li {
		display: inline-block;
		font-size: $fs-small;
		line-height: 1;
		margin-right: $dist*0.5;
		padding-right: $dist*0.5;
		border-right: 1px solid $border-color;

		&:last-of-type {
			margin-right: 0;
			padding-right: 0;
			border-right: 0;
		}

		a {
			display: block;
			line-height: 1;
			font-weight: $fw-bold;
		}

		&[class*="-current"] {
			a {
				color: $ac;
			}
		}
	}
}