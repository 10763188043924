

@use "sass:math";

.products-listing {
	.fa-exclamation-triangle {
		color: $invalid-color;
		cursor: help;
	}

	& + .modular-content {
		margin-top: $dist;
		padding-top: $dist;
		border-top: 1px solid #e2e2e2;
	}

	& > .pagination {
		margin-top: $dist;
	}

	&__suffix-for-prev {
		display: none;
	}

	&__resp-details {
		@include breakpoint($bp-m, null) {
			display: none;
		}

		@include breakpoint(null, $bp-m) {
			ul {
				display: none;
				padding: $dist*0.5 0 $dist*0.5 0;
				border-top: 1px dashed #e2e2e2;
			}
		}
	}

	&--without-images {
		table {
			tr {
				td,
				th {
					&:first-of-type {
						text-align: left;
					}
				}

				@include breakpoint(null, $bp-m) {
					td {
						&:first-of-type {
							cursor: pointer;

							&:before {
								@include fontawesome($fa-var-angle-down);
								margin: 0 $dist*0.5 0 0;
								color: $hc;
								vertical-align: middle;
								font-size: $fs-h4;
								font-weight: $fw-medium;
								padding: 22px 0;
							}
						}
					}

					th,
					td {
						display: none;

						&:first-of-type,
						&:nth-last-child(-n+2) {
							display: table-cell;
						}
					}
				}
			}
		}
	}

	&--with-images {
		table {
			tr {
				td,
				th {
					&:nth-of-type(1) {
						width: 100px + $dist;
					}

					&:nth-of-type(2) {
						text-align: left;
					}
				}

				@include breakpoint(null, $bp-m) {
					td {
						&:nth-of-type(2) {
							cursor: pointer;

							&:before {
								@include fontawesome($fa-var-angle-down);
								margin: 0 $dist*0.5 0 0;
								color: $hc;
								vertical-align: middle;
								font-size: $fs-h4;
								font-weight: $fw-medium;
								padding: 22px 0;
							}
						}
					}

					th,
					td {
						display: none;

						&:nth-of-type(2),
						&:nth-last-child(-n+2) {
							display: table-cell;
						}
					}
				}
			}
		}
	}

	table {
		width: 100%;

		tr {
			th,
			td {
				padding: 0 $dist*0.5;
				text-align: center;
				height: 80px;
				vertical-align: middle;
			}

			td {

				img {
					@include breakpoint(null, $bp-m) {
						border: 1px solid $border-color;
					}

					@include breakpoint(null, $bp-s) {
						display: none;
					}
				}

				&:first-of-type {
					text-align: left;
				}

				.attribute__suffix {
					font-size: $fs-small;
				}
			}
		}

		thead {
			tr {
				th {
					font-size: $fs-tiny;
					color: #ffffff;
					background: $box-color-dark;
					text-align: center;
					height: $dist*1.25;

					@include breakpoint(null, $bp-s) {
						font-size: $fs-tiny;
					}
				}
			}
		}

		tbody {
			tr {
				&:nth-of-type(odd) {
					background: $box-color;
				}

				td {
					&:first-of-type {
						font-weight: $fw-bold;
					}
				}
			}
		}

		tfoot {
			tr {
				td {
					padding: 0;
					@extend %cf;

					.col-left {
						float: left;
						padding-top: $dist;
						width: 200px;
					}

					.col-right {
						float: right;
						padding-top: $dist;
						width: calc(100% - 200px);
					}
				}
			}
		}
	}

	.pagination {
		padding-top: 0;
		text-align: left;

		& > * {
			text-align: center;
		}

		&:before {
			display: none;
		}
	}

	&__datasheet {
		width: 90px;

		a {
			display: block;
			margin-top: -$dist*0.5;
			margin-right: math.div(-$dist, 6);
			margin-left: math.div(-$dist, 6);
			margin-bottom: -$dist*0.5;
			font-size: $fs-h4;
			color: $page-bg;

			&:hover {
				i {
					background: $ac;
				}
			}

			i {
				padding: $dist*0.5 17px;
				background: $box-color-dark;
				font-size: $fs;
			}
		}
	}

	&__inquiry {
		width: 60px;

		a {
			display: block;
			margin-top: -$dist*0.5;
			margin-right: math.div(-$dist, 6);
			margin-left: math.div(-$dist, 6);
			margin-bottom: -$dist*0.5;
			font-size: $fs-h4;
			color: $fc;

			&:hover {
				i {
					background: $ac;
					color: #FFFFFF;
				}
			}

			i {
				padding: $dist*0.5 17px;
				font-size: $fs;
				color: $fc;
			}
		}

		a {
			display: block;
			margin-top: -$dist*0.5;
			margin-right: math.div(-$dist, 6);
			margin-left: math.div(-$dist, 6);
			margin-bottom: -$dist*0.5;
			color: #ffffff;
			font-size: $fs-h4;
		}
	}
}
