#teampage {
  .main__text {
    @include resp-margin(bottom);
  }

  .team-members {
    @extend %cf;

    .team-member {
      $grid-conf: (
        (null, $bp-s, 1, 2),
        ($bp-s, null, 2)
      );

      @include resp-gallery-layout($grid-conf);
    }
  }
}