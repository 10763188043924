@font-face {
  font-family: 'Titillium Web';
  src: url('/_resources/themes/chips4light/fonts/Titillium_Web/TitilliumWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Titillium Web';
  src: url('/_resources/themes/chips4light/fonts/Titillium_Web/TitilliumWeb-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Titillium Web';
  src: url('/_resources/themes/chips4light/fonts/Titillium_Web/TitilliumWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Titillium Web';
  src: url('/_resources/themes/chips4light/fonts/Titillium_Web/TitilliumWeb-Bold.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
