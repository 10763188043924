@use "sass:math";

.product-information {
	@include resp-margin(bottom);
	@include table-styling;

	.fa-exclamation-triangle {
		color: $invalid-color;
		margin-top: 0.25em;
	}

	h4 {
		font-size: $fs-h4;
	}

	table {
		margin-top: $dist*0.5;
		border-left: 1px solid $box-color;
		border-top: 1px solid $box-color;
		border-right: 1px solid $box-color;

		td, th {
			border-color: $box-color !important;
		}
	}
}

.step-button-wrapper {
	@extend %cf;

	.step-button-next,
	.inquiry-back-link {
		float: right;
	}

	.inquiry-back-link {
		margin-right: math.div($dist, 6);
	}

	.step-button-next[style*="display: none"] {
		& + .inquiry-back-link {
			display: none;
		}
	}
}
