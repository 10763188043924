@use "sass:math";

.footer {
	background: $box-color;
	margin-top: $dist;
	border-top: 1px solid $border-color;
	@extend %cf;

	@include breakpoint($bp-m, null) {
		.navigation--footer {
			float: left;
		}

		& > span {
			float: right;
		}
	}

	&__top {
		margin: $dist*0.5 0 $dist;

		.footer__cols {
			@extend %cf;
			.footer__col {
				float: left;
				width: span(3 of 12);
				margin-right: gutter();

				@include breakpoint(null, $bp-m) {
					width: span(6 of 12);
				}

				@include breakpoint(null, $bp-s) {
					width: 100%;
					margin-right: 0;
					margin-bottom: $dist*0.5;
					font-size: $fs-small;

					&:last-of-type {
						margin-bottom: 0;
					}
				}

				strong {
					font-size: $fs-tiny;
					text-transform: uppercase;

					&:after {
						content: "";
						height: 2px;
						display: block;
						width: $dist*3;
						background: $fc;
						margin: $dist*0.5 0 12px 0;
					}
				}

				li {
					margin-bottom: math.div($dist, 3);

					a {
						font-weight: $fw-medium;
					}
				}

				&:first-of-type {
					@extend %cf;
					width: span(6 of 12);

					@include breakpoint(null, $bp-m) {
						width: 100%;
						margin-right: 0;
						margin-bottom: $dist*0.5;
					}

					@include breakpoint(null, $bp-xs) {
						li {
							width: 100% !important;
						}
					}

					li {
						width: 50%;
						float: left;
					}
				}

				&:last-of-type {
					margin-right: 0;
				}
			}
		}
	}

	&__bottom {
		background: $box-color-dark;
		color: $page-bg;
		padding: $dist*0.5 0;
		font-size: $fs-small;


	}
}