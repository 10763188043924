@use "sass:math";

.banner-caption-block {
	margin-bottom: $dist;
	margin-top: -1px;

	.slick-dots {
		@extend %cf;

		li {
			float: left;
			display: block;
			border: 1px solid $border-color;
			padding: $dist*0.5;
			margin-right: -1px;
			margin-top: -1px;

			@include breakpoint($bp-m, null) {
				width: calc(33.33333% + 1px);

				&:nth-of-type(3n+3) {
					width: 33.33333%;
				}

				&:nth-of-type(-n+3) {
					border-top: 0;
				}
			}

			@include breakpoint(null, $bp-m) {
				width: calc(50% + 1px);

				&:nth-of-type(2n+2) {
					width: 50%;
				}

				&:nth-of-type(-n+2) {
					border-top: 0;
				}
			}

			h3 {
				font-size: $fs-h4;
				margin-bottom: $dist*0.25;
			}

			p {
				font-size: $fs-small;
				margin-bottom: $dist*0.25;
			}

			a {
				font-size: 0;
				display: inline-block;
				margin-left: -$dist*0.25;
				margin-top: -$dist*0.25;

				&:after {
					font-size: $fs;
					padding: $dist*0.25;
					@include fontawesome($fa-var-long-arrow-right);
				}
			}

			&.slick-active {
				background: $ac;
				border-color: $ac;

				&,
				& * {
					color: #FFFFFF;
				}
			}

			@include breakpoint(null, $bp-l) {
				padding: math.div($dist, 3);
			}
		}
	}
}