@use "sass:math";

.contact-person {
  position: fixed;
  right: $dist*1.5;
  bottom: $dist*1.5;
  z-index: 99999;

  &__toggle {
    display: block;
    position: relative;
    z-index: 10;
    cursor: pointer;
    width: $dist*1.5;
    height: $dist*1.5;
    line-height: $dist*1.5;
    text-align: center;
    background: $ac;
    border-radius: 100%;
    transition: background $duration linear;
    color: #ffffff;
    box-shadow: 0px 0px 10px -2px $shadow-color;

    &:hover {
      background: $ac-hover;
    }
  }

  &__details {
    display: none;
    position: absolute;
    padding: $dist*0.5;
    width: 350px;
    top: $dist*0.5;
    left: $dist*0.5;
    transform: translateX(-100%) translateY(-100%);
    background: $box-color;
    box-shadow: 0px 0px 10px -2px $shadow-color;

    .team-member {
      &__image {
        float: left;
        width: 100px;
        margin-right: $dist*0.5;
      }
      
      &__info {
        float: left;
        width: 250px - $dist*1.5;

        li {
          &.team-member__separator {
            display: block;
            width: $dist*2;
            height: 2px;
            margin: $dist*0.25 0 math.div($dist, 3) 0;
            background: $border-color;
            line-height: 1;
          }

          i {
            margin-right: 0.5em;
            color: $hc;
          }

          a {
            text-decoration: none;
            color: $fc;
            font-weight: $fw-light;

            &:hover {
              color: $ac;
            }
          }
        }
      }
    }
  }
}