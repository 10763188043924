.cr_form {
	.cr_ipe_item {
		margin-top: $dist*0.5;

		&:nth-of-type(-n+2) {
			margin-top: 0;
		}

		label {
			display: block;
			font-weight: $fw-bold;
			margin-bottom: $dist*0.25;
		}

		input[type="checkbox"],
		input[type="radio"] {
			display: inline-block;
		}
	}
}