/*Änderungen IFrame Kontaktformular - Mail Dominik 9.10.23*/
#iframe-forms .backgroundSecBg {
  width: 100% !important;
}
#iframe-forms .backgroundBg {
  width: 100%;
}
#iframe-forms {
  width: 100%;
  height: 759px; 
  float:left;
} 
#iframe-forms .templateWidth {
  margin: 0 !important;
  padding: 0 !important;
  float: left;
}
#Name-li {
  padding: 0 !important;
}
#formBodyDiv {
  padding: 0 !important;
}
#iframe-forms .templateWidth, .tyTemplateWidth {
        margin:0 !important;
        float:left !important;
}
/*ENDE Änderungen IFrame Kontaktformular*/
@media screen and (min-width:850px) {#m-128 div.grid__item:nth-child(2) {
 width: 33%;
}
#m-128 div.grid__item:nth-child(1) {
 width: 64%;
}}
#m-128 div.grid__item:nth-child(1) {
  border: none;
}