.header {
  @extend %cf;
  padding-top: $dist;
  margin-bottom: $dist;

  &__left {
    float: left;
    width: 140px;
    margin-right: $dist;
  }

  &__right {
    float: left;
    text-align: right;
    width: calc(100% - #{140px + $dist});

    &-top {
      @extend %cf;

      .navigation--header,
      .language-switcher {
        float: right;
      }
    }

    &-bottom {
      margin-top: calc((#{140px - 45px} - #{$fs-small}) / 2);
    }
  }
}