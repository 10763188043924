#grasenhiller_aboutuspage {
	.main__text {
		@include resp-margin(bottom);
		@include resp-padding(v);
		@include resp-padding(h);
		background: $box-color;

		& > h2:first-of-type {
			&:after {
				@include resp-margin(bottom);
				@include resp-margin(top, 0.5);
				display: block;
				content: '';
				height: 2px;
				width: $dist*3;
				background: $border-color-dark;
			}
		}
	}

	.about-us-grid {
		@extend %cf;
		@include resp-margin(bottom);
		display: flex;
		align-items: stretch;
		flex-wrap: wrap;

		& > div {
			margin-right: gutter(12);
			border: 3px solid $border-color-dark;
			@include resp-padding(v);
			@include resp-padding(h);

			@include breakpoint($bp-m, null) {
				width: span(4 of 12);

				&:nth-of-type(3) {
					margin-right: 0;
				}
			}

			@include breakpoint($bp-s, $bp-m) {
				width: span(6 of 12);

				&:nth-of-type(2) {
					margin-right: 0;
				}

				&:nth-of-type(3) {
					margin-right: auto;
					margin-left: auto;
					margin-top: $dist;
				}
			}

			@include breakpoint(null, $bp-s) {
				float: none;
				width: 100%;
				margin-right: 0;
				margin-top: $dist;

				&:first-of-type {
					margin-top: 0;
				}
			}

			img {
				@include resp-img;
			}
		}
		
		&__title {
			@extend %cf;
			@include resp-margin(bottom);
			
			& > small {
				display: block;
				float: left;
				width: 7rem;
				overflow: hidden;
				border-bottom: 2px solid $border-color-dark;
				padding-bottom: $dist*0.5;
				line-height: 1;
				text-transform: uppercase;
			}
		}

		&__controls {
			float: right;
			width: calc(100% - 7rem);
			text-align: right;
			@include resp-margin(top, -1);
			@include resp-margin(right, -1);

			i {
				background: $box-color-dark;
				color: #ffffff;
				width: $dist*1.5;
				height: $dist*1.5;
				line-height: $dist*1.5;
				text-align: center;
				cursor: pointer;

				&:hover {
					background: $ac;
				}

				&.slick-disabled {
					cursor: not-allowed;
					opacity: 0.7;
				}
			}
		}
	}

	.team-slider {
		.team-member {
			&__info {
				&:first-of-type {
					margin-bottom: $dist*0.5;
				}

				&:last-of-type {
					margin-top: $dist*0.5;
				}
			}

			&__title {
				font-size: $fs-h3;
				text-transform: uppercase;
				font-weight: $fw-bold;
			}
		}
	}

	.certificates-slider {
		.certificate {
			strong {
				display: block;
				font-size: $fs-h3;
				text-transform: uppercase;
				font-weight: $fw-bold;
				margin-bottom: $dist*0.5;
			}
		}
	}

	.partner-slider {
		.partner {
			strong {
				display: block;
				font-size: $fs-h3;
				text-transform: uppercase;
				font-weight: $fw-bold;
				margin-bottom: $dist*0.5;
			}

			img {
				margin-bottom: $dist*0.5;
			}

			a {
				display: inline-block;
				margin-top: $dist*0.5;
			}
		}
	}
}