#grasenhiller_productlandingholder {
  .pages {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    .page {
      margin-right: gutter(12);
      cursor: pointer;
      margin-bottom: $dist;
      border: 2px solid $ac;

      @include breakpoint($bp-m, null) {
        width: span(3 of 12);

        &:nth-of-type(4n+4) {
          margin-right: 0;
        }
      }

      @include breakpoint($bp-s, $bp-m) {
        width: span(6 of 12);

        &:nth-of-type(2n+2) {
          margin-right: 0;
        }
      }

      @include breakpoint(null, $bp-s) {
        width: 100%;
        margin-right: 0;
      }

      img {
        @include resp-img();
        width: calc(100% + 4px);
        margin-left: -2px;
        margin-right: -2px;
        margin-top: -2px;
      }

      &__content {
        padding: $dist * 0.5;

        h3 {
          font-size: 1.2rem;
        }

        p {
          color: $fc-light;
          margin-top: $dist * 0.25;

          &::after {
            color: $ac;
            margin-left: 0.25em;

            @include fontawesome($fa-var-long-arrow-right);
          }
        }
      }
    }
  }
}
