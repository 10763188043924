.module--textimage {
  .module__inner {
    .textimage__image a,
    a.textimage__image {
      display: block;

      &[data-lightbox] {
        @include image-hover();
      }

      &:not([data-lightbox]) {
        @include image-hover($fa-var-arrow-right);
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  .textimage {
    @extend %cf;

    &--image-top {
      .textimage__image {
        @include resp-margin(bottom);

        img {
          display: block;
          width: 100%;
          height: auto;
          border: 1px solid $box-color;
          padding: $dist*0.5;
        }
      }
    }

    &--image-bottom {
      .textimage__image {
        @include resp-margin(top);

        img {
          display: block;
          width: 100%;
          height: auto;
          border: 1px solid $box-color;
          padding: $dist*0.5;
        }
      }
    }

    .formatted-text {
      .textimage__title {
        margin-top: 0 !important;
        text-transform: uppercase;
        margin-bottom: $dist*0.5;

        &:after {
          content: "";
          height: 2px;
          width: $dist*3;
          background: $box-color-dark;
          margin-top: $dist*0.5;
          display: block;
        }
      }
    }

    &--image-left,
    &--image-right {
      .textimage__image {
        width: span(3 of 12);
        
        img {
          display: block;
          width: 100%;
          height: auto;
          background: $page-bg;
          border: 1px solid $box-color;
          padding: $dist*0.5;
        }
      }

      .formatted-text {
        width: span(9 of 12);
        float: left;
      }

      @include breakpoint(null, $bp-s) {
        .textimage__image {
          float: none !important;
          margin: 0;
          width: 100%;

          & + .formatted-text {
            margin-top: $dist*0.5 !important;
          }
        }

        .formatted-text {
          float: none;
          margin: 0;
          width: 100%;

          & + .textimage__image {
            margin-top: $dist*0.5 !important;
          }
        }
      }

      @include breakpoint($bp-s, $bp-m) {
        .textimage__image {
          width: span(6 of 12);
        }

        .formatted-text {
          width: span(6 of 12);
        }
      }
    }

    &--image-left {
      .textimage__image {
        float: left;
        margin-right: gutter(12);
      }

      .formatted-text {
        float: right;
      }
    }

    &--image-right {
      .textimage__image {
        float: right;
        margin-left: gutter(12);
      }
    }

    &--text-floating {
      .formatted-text {
        width: 100%;

        p {
          overflow: visible;
        }
      }
      
      .textimage__image {
        display: block;
        float: left;
        margin-bottom: $dist*0.25;

        @include breakpoint(null, $bp-s) {
          margin: 0 !important;
          margin-bottom: $dist*0.5 !important;
        }
      }

      &.textimage--image-right {
        .textimage__image {
          float: right;
        }
      }
    }
  }
}