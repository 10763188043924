@use "sass:math";

.module--latestnewspage {
  .module__inner {
    background-color: $box-color;
    padding: $dist;
  }

  .module__title {
    h3 {
      font-size: $fs-h4;
    }

    &:after {
      content: none;
    }

    margin-bottom: $dist*0.5;
  }

  .latest-news-page {
    .news-preview {
      padding: 0;
      background-color: unset;

      h2 {
        font-size: $fs-h4;
      }

      &__image {
        @include breakpoint(null, $bp-s) {
          width: 100%;
        }

        @include breakpoint($bp-s, $bp-m) {
          img {
            width: 75%;
            margin: 0 auto;
          }
        }
      }

      @include breakpoint($bp-m) {
        display: flex;

        &__image {
          width: 25%;
        }

        &__text {
           flex-grow: 1;
        }
      }
    }

    &__archive-link {
      > a {
        margin-top: math.div($dist, 3);
        color: $ac;
        display: block;

        &:after {
          @include fontawesome($fa-var-long-arrow-right);
          margin-left: 0.5em;
        }
      }
    }
  }
}