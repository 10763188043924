form#Form_ProductsFilterForm {
	margin-bottom: $dist;

	fieldset {
		@extend %cf;

		.field {
			float: left;
			margin-right: gutter(12);

			@include breakpoint($bp-m, null) {
				width: span(3 of 12);

				&:nth-child(4n) {
					margin-right: 0;
				}
			}

			@include breakpoint($bp-s, $bp-m) {
				width: span(6 of 12);

				&:nth-child(2n) {
					margin-right: 0;
				}
			}

			@include breakpoint(null, $bp-s) {
				width: 100%;
				margin-right: 0;
				margin-bottom: $dist*0.5;

				&:last-of-type {
					margin-bottom: 0;
				}
			}

			label {
				font-size: $fs-tiny;
				text-transform: uppercase;
				text-transform: uppercase;

				&:after {
					content: "";
					height: 2px;
					display: block;
					width: $dist*3;
					background: $fc;
					margin: $dist*0.5 0 12px 0;
				}
			}
		}
	}

	.btn-toolbar {
		width: 100%;
	}
}