// - Susy Grid
@use "sass:math";

$max-width: 1170px;
$column-width: 70px;
$gutter-width: 30px;
$columns: 12;

// - Sizes
$dist: $gutter-width;
$mobilenav-width: 270px;
$radius: 3px;
$duration: 0.2s;

// - Text & Highlight Colors
$fc: #656565;
$fc-light: #acacac;
$hc: #0068b4;
$hc-hover: darken($hc, 5%);
$ac: #0068b4;
$ac-hover: darken($ac, 5%);
$ac-sec: #3f3b48;
$ac-sec-hover: darken($ac-sec, 5%);

// - Border & Box Colors
$border-color: #c3c3c3;
$border-color-dark: #3f3b48;
$box-color: #efefef;
$box-color-dark: #3f3b48;
$page-bg: #ffffff;

// - Misc
$shadow-color: rgba(0, 0, 0, 0.15);
$shadow-color-hover: rgba(0, 0, 0, 0.35);
$valid-color: #9edd3c;
$warning-color: #eabe17;
$invalid-color: #dd3c3c;

// - Font Family
$ff: 'Titillium Web', sans-serif;
$ff-h: $ff;

// - Font Sizes
$line-height-int: 1.35;
$line-height-big-int: 1.5;
$fs-root-int: 16;
$fs-int: 16;
$fs-tiny-int: 12;
$fs-small-int: 14;
$fs-h1-int: 38;
$fs-h2-int: 26;
$fs-h3-int: 24;
$fs-h4-int: 20;
$fs-h5-int: 18;
$fs-h6-int: 16;

// - Font Weights
$fw-light: 400;
$fw: 400;
$fw-medium: 600;
$fw-bold: 600;
$fw-h-light: 400;
$fw-h: 400;
$fw-h-medium: 600;
$fw-h-bold: 600;

// - Breakpoints
$bp-xs: 350px;
$bp-s: 480px;
$bp-m: 768px;
$bp-l: 1036px;
$bp-xl: $max-width + $dist*2;
$bp-nav: 950px;
$bp-columns: 1020px;

// ------
// - You don't need to touch those variables below.
// - They are automatically configured with the values above
// ------

$susy: (
	'columns': susy-repeat($columns),
	'gutters': math.div($gutter-width, $column-width),
	'spread': 'narrow',
	'container-spread': 'narrow',
);

$line-height: $line-height-int + em;
$line-height-big: $line-height-big-int + em;
$fs-root: $fs-root-int + px;
$fs-tiny: math.div($fs-tiny-int, $fs-root-int) + rem;
$fs-small: math.div($fs-small-int, $fs-root-int) + rem;
$fs: math.div($fs-int, $fs-root-int) + rem;
$fs-h1: math.div($fs-h1-int, $fs-root-int) + rem;
$fs-h2: math.div($fs-h2-int, $fs-root-int) + rem;
$fs-h3: math.div($fs-h3-int, $fs-root-int) + rem;
$fs-h4: math.div($fs-h4-int, $fs-root-int) + rem;
$fs-h5: math.div($fs-h5-int, $fs-root-int) + rem;
$fs-h6: math.div($fs-h6-int, $fs-root-int) + rem;