// - General
.module--newsletter {
  &.module--as-popup {
    display: none !important;
  }

  .newsletter-form {
    overflow: hidden;

    &--fpr-1 {
      .cr_ipe_item {
        width: 100%;
      }
    }

    .submit_container,
    div[rel="recaptcha"] {
      float: none !important;
      margin-right: 0 !important;
      clear: both !important;
      width: 100%;
    }

    div[rel="recaptcha"] {
      br {
        display: none !important;
      }
    }

    .editable_content {
      @extend %cf;
    }

    .badge {
      display: none;
    }

    .clever_form_error {
      border-color: $invalid-color;
    }

    .cr_button {
      width: auto;
      font-size: $fs;
      @extend %button;
      text-transform: uppercase;
    }
  }

  &.module--bg-dark {
    .newsletter-form {
      &,
      & * {
        color: #ffffff;
      }
    }
  }
}

// - Only outside columns or after breakpoint
& > .module--newsletter,
.module--newsletter.module--breakpoint-reached {
  .newsletter-form {
    &--fpr-2 {
      @include breakpoint($bp-s, null) {
        .cr_ipe_item {
          float: left;
          width: span(6 of 12);
          margin-right: gutter(12);

          &:nth-of-type(2n+2) {
            margin-right: 0;
          }

          &:nth-of-type(2n+3) {
            clear: both;
          }
        }
      }
    }

    &--fpr-3 {
      @include breakpoint($bp-s, null) {
        .cr_ipe_item {
          float: left;
          width: span(4 of 12);
          margin-right: gutter(12);

          &:nth-of-type(3n+3) {
            margin-right: 0;
          }

          &:nth-of-type(3n+4) {
            clear: both;
          }
        }
      }
    }
  }
}

// - Only in Columns above breakpoint
@include breakpoint($bp-columns, null) {
  .modular-content-column > .module--newsletter {
    .newsletter-form {
      &--fpr-2 {
        @include breakpoint($bp-columns, null) {
          .cr_ipe_item {
            float: left;
            width: span(3 of 6);
            margin-right: gutter(6);

            &:nth-of-type(2n+2) {
              margin-right: 0;
            }

            &:nth-of-type(2n+3) {
              clear: both;
            }
          }
        }
      }

      &--fpr-3 {
        @include breakpoint($bp-columns, null) {
          .cr_ipe_item {
            float: left;
            width: span(2 of 6);
            margin-right: gutter(6);

            &:nth-of-type(3n+3) {
              margin-right: 0;
            }

            &:nth-of-type(3n+4) {
              clear: both;
            }
          }
        }
      }
    }
  }
}