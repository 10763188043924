@charset "UTF-8";
@font-face {
  font-family: "Titillium Web";
  src: url("/_resources/themes/chips4light/fonts/Titillium_Web/TitilliumWeb-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Titillium Web";
  src: url("/_resources/themes/chips4light/fonts/Titillium_Web/TitilliumWeb-Bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Titillium Web";
  src: url("/_resources/themes/chips4light/fonts/Titillium_Web/TitilliumWeb-Regular.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Titillium Web";
  src: url("/_resources/themes/chips4light/fonts/Titillium_Web/TitilliumWeb-Bold.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
#silverstripe_userdefinedform .main, #teampage .team-members, .news-page__meta ul, .news-page__text, .news-preview, html .basic-holder__grand-children, #grasenhiller_aboutuspage .about-us-grid__title, #grasenhiller_aboutuspage .about-us-grid, html .userform .step-navigation ul, .team-members .team-member,
.search-results__list--TeamMember .search-results__list-items .team-member, .products-listing table tfoot tr td, .navigation__box ul, .step-button-wrapper, .products-listing, .page--banner-small .page-slider__slide, .page-media__files li, .page-media__images, .navigation--mobile .navigation__sub .navigation__sub-link, .navigation--mobile ul li, .latest-news__children, .header__right-top, .header, form .field .middleColumn, .footer__top .footer__cols .footer__col:first-of-type, .footer__top .footer__cols, .footer, form#Form_ProductsFilterForm fieldset, .modular-content #UserForm_Form_40 .form-step, .banner-caption-block .slick-dots, .modular-content .module--textimage .textimage, .modular-content .module--newsletter .newsletter-form .editable_content, .modular-content .module--grid .grid__content-wrapper, .modular-content .module--grid .grid, .modular-content .module--gallery .gallery, .modular-content .module--downloads ul li, .modular-content .module--accordion .accordion__title, .modular-content .modular-content-columns, .page-title .responsive-iframe-container, .formatted-text .responsive-iframe-container,
[class*=__text] .responsive-iframe-container, .cf {
  *zoom: 1;
}
#silverstripe_userdefinedform .main:before, #teampage .team-members:before, .news-page__meta ul:before, .news-page__text:before, .news-preview:before, html .basic-holder__grand-children:before, #grasenhiller_aboutuspage .about-us-grid__title:before, #grasenhiller_aboutuspage .about-us-grid:before, html .userform .step-navigation ul:before, .team-members .team-member:before,
.search-results__list--TeamMember .search-results__list-items .team-member:before, .products-listing table tfoot tr td:before, .navigation__box ul:before, .step-button-wrapper:before, .products-listing:before, .page--banner-small .page-slider__slide:before, .page-media__files li:before, .page-media__images:before, .navigation--mobile .navigation__sub .navigation__sub-link:before, .navigation--mobile ul li:before, .latest-news__children:before, .header__right-top:before, .header:before, form .field .middleColumn:before, .footer__top .footer__cols .footer__col:first-of-type:before, .footer__top .footer__cols:before, .footer:before, form#Form_ProductsFilterForm fieldset:before, .modular-content #UserForm_Form_40 .form-step:before, .banner-caption-block .slick-dots:before, .modular-content .module--textimage .textimage:before, .modular-content .module--newsletter .newsletter-form .editable_content:before, .modular-content .module--grid .grid__content-wrapper:before, .modular-content .module--grid .grid:before, .modular-content .module--gallery .gallery:before, .modular-content .module--downloads ul li:before, .modular-content .module--accordion .accordion__title:before, .modular-content .modular-content-columns:before, .page-title .responsive-iframe-container:before, .formatted-text .responsive-iframe-container:before,
[class*=__text] .responsive-iframe-container:before, .cf:before {
  content: "";
  display: table;
}
#silverstripe_userdefinedform .main:after, #teampage .team-members:after, .news-page__meta ul:after, .news-page__text:after, .news-preview:after, html .basic-holder__grand-children:after, #grasenhiller_aboutuspage .about-us-grid__title:after, #grasenhiller_aboutuspage .about-us-grid:after, html .userform .step-navigation ul:after, .team-members .team-member:after,
.search-results__list--TeamMember .search-results__list-items .team-member:after, .products-listing table tfoot tr td:after, .navigation__box ul:after, .step-button-wrapper:after, .products-listing:after, .page--banner-small .page-slider__slide:after, .page-media__files li:after, .page-media__images:after, .navigation--mobile .navigation__sub .navigation__sub-link:after, .navigation--mobile ul li:after, .latest-news__children:after, .header__right-top:after, .header:after, form .field .middleColumn:after, .footer__top .footer__cols .footer__col:first-of-type:after, .footer__top .footer__cols:after, .footer:after, form#Form_ProductsFilterForm fieldset:after, .modular-content #UserForm_Form_40 .form-step:after, .banner-caption-block .slick-dots:after, .modular-content .module--textimage .textimage:after, .modular-content .module--newsletter .newsletter-form .editable_content:after, .modular-content .module--grid .grid__content-wrapper:after, .modular-content .module--grid .grid:after, .modular-content .module--gallery .gallery:after, .modular-content .module--downloads ul li:after, .modular-content .module--accordion .accordion__title:after, .modular-content .modular-content-columns:after, .page-title .responsive-iframe-container:after, .formatted-text .responsive-iframe-container:after,
[class*=__text] .responsive-iframe-container:after, .cf:after {
  content: "";
  display: table;
  clear: both;
}

.team-members .team-member__info a,
.search-results__list--TeamMember .search-results__list-items .team-member__info a {
  color: #656565;
  text-decoration: none;
}
.team-members .team-member__info a:hover,
.search-results__list--TeamMember .search-results__list-items .team-member__info a:hover {
  color: #0068b4;
  text-decoration: underline;
}

.outer-wrapper {
  width: calc(100% - 15px);
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
}

html .userform .step-navigation ul li button, input[type=submit],
input[type=reset], .modular-content .module--newsletter .newsletter-form .cr_button, .page-title .text--button-2, .formatted-text .text--button-2,
[class*=__text] .text--button-2, .page-title .text--button, .formatted-text .text--button,
[class*=__text] .text--button, .button {
  display: inline-block;
  color: #FFFFFF;
  padding: 10px 15px;
  background: #3f3b48;
  transition: all linear 0.2s;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  line-height: 1.35em;
  font-weight: 400;
}
html .userform .step-navigation ul li button:hover, input[type=submit]:hover,
input[type=reset]:hover, .modular-content .module--newsletter .newsletter-form .cr_button:hover, .page-title .text--button-2:hover, .formatted-text .text--button-2:hover,
[class*=__text] .text--button-2:hover, .page-title .text--button:hover, .formatted-text .text--button:hover,
[class*=__text] .text--button:hover, .button:hover {
  background: #0068b4;
  color: #ffffff;
}
html .userform .step-navigation ul li button:focus, input[type=submit]:focus,
input[type=reset]:focus, .modular-content .module--newsletter .newsletter-form .cr_button:focus, .page-title .text--button-2:focus, .formatted-text .text--button-2:focus,
[class*=__text] .text--button-2:focus, .page-title .text--button:focus, .formatted-text .text--button:focus,
[class*=__text] .text--button:focus, .button:focus {
  outline: none;
  border: none;
}

.button--small {
  font-size: 0.875rem;
}

.button--white {
  background: #FFFFFF;
  color: #656565;
}
.button--white:hover {
  color: #FFFFFF;
}

html .userform .step-navigation ul li button, input[type=reset], .page-title .text--button-2, .formatted-text .text--button-2,
[class*=__text] .text--button-2, .button--neutral {
  background: #3f3b48;
  color: #ffffff;
}
html .userform .step-navigation ul li button:hover, input[type=reset]:hover, .page-title .text--button-2:hover, .formatted-text .text--button-2:hover,
[class*=__text] .text--button-2:hover, .button--neutral:hover {
  color: #ffffff;
  background: #33303a;
}

.table--responsive {
  overflow-x: auto;
}

@media screen and (max-width: 768px) {
  .table--responsive {
    width: 100%;
  }
  .table--responsive th,
.table--responsive td {
    white-space: nowrap;
  }
}
.page-title ul.list--checklist, .formatted-text ul.list--checklist,
[class*=__text] ul.list--checklist {
  list-style: none;
  margin-left: 0 !important;
}
.page-title ul.list--checklist li, .formatted-text ul.list--checklist li,
[class*=__text] ul.list--checklist li {
  display: block;
  padding-left: 1.25em;
}
.page-title ul.list--checklist li:before, .formatted-text ul.list--checklist li:before,
[class*=__text] ul.list--checklist li:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00c";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  width: 1em;
  margin-left: -1.25em;
  float: left;
  text-align: center;
  position: relative;
  top: 3px;
}
.page-title ul.list--checklist li:before, .formatted-text ul.list--checklist li:before,
[class*=__text] ul.list--checklist li:before {
  margin-top: 0.125em;
}
.page-title .text--discreet, .formatted-text .text--discreet,
[class*=__text] .text--discreet {
  color: #acacac !important;
}
.page-title .text--highlight, .formatted-text .text--highlight,
[class*=__text] .text--highlight {
  color: #0068b4;
}
.page-title .text--background, .formatted-text .text--background,
[class*=__text] .text--background,
.page-title .text--background-2,
.formatted-text .text--background-2,
[class*=__text] .text--background-2,
.page-title .text--background-3,
.formatted-text .text--background-3,
[class*=__text] .text--background-3 {
  padding: 30px;
}
.page-title .text--background > *:last-child, .formatted-text .text--background > *:last-child,
[class*=__text] .text--background > *:last-child,
.page-title .text--background-2 > *:last-child,
.formatted-text .text--background-2 > *:last-child,
[class*=__text] .text--background-2 > *:last-child,
.page-title .text--background-3 > *:last-child,
.formatted-text .text--background-3 > *:last-child,
[class*=__text] .text--background-3 > *:last-child {
  margin-bottom: 0;
}
.page-title .text--background, .formatted-text .text--background,
[class*=__text] .text--background,
.page-title .text--inline-bg,
.formatted-text .text--inline-bg,
[class*=__text] .text--inline-bg {
  background: #0068b4;
  color: #ffffff;
}
.page-title .text--background-2, .formatted-text .text--background-2,
[class*=__text] .text--background-2,
.page-title .text--inline-bg-2,
.formatted-text .text--inline-bg-2,
[class*=__text] .text--inline-bg-2 {
  background: #0068b4;
  color: #ffffff;
}
.page-title .text--background-3, .formatted-text .text--background-3,
[class*=__text] .text--background-3,
.page-title .text--inline-bg-3,
.formatted-text .text--inline-bg-3,
[class*=__text] .text--inline-bg-3 {
  background: #efefef;
}
.page-title .text--small, .formatted-text .text--small,
[class*=__text] .text--small {
  font-size: 0.875rem;
}
.page-title .text--medium, .formatted-text .text--medium,
[class*=__text] .text--medium {
  font-size: 1.25rem;
}
.page-title .text--big, .formatted-text .text--big,
[class*=__text] .text--big {
  font-size: 1.625rem;
}
.page-title p, .formatted-text p,
[class*=__text] p {
  min-height: 1.35em;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  overflow: hidden;
}
.page-title p.text-center, .formatted-text p.text-center,
[class*=__text] p.text-center {
  text-align: center;
}
.page-title p.text-left, .formatted-text p.text-left,
[class*=__text] p.text-left {
  text-align: left;
}
.page-title p.text-right, .formatted-text p.text-right,
[class*=__text] p.text-right {
  text-align: right;
}
.page-title p.text-justify, .formatted-text p.text-justify,
[class*=__text] p.text-justify {
  text-align: justify;
}
.page-title code:not([class*=language-]), .formatted-text code:not([class*=language-]),
[class*=__text] code:not([class*=language-]) {
  display: block;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  font-family: monospace, san-serif;
  font-size: 0.875rem;
  padding: 15px;
  background: #ffffff;
  border-radius: 3px;
}
.page-title pre:not([class*=language-]), .formatted-text pre:not([class*=language-]),
[class*=__text] pre:not([class*=language-]) {
  white-space: normal;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  background: #efefef;
  padding: 1.35rem;
  border-radius: 3px;
}
.page-title pre[class*=language-], .formatted-text pre[class*=language-],
[class*=__text] pre[class*=language-] {
  padding: 1.35rem;
  margin: 0.75em 0 0 0;
  border-radius: 3px;
}
.page-title img[style*="max-width:"], .formatted-text img[style*="max-width:"],
[class*=__text] img[style*="max-width:"],
.page-title .embed[style*="max-width:"],
.formatted-text .embed[style*="max-width:"],
[class*=__text] .embed[style*="max-width:"] {
  display: block;
  width: 100%;
  height: auto;
}
.page-title img.center, .formatted-text img.center,
[class*=__text] img.center, .page-title img.leftAlone, .formatted-text img.leftAlone,
[class*=__text] img.leftAlone, .page-title img.rightAlone, .formatted-text img.rightAlone,
[class*=__text] img.rightAlone,
.page-title .embed.center,
.formatted-text .embed.center,
[class*=__text] .embed.center,
.page-title .embed.leftAlone,
.formatted-text .embed.leftAlone,
[class*=__text] .embed.leftAlone,
.page-title .embed.rightAlone,
.formatted-text .embed.rightAlone,
[class*=__text] .embed.rightAlone {
  display: block;
}
.page-title img.center + *, .formatted-text img.center + *,
[class*=__text] img.center + *, .page-title img.leftAlone + *, .formatted-text img.leftAlone + *,
[class*=__text] img.leftAlone + *, .page-title img.rightAlone + *, .formatted-text img.rightAlone + *,
[class*=__text] img.rightAlone + *,
.page-title .embed.center + *,
.formatted-text .embed.center + *,
[class*=__text] .embed.center + *,
.page-title .embed.leftAlone + *,
.formatted-text .embed.leftAlone + *,
[class*=__text] .embed.leftAlone + *,
.page-title .embed.rightAlone + *,
.formatted-text .embed.rightAlone + *,
[class*=__text] .embed.rightAlone + * {
  clear: both;
}
.page-title img.center, .formatted-text img.center,
[class*=__text] img.center,
.page-title .embed.center,
.formatted-text .embed.center,
[class*=__text] .embed.center {
  margin-left: auto;
  margin-right: auto;
}
.page-title img.leftAlone, .formatted-text img.leftAlone,
[class*=__text] img.leftAlone,
.page-title .embed.leftAlone,
.formatted-text .embed.leftAlone,
[class*=__text] .embed.leftAlone {
  float: left;
}
.page-title img.rightAlone, .formatted-text img.rightAlone,
[class*=__text] img.rightAlone,
.page-title .embed.rightAlone,
.formatted-text .embed.rightAlone,
[class*=__text] .embed.rightAlone {
  float: right;
}
.page-title img.left, .formatted-text img.left,
[class*=__text] img.left,
.page-title .embed.left,
.formatted-text .embed.left,
[class*=__text] .embed.left {
  float: left;
  margin: 1em 1.35em 0.5em 0;
}
.page-title img.right, .formatted-text img.right,
[class*=__text] img.right,
.page-title .embed.right,
.formatted-text .embed.right,
[class*=__text] .embed.right {
  float: right;
  margin: 1em 0 0.5em 1.35em;
}
.page-title h1, .formatted-text h1,
[class*=__text] h1 {
  font-size: 2.375rem;
}
.page-title h2, .formatted-text h2,
[class*=__text] h2 {
  font-size: 1.625rem;
}
.page-title h3, .formatted-text h3,
[class*=__text] h3 {
  font-size: 1.5rem;
}
.page-title h4, .formatted-text h4,
[class*=__text] h4 {
  font-size: 1.25rem;
}
.page-title h5, .formatted-text h5,
[class*=__text] h5 {
  font-size: 1.125rem;
}
.page-title h6, .formatted-text h6,
[class*=__text] h6 {
  font-size: 1rem;
}
.page-title h1, .formatted-text h1,
[class*=__text] h1,
.page-title h2,
.formatted-text h2,
[class*=__text] h2,
.page-title h3,
.formatted-text h3,
[class*=__text] h3,
.page-title h4,
.formatted-text h4,
[class*=__text] h4,
.page-title h5,
.formatted-text h5,
[class*=__text] h5,
.page-title h6,
.formatted-text h6,
[class*=__text] h6 {
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  margin-top: 1.5em !important;
}
.page-title > *:first-child, .formatted-text > *:first-child,
[class*=__text] > *:first-child {
  margin-top: 0 !important;
}
.page-title > *:last-child, .formatted-text > *:last-child,
[class*=__text] > *:last-child {
  margin-bottom: 0;
}
.page-title p, .formatted-text p,
[class*=__text] p {
  margin-top: 0.75em;
}
.page-title p > *:first-child, .formatted-text p > *:first-child,
[class*=__text] p > *:first-child {
  margin-top: 0 !important;
}
.page-title p .btn, .formatted-text p .btn,
[class*=__text] p .btn {
  margin-top: 0.75em;
}
.page-title p + ul, .formatted-text p + ul,
[class*=__text] p + ul, .page-title p + ol, .formatted-text p + ol,
[class*=__text] p + ol, .page-title p + h1, .formatted-text p + h1,
[class*=__text] p + h1, .page-title p + h2, .formatted-text p + h2,
[class*=__text] p + h2, .page-title p + h3, .formatted-text p + h3,
[class*=__text] p + h3, .page-title p + h4, .formatted-text p + h4,
[class*=__text] p + h4, .page-title p + h5, .formatted-text p + h5,
[class*=__text] p + h5, .page-title p + h6, .formatted-text p + h6,
[class*=__text] p + h6, .page-title p + table, .formatted-text p + table,
[class*=__text] p + table, .page-title p + div, .formatted-text p + div,
[class*=__text] p + div, .page-title p + blockquote, .formatted-text p + blockquote,
[class*=__text] p + blockquote, .page-title p + code, .formatted-text p + code,
[class*=__text] p + code, .page-title p + pre, .formatted-text p + pre,
[class*=__text] p + pre {
  margin-top: 0.75em;
}
.page-title blockquote, .formatted-text blockquote,
[class*=__text] blockquote {
  margin-top: 0.75em;
}
.page-title blockquote > *:first-child, .formatted-text blockquote > *:first-child,
[class*=__text] blockquote > *:first-child {
  margin-top: 0 !important;
}
.page-title blockquote + ul, .formatted-text blockquote + ul,
[class*=__text] blockquote + ul, .page-title blockquote + ol, .formatted-text blockquote + ol,
[class*=__text] blockquote + ol, .page-title blockquote + h1, .formatted-text blockquote + h1,
[class*=__text] blockquote + h1, .page-title blockquote + h2, .formatted-text blockquote + h2,
[class*=__text] blockquote + h2, .page-title blockquote + h3, .formatted-text blockquote + h3,
[class*=__text] blockquote + h3, .page-title blockquote + h4, .formatted-text blockquote + h4,
[class*=__text] blockquote + h4, .page-title blockquote + h5, .formatted-text blockquote + h5,
[class*=__text] blockquote + h5, .page-title blockquote + h6, .formatted-text blockquote + h6,
[class*=__text] blockquote + h6, .page-title blockquote + table, .formatted-text blockquote + table,
[class*=__text] blockquote + table, .page-title blockquote + div, .formatted-text blockquote + div,
[class*=__text] blockquote + div, .page-title blockquote + p, .formatted-text blockquote + p,
[class*=__text] blockquote + p, .page-title blockquote + code, .formatted-text blockquote + code,
[class*=__text] blockquote + code, .page-title blockquote + pre, .formatted-text blockquote + pre,
[class*=__text] blockquote + pre {
  margin-top: 0.75em;
}
.page-title code, .formatted-text code,
[class*=__text] code {
  margin-top: 0.75em;
}
.page-title code > *:first-child, .formatted-text code > *:first-child,
[class*=__text] code > *:first-child {
  margin-top: 0 !important;
}
.page-title code + ul, .formatted-text code + ul,
[class*=__text] code + ul, .page-title code + ol, .formatted-text code + ol,
[class*=__text] code + ol, .page-title code + h1, .formatted-text code + h1,
[class*=__text] code + h1, .page-title code + h2, .formatted-text code + h2,
[class*=__text] code + h2, .page-title code + h3, .formatted-text code + h3,
[class*=__text] code + h3, .page-title code + h4, .formatted-text code + h4,
[class*=__text] code + h4, .page-title code + h5, .formatted-text code + h5,
[class*=__text] code + h5, .page-title code + h6, .formatted-text code + h6,
[class*=__text] code + h6, .page-title code + table, .formatted-text code + table,
[class*=__text] code + table, .page-title code + div, .formatted-text code + div,
[class*=__text] code + div, .page-title code + p, .formatted-text code + p,
[class*=__text] code + p, .page-title code + code, .formatted-text code + code,
[class*=__text] code + code, .page-title code + pre, .formatted-text code + pre,
[class*=__text] code + pre {
  margin-top: 0.75em;
}
.page-title pre, .formatted-text pre,
[class*=__text] pre {
  margin-top: 0.75em;
}
.page-title pre > *:first-child, .formatted-text pre > *:first-child,
[class*=__text] pre > *:first-child {
  margin-top: 0 !important;
}
.page-title pre + ul, .formatted-text pre + ul,
[class*=__text] pre + ul, .page-title pre + ol, .formatted-text pre + ol,
[class*=__text] pre + ol, .page-title pre + h1, .formatted-text pre + h1,
[class*=__text] pre + h1, .page-title pre + h2, .formatted-text pre + h2,
[class*=__text] pre + h2, .page-title pre + h3, .formatted-text pre + h3,
[class*=__text] pre + h3, .page-title pre + h4, .formatted-text pre + h4,
[class*=__text] pre + h4, .page-title pre + h5, .formatted-text pre + h5,
[class*=__text] pre + h5, .page-title pre + h6, .formatted-text pre + h6,
[class*=__text] pre + h6, .page-title pre + table, .formatted-text pre + table,
[class*=__text] pre + table, .page-title pre + div, .formatted-text pre + div,
[class*=__text] pre + div, .page-title pre + p, .formatted-text pre + p,
[class*=__text] pre + p, .page-title pre + code, .formatted-text pre + code,
[class*=__text] pre + code, .page-title pre + pre, .formatted-text pre + pre,
[class*=__text] pre + pre {
  margin-top: 0.75em;
}
.page-title h1 + h1, .formatted-text h1 + h1,
[class*=__text] h1 + h1, .page-title h1 + h2, .formatted-text h1 + h2,
[class*=__text] h1 + h2, .page-title h1 + h3, .formatted-text h1 + h3,
[class*=__text] h1 + h3, .page-title h1 + h4, .formatted-text h1 + h4,
[class*=__text] h1 + h4, .page-title h1 + h5, .formatted-text h1 + h5,
[class*=__text] h1 + h5, .page-title h1 + h6, .formatted-text h1 + h6,
[class*=__text] h1 + h6, .page-title h1 + p, .formatted-text h1 + p,
[class*=__text] h1 + p, .page-title h1 + ul, .formatted-text h1 + ul,
[class*=__text] h1 + ul, .page-title h1 + ol, .formatted-text h1 + ol,
[class*=__text] h1 + ol, .page-title h1 + table, .formatted-text h1 + table,
[class*=__text] h1 + table, .page-title h1 + div, .formatted-text h1 + div,
[class*=__text] h1 + div, .page-title h1 + blockquote, .formatted-text h1 + blockquote,
[class*=__text] h1 + blockquote, .page-title h1 + code, .formatted-text h1 + code,
[class*=__text] h1 + code, .page-title h1 + pre, .formatted-text h1 + pre,
[class*=__text] h1 + pre, .page-title h2 + h1, .formatted-text h2 + h1,
[class*=__text] h2 + h1, .page-title h2 + h2, .formatted-text h2 + h2,
[class*=__text] h2 + h2, .page-title h2 + h3, .formatted-text h2 + h3,
[class*=__text] h2 + h3, .page-title h2 + h4, .formatted-text h2 + h4,
[class*=__text] h2 + h4, .page-title h2 + h5, .formatted-text h2 + h5,
[class*=__text] h2 + h5, .page-title h2 + h6, .formatted-text h2 + h6,
[class*=__text] h2 + h6, .page-title h2 + p, .formatted-text h2 + p,
[class*=__text] h2 + p, .page-title h2 + ul, .formatted-text h2 + ul,
[class*=__text] h2 + ul, .page-title h2 + ol, .formatted-text h2 + ol,
[class*=__text] h2 + ol, .page-title h2 + table, .formatted-text h2 + table,
[class*=__text] h2 + table, .page-title h2 + div, .formatted-text h2 + div,
[class*=__text] h2 + div, .page-title h2 + blockquote, .formatted-text h2 + blockquote,
[class*=__text] h2 + blockquote, .page-title h2 + code, .formatted-text h2 + code,
[class*=__text] h2 + code, .page-title h2 + pre, .formatted-text h2 + pre,
[class*=__text] h2 + pre, .page-title h3 + h1, .formatted-text h3 + h1,
[class*=__text] h3 + h1, .page-title h3 + h2, .formatted-text h3 + h2,
[class*=__text] h3 + h2, .page-title h3 + h3, .formatted-text h3 + h3,
[class*=__text] h3 + h3, .page-title h3 + h4, .formatted-text h3 + h4,
[class*=__text] h3 + h4, .page-title h3 + h5, .formatted-text h3 + h5,
[class*=__text] h3 + h5, .page-title h3 + h6, .formatted-text h3 + h6,
[class*=__text] h3 + h6, .page-title h3 + p, .formatted-text h3 + p,
[class*=__text] h3 + p, .page-title h3 + ul, .formatted-text h3 + ul,
[class*=__text] h3 + ul, .page-title h3 + ol, .formatted-text h3 + ol,
[class*=__text] h3 + ol, .page-title h3 + table, .formatted-text h3 + table,
[class*=__text] h3 + table, .page-title h3 + div, .formatted-text h3 + div,
[class*=__text] h3 + div, .page-title h3 + blockquote, .formatted-text h3 + blockquote,
[class*=__text] h3 + blockquote, .page-title h3 + code, .formatted-text h3 + code,
[class*=__text] h3 + code, .page-title h3 + pre, .formatted-text h3 + pre,
[class*=__text] h3 + pre, .page-title h4 + h1, .formatted-text h4 + h1,
[class*=__text] h4 + h1, .page-title h4 + h2, .formatted-text h4 + h2,
[class*=__text] h4 + h2, .page-title h4 + h3, .formatted-text h4 + h3,
[class*=__text] h4 + h3, .page-title h4 + h4, .formatted-text h4 + h4,
[class*=__text] h4 + h4, .page-title h4 + h5, .formatted-text h4 + h5,
[class*=__text] h4 + h5, .page-title h4 + h6, .formatted-text h4 + h6,
[class*=__text] h4 + h6, .page-title h4 + p, .formatted-text h4 + p,
[class*=__text] h4 + p, .page-title h4 + ul, .formatted-text h4 + ul,
[class*=__text] h4 + ul, .page-title h4 + ol, .formatted-text h4 + ol,
[class*=__text] h4 + ol, .page-title h4 + table, .formatted-text h4 + table,
[class*=__text] h4 + table, .page-title h4 + div, .formatted-text h4 + div,
[class*=__text] h4 + div, .page-title h4 + blockquote, .formatted-text h4 + blockquote,
[class*=__text] h4 + blockquote, .page-title h4 + code, .formatted-text h4 + code,
[class*=__text] h4 + code, .page-title h4 + pre, .formatted-text h4 + pre,
[class*=__text] h4 + pre, .page-title h5 + h1, .formatted-text h5 + h1,
[class*=__text] h5 + h1, .page-title h5 + h2, .formatted-text h5 + h2,
[class*=__text] h5 + h2, .page-title h5 + h3, .formatted-text h5 + h3,
[class*=__text] h5 + h3, .page-title h5 + h4, .formatted-text h5 + h4,
[class*=__text] h5 + h4, .page-title h5 + h5, .formatted-text h5 + h5,
[class*=__text] h5 + h5, .page-title h5 + h6, .formatted-text h5 + h6,
[class*=__text] h5 + h6, .page-title h5 + p, .formatted-text h5 + p,
[class*=__text] h5 + p, .page-title h5 + ul, .formatted-text h5 + ul,
[class*=__text] h5 + ul, .page-title h5 + ol, .formatted-text h5 + ol,
[class*=__text] h5 + ol, .page-title h5 + table, .formatted-text h5 + table,
[class*=__text] h5 + table, .page-title h5 + div, .formatted-text h5 + div,
[class*=__text] h5 + div, .page-title h5 + blockquote, .formatted-text h5 + blockquote,
[class*=__text] h5 + blockquote, .page-title h5 + code, .formatted-text h5 + code,
[class*=__text] h5 + code, .page-title h5 + pre, .formatted-text h5 + pre,
[class*=__text] h5 + pre, .page-title h6 + h1, .formatted-text h6 + h1,
[class*=__text] h6 + h1, .page-title h6 + h2, .formatted-text h6 + h2,
[class*=__text] h6 + h2, .page-title h6 + h3, .formatted-text h6 + h3,
[class*=__text] h6 + h3, .page-title h6 + h4, .formatted-text h6 + h4,
[class*=__text] h6 + h4, .page-title h6 + h5, .formatted-text h6 + h5,
[class*=__text] h6 + h5, .page-title h6 + h6, .formatted-text h6 + h6,
[class*=__text] h6 + h6, .page-title h6 + p, .formatted-text h6 + p,
[class*=__text] h6 + p, .page-title h6 + ul, .formatted-text h6 + ul,
[class*=__text] h6 + ul, .page-title h6 + ol, .formatted-text h6 + ol,
[class*=__text] h6 + ol, .page-title h6 + table, .formatted-text h6 + table,
[class*=__text] h6 + table, .page-title h6 + div, .formatted-text h6 + div,
[class*=__text] h6 + div, .page-title h6 + blockquote, .formatted-text h6 + blockquote,
[class*=__text] h6 + blockquote, .page-title h6 + code, .formatted-text h6 + code,
[class*=__text] h6 + code, .page-title h6 + pre, .formatted-text h6 + pre,
[class*=__text] h6 + pre {
  margin-top: 0.75em;
}
.page-title h1 + div, .formatted-text h1 + div,
[class*=__text] h1 + div, .page-title h1 + table, .formatted-text h1 + table,
[class*=__text] h1 + table, .page-title h1 + blockquote, .formatted-text h1 + blockquote,
[class*=__text] h1 + blockquote, .page-title h1 + code, .formatted-text h1 + code,
[class*=__text] h1 + code, .page-title h1 + pre, .formatted-text h1 + pre,
[class*=__text] h1 + pre, .page-title h2 + div, .formatted-text h2 + div,
[class*=__text] h2 + div, .page-title h2 + table, .formatted-text h2 + table,
[class*=__text] h2 + table, .page-title h2 + blockquote, .formatted-text h2 + blockquote,
[class*=__text] h2 + blockquote, .page-title h2 + code, .formatted-text h2 + code,
[class*=__text] h2 + code, .page-title h2 + pre, .formatted-text h2 + pre,
[class*=__text] h2 + pre, .page-title h3 + div, .formatted-text h3 + div,
[class*=__text] h3 + div, .page-title h3 + table, .formatted-text h3 + table,
[class*=__text] h3 + table, .page-title h3 + blockquote, .formatted-text h3 + blockquote,
[class*=__text] h3 + blockquote, .page-title h3 + code, .formatted-text h3 + code,
[class*=__text] h3 + code, .page-title h3 + pre, .formatted-text h3 + pre,
[class*=__text] h3 + pre, .page-title h4 + div, .formatted-text h4 + div,
[class*=__text] h4 + div, .page-title h4 + table, .formatted-text h4 + table,
[class*=__text] h4 + table, .page-title h4 + blockquote, .formatted-text h4 + blockquote,
[class*=__text] h4 + blockquote, .page-title h4 + code, .formatted-text h4 + code,
[class*=__text] h4 + code, .page-title h4 + pre, .formatted-text h4 + pre,
[class*=__text] h4 + pre, .page-title h5 + div, .formatted-text h5 + div,
[class*=__text] h5 + div, .page-title h5 + table, .formatted-text h5 + table,
[class*=__text] h5 + table, .page-title h5 + blockquote, .formatted-text h5 + blockquote,
[class*=__text] h5 + blockquote, .page-title h5 + code, .formatted-text h5 + code,
[class*=__text] h5 + code, .page-title h5 + pre, .formatted-text h5 + pre,
[class*=__text] h5 + pre, .page-title h6 + div, .formatted-text h6 + div,
[class*=__text] h6 + div, .page-title h6 + table, .formatted-text h6 + table,
[class*=__text] h6 + table, .page-title h6 + blockquote, .formatted-text h6 + blockquote,
[class*=__text] h6 + blockquote, .page-title h6 + code, .formatted-text h6 + code,
[class*=__text] h6 + code, .page-title h6 + pre, .formatted-text h6 + pre,
[class*=__text] h6 + pre {
  margin-top: 1em;
}
.page-title ul, .formatted-text ul,
[class*=__text] ul {
  margin-left: 20px !important;
}
.page-title ul li, .formatted-text ul li,
[class*=__text] ul li {
  list-style-type: disc;
}
.page-title ul li ul li, .formatted-text ul li ul li,
[class*=__text] ul li ul li {
  list-style-type: circle;
}
.page-title ul li ul li ul li, .formatted-text ul li ul li ul li,
[class*=__text] ul li ul li ul li {
  list-style-type: square;
}
.page-title ol li, .formatted-text ol li,
[class*=__text] ol li {
  list-style-type: decimal;
}
.page-title ul, .formatted-text ul,
[class*=__text] ul, .page-title ol, .formatted-text ol,
[class*=__text] ol {
  list-style: outside;
  margin: 0 0 1em 2.25em;
}
.page-title ul li, .formatted-text ul li,
[class*=__text] ul li, .page-title ol li, .formatted-text ol li,
[class*=__text] ol li {
  line-height: 1.35em;
  margin-top: 5px;
}
.page-title ul li:first-of-type, .formatted-text ul li:first-of-type,
[class*=__text] ul li:first-of-type, .page-title ol li:first-of-type, .formatted-text ol li:first-of-type,
[class*=__text] ol li:first-of-type {
  margin-top: 0;
}
.page-title ul li ul, .formatted-text ul li ul,
[class*=__text] ul li ul,
.page-title ul li ol,
.formatted-text ul li ol,
[class*=__text] ul li ol, .page-title ol li ul, .formatted-text ol li ul,
[class*=__text] ol li ul,
.page-title ol li ol,
.formatted-text ol li ol,
[class*=__text] ol li ol {
  margin-top: 0.75em;
  margin-bottom: 0;
}
.page-title ul + p, .formatted-text ul + p,
[class*=__text] ul + p, .page-title ul + h1, .formatted-text ul + h1,
[class*=__text] ul + h1, .page-title ul + h2, .formatted-text ul + h2,
[class*=__text] ul + h2, .page-title ul + h3, .formatted-text ul + h3,
[class*=__text] ul + h3, .page-title ul + h4, .formatted-text ul + h4,
[class*=__text] ul + h4, .page-title ul + h5, .formatted-text ul + h5,
[class*=__text] ul + h5, .page-title ul + h6, .formatted-text ul + h6,
[class*=__text] ul + h6, .page-title ul + table, .formatted-text ul + table,
[class*=__text] ul + table, .page-title ul + div, .formatted-text ul + div,
[class*=__text] ul + div, .page-title ul + blockquote, .formatted-text ul + blockquote,
[class*=__text] ul + blockquote, .page-title ul + code, .formatted-text ul + code,
[class*=__text] ul + code, .page-title ul + pre, .formatted-text ul + pre,
[class*=__text] ul + pre, .page-title ol + p, .formatted-text ol + p,
[class*=__text] ol + p, .page-title ol + h1, .formatted-text ol + h1,
[class*=__text] ol + h1, .page-title ol + h2, .formatted-text ol + h2,
[class*=__text] ol + h2, .page-title ol + h3, .formatted-text ol + h3,
[class*=__text] ol + h3, .page-title ol + h4, .formatted-text ol + h4,
[class*=__text] ol + h4, .page-title ol + h5, .formatted-text ol + h5,
[class*=__text] ol + h5, .page-title ol + h6, .formatted-text ol + h6,
[class*=__text] ol + h6, .page-title ol + table, .formatted-text ol + table,
[class*=__text] ol + table, .page-title ol + div, .formatted-text ol + div,
[class*=__text] ol + div, .page-title ol + blockquote, .formatted-text ol + blockquote,
[class*=__text] ol + blockquote, .page-title ol + code, .formatted-text ol + code,
[class*=__text] ol + code, .page-title ol + pre, .formatted-text ol + pre,
[class*=__text] ol + pre {
  margin-top: 0.75em;
}
.page-title div > *:first-child, .formatted-text div > *:first-child,
[class*=__text] div > *:first-child {
  margin-top: 0 !important;
}
.page-title div + p, .formatted-text div + p,
[class*=__text] div + p, .page-title div + ul, .formatted-text div + ul,
[class*=__text] div + ul, .page-title div + ol, .formatted-text div + ol,
[class*=__text] div + ol, .page-title div + h1, .formatted-text div + h1,
[class*=__text] div + h1, .page-title div + h2, .formatted-text div + h2,
[class*=__text] div + h2, .page-title div + h3, .formatted-text div + h3,
[class*=__text] div + h3, .page-title div + h4, .formatted-text div + h4,
[class*=__text] div + h4, .page-title div + h5, .formatted-text div + h5,
[class*=__text] div + h5, .page-title div + h6, .formatted-text div + h6,
[class*=__text] div + h6, .page-title div + table, .formatted-text div + table,
[class*=__text] div + table, .page-title div + div, .formatted-text div + div,
[class*=__text] div + div, .page-title div + blockquote, .formatted-text div + blockquote,
[class*=__text] div + blockquote, .page-title div + code, .formatted-text div + code,
[class*=__text] div + code, .page-title div + pre, .formatted-text div + pre,
[class*=__text] div + pre {
  margin-top: 0.75em;
}
.page-title table > *:first-child, .formatted-text table > *:first-child,
[class*=__text] table > *:first-child {
  margin-top: 0;
}
.page-title table + p, .formatted-text table + p,
[class*=__text] table + p, .page-title table + ul, .formatted-text table + ul,
[class*=__text] table + ul, .page-title table + ol, .formatted-text table + ol,
[class*=__text] table + ol, .page-title table + h1, .formatted-text table + h1,
[class*=__text] table + h1, .page-title table + h2, .formatted-text table + h2,
[class*=__text] table + h2, .page-title table + h3, .formatted-text table + h3,
[class*=__text] table + h3, .page-title table + h4, .formatted-text table + h4,
[class*=__text] table + h4, .page-title table + h5, .formatted-text table + h5,
[class*=__text] table + h5, .page-title table + h6, .formatted-text table + h6,
[class*=__text] table + h6, .page-title table + table, .formatted-text table + table,
[class*=__text] table + table, .page-title table + div, .formatted-text table + div,
[class*=__text] table + div, .page-title table + blockquote, .formatted-text table + blockquote,
[class*=__text] table + blockquote, .page-title table + code, .formatted-text table + code,
[class*=__text] table + code, .page-title table + pre, .formatted-text table + pre,
[class*=__text] table + pre {
  margin-top: 0.75em;
}
.page-title hr, .formatted-text hr,
[class*=__text] hr {
  outline: 0;
  border: 0;
  display: block;
  height: 1px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 0;
  margin-right: 0;
  background: #c3c3c3;
}
@media screen and (max-width: 768px) {
  .page-title hr, .formatted-text hr,
[class*=__text] hr {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
.page-title blockquote, .formatted-text blockquote,
[class*=__text] blockquote {
  position: relative;
  min-height: 1.634375rem;
  padding-left: calc(1.625rem + 15px);
  font-style: italic;
  color: #acacac;
}
.page-title blockquote:before, .formatted-text blockquote:before,
[class*=__text] blockquote:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f10d";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  font-size: 1.625rem;
  position: absolute;
  left: 0;
  top: 0.15rem;
}
.page-title table, .formatted-text table,
[class*=__text] table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
}
.page-title table thead th, .formatted-text table thead th,
[class*=__text] table thead th {
  border-bottom: 2px solid #c3c3c3 !important;
  border-right: 0 !important;
}
.page-title table thead + tbody tr th:first-child, .formatted-text table thead + tbody tr th:first-child,
[class*=__text] table thead + tbody tr th:first-child {
  border-right: 0;
}
.page-title table tfoot th, .formatted-text table tfoot th,
[class*=__text] table tfoot th {
  border-top: 2px solid #c3c3c3;
  border-right: 0 !important;
  border-bottom: 0 !important;
}
.page-title table td, .formatted-text table td,
[class*=__text] table td {
  border-bottom: 1px solid #c3c3c3;
}
.page-title table th, .formatted-text table th,
[class*=__text] table th,
.page-title table td,
.formatted-text table td,
[class*=__text] table td {
  text-align: left;
  padding: 0.5rem 0.75rem;
}
.page-title table th > *:first-child, .formatted-text table th > *:first-child,
[class*=__text] table th > *:first-child,
.page-title table td > *:first-child,
.formatted-text table td > *:first-child,
[class*=__text] table td > *:first-child {
  margin-top: 0 !important;
}
.page-title table tr th:first-child, .formatted-text table tr th:first-child,
[class*=__text] table tr th:first-child {
  border-right: 2px solid #c3c3c3;
  border-bottom: 1px solid #c3c3c3;
}
.page-title .table--two-heads table tbody tr th:first-child, .formatted-text .table--two-heads table tbody tr th:first-child,
[class*=__text] .table--two-heads table tbody tr th:first-child {
  border-right: 2px solid #c3c3c3 !important;
  border-bottom: 1px solid #c3c3c3;
}
.page-title .table--striped tbody tr:nth-child(odd), .formatted-text .table--striped tbody tr:nth-child(odd),
[class*=__text] .table--striped tbody tr:nth-child(odd) {
  background-color: #efefef;
}
.page-title .table--unstyled, .formatted-text .table--unstyled,
[class*=__text] .table--unstyled {
  width: 100%;
}
.page-title .table--unstyled tr, .formatted-text .table--unstyled tr,
[class*=__text] .table--unstyled tr, .page-title .table--unstyled th, .formatted-text .table--unstyled th,
[class*=__text] .table--unstyled th, .page-title .table--unstyled td, .formatted-text .table--unstyled td,
[class*=__text] .table--unstyled td {
  border: 0 !important;
  background: 0 !important;
  padding: 0 !important;
}
.page-title .table--unstyled tr td, .formatted-text .table--unstyled tr td,
[class*=__text] .table--unstyled tr td,
.page-title .table--unstyled tr th,
.formatted-text .table--unstyled tr th,
[class*=__text] .table--unstyled tr th {
  padding: 0 0.25rem 0 0 !important;
}
.page-title .table--unstyled tr td:last-of-type, .formatted-text .table--unstyled tr td:last-of-type,
[class*=__text] .table--unstyled tr td:last-of-type,
.page-title .table--unstyled tr th:last-of-type,
.formatted-text .table--unstyled tr th:last-of-type,
[class*=__text] .table--unstyled tr th:last-of-type {
  padding-right: 0 !important;
}
.page-title .responsive-iframe-container .responsive-iframe--1_1, .formatted-text .responsive-iframe-container .responsive-iframe--1_1,
[class*=__text] .responsive-iframe-container .responsive-iframe--1_1 {
  position: relative !important;
  overflow: hidden !important;
  padding-top: 100% !important;
}
.page-title .responsive-iframe-container .responsive-iframe--1_1 > *:first-child, .formatted-text .responsive-iframe-container .responsive-iframe--1_1 > *:first-child,
[class*=__text] .responsive-iframe-container .responsive-iframe--1_1 > *:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.page-title .responsive-iframe-container .responsive-iframe--4_3, .formatted-text .responsive-iframe-container .responsive-iframe--4_3,
[class*=__text] .responsive-iframe-container .responsive-iframe--4_3 {
  position: relative !important;
  overflow: hidden !important;
  padding-top: 75% !important;
}
.page-title .responsive-iframe-container .responsive-iframe--4_3 > *:first-child, .formatted-text .responsive-iframe-container .responsive-iframe--4_3 > *:first-child,
[class*=__text] .responsive-iframe-container .responsive-iframe--4_3 > *:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.page-title .responsive-iframe-container .responsive-iframe--3_2, .formatted-text .responsive-iframe-container .responsive-iframe--3_2,
[class*=__text] .responsive-iframe-container .responsive-iframe--3_2 {
  position: relative !important;
  overflow: hidden !important;
  padding-top: 66.6666666667% !important;
}
.page-title .responsive-iframe-container .responsive-iframe--3_2 > *:first-child, .formatted-text .responsive-iframe-container .responsive-iframe--3_2 > *:first-child,
[class*=__text] .responsive-iframe-container .responsive-iframe--3_2 > *:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.page-title .responsive-iframe-container .responsive-iframe--5_3, .formatted-text .responsive-iframe-container .responsive-iframe--5_3,
[class*=__text] .responsive-iframe-container .responsive-iframe--5_3 {
  position: relative !important;
  overflow: hidden !important;
  padding-top: 60% !important;
}
.page-title .responsive-iframe-container .responsive-iframe--5_3 > *:first-child, .formatted-text .responsive-iframe-container .responsive-iframe--5_3 > *:first-child,
[class*=__text] .responsive-iframe-container .responsive-iframe--5_3 > *:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.page-title .responsive-iframe-container .responsive-iframe--16_9, .formatted-text .responsive-iframe-container .responsive-iframe--16_9,
[class*=__text] .responsive-iframe-container .responsive-iframe--16_9 {
  position: relative !important;
  overflow: hidden !important;
  padding-top: 56.25% !important;
}
.page-title .responsive-iframe-container .responsive-iframe--16_9 > *:first-child, .formatted-text .responsive-iframe-container .responsive-iframe--16_9 > *:first-child,
[class*=__text] .responsive-iframe-container .responsive-iframe--16_9 > *:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.page-title .responsive-iframe-container .responsive-iframe--3_1, .formatted-text .responsive-iframe-container .responsive-iframe--3_1,
[class*=__text] .responsive-iframe-container .responsive-iframe--3_1 {
  position: relative !important;
  overflow: hidden !important;
  padding-top: 33.3333333333% !important;
}
.page-title .responsive-iframe-container .responsive-iframe--3_1 > *:first-child, .formatted-text .responsive-iframe-container .responsive-iframe--3_1 > *:first-child,
[class*=__text] .responsive-iframe-container .responsive-iframe--3_1 > *:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.page-title .responsive-iframe-container iframe, .formatted-text .responsive-iframe-container iframe,
[class*=__text] .responsive-iframe-container iframe {
  width: 100%;
  height: 100%;
}
.page-title a:not(.button):not(.btn):not(.text--button):not(.text--button-2), .formatted-text a:not(.button):not(.btn):not(.text--button):not(.text--button-2),
[class*=__text] a:not(.button):not(.btn):not(.text--button):not(.text--button-2) {
  color: #0068b4;
  text-decoration: underline;
}
.page-title a:not(.button):not(.btn):not(.text--button):not(.text--button-2):hover, .formatted-text a:not(.button):not(.btn):not(.text--button):not(.text--button-2):hover,
[class*=__text] a:not(.button):not(.btn):not(.text--button):not(.text--button-2):hover {
  background: #0068b4;
  color: #ffffff;
  text-decoration: none;
}

html {
  font-size: 16px;
}
@media screen and (max-width: 480px) {
  html {
    font-size: 16px-2px;
  }
}

body {
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #656565;
  text-size-adjust: none;
}

strong, b, th {
  font-weight: 600;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

small {
  font-size: 0.875rem;
}

h1, h2, h3, h4, h5, h6,
strong, p, b, em, i, span, a, small, th, td, li {
  line-height: 1.35em;
}

i.fa,
i.fal,
i.far,
i.fab {
  display: inline-block;
  min-width: 1em;
  text-align: center;
  font-style: normal;
}

a {
  color: #656565;
  text-decoration: none;
}
a:hover {
  color: #0068b4;
}

::selection {
  color: #ffffff;
  background: #0068b4;
}

::-moz-selection {
  color: #ffffff;
  background: #0068b4;
}

hr {
  border: none;
  height: 1px;
  background: #c3c3c3;
  margin-top: 30px;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  hr {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

em, i {
  font-style: italic;
}

.system-message + *, .message:not([style*="display: none"]) + * {
  margin-top: 30px !important;
}
@media screen and (max-width: 768px) {
  .system-message + *, .message:not([style*="display: none"]) + * {
    margin-top: 15px !important;
  }
}
.system-message + .system-message, .message:not([style*="display: none"]) + .system-message, .system-message + .message:not([style*="display: none"]), .message:not([style*="display: none"]) + .message:not([style*="display: none"]) {
  margin-top: 5px !important;
}
.system-message, .message:not([style*="display: none"]), .system-message--neutral {
  position: relative;
  border-left: 5px solid #c3c3c3;
  padding: 15px;
  background: rgba(195, 195, 195, 0.1);
}
.system-message--good, .message:not([style*="display: none"]).good, .message:not([style*="display: none"]).success {
  border-color: #9edd3c;
  background: rgba(158, 221, 60, 0.1);
}
.system-message--warning, .message:not([style*="display: none"]).warning {
  border-color: #eabe17;
  background: rgba(234, 190, 23, 0.1);
}
.system-message--bad, .message:not([style*="display: none"]).bad, .message:not([style*="display: none"]).error, .message:not([style*="display: none"]).required {
  border-color: #dd3c3c;
  background: rgba(221, 60, 60, 0.1);
}
.system-message--permanent, .message:not([style*="display: none"]) {
  cursor: default;
}
.system-message--permanent:after, .message:not([style*="display: none"]):after {
  display: none;
}

.dist-right {
  margin-right: 0.25em;
}

.dist-left {
  margin-left: 0.25em;
}

.dist-top {
  margin-top: 0.25em;
}

.dist-bottom {
  margin-bottom: 0.25em;
}

br {
  line-height: 1;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

sub {
  vertical-align: sub;
  font-size: smaller;
}

textarea {
  resize: vertical;
}

.global-sc-dist-right {
  margin-right: 0.5em;
}

.global-sc-dist-left {
  margin-left: 0.5em;
}

.global-sc-dist-top {
  margin-top: 0.5em;
}

.global-sc-dist-bottom {
  margin-bottom: 0.5em;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

img {
  vertical-align: top;
}

*:focus {
  outline: 0;
}

main,
.main {
  display: block;
}

html {
  background: #3f3b48;
}

body {
  background: #ffffff;
}

.outer-wrapper .modular-content .module--as-popup {
  display: none !important;
}

.modular-content .module {
  position: relative;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .modular-content .module {
    margin-bottom: 15px;
  }
}
.modular-content .module:hover .module__editlink {
  opacity: 0.625;
}
.modular-content .module__editlink {
  opacity: 0.25;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em 1em;
  color: #ffffff;
  border-radius: 5px;
  background: #0068b4;
  transition: opacity 0.2s linear, background 0.2s linear;
}
.modular-content .module__editlink i {
  margin-right: 0.5em;
}
.modular-content .module__editlink:hover {
  opacity: 1 !important;
}
.modular-content .module:last-of-type {
  margin-bottom: 0;
}
.modular-content .module--light-background {
  background: #efefef;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 30px;
  padding-left: 30px;
}
@media screen and (max-width: 768px) {
  .modular-content .module--light-background {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  .modular-content .module--light-background {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.modular-content .module--separator-top {
  padding-top: 30px;
  border-top: 1px solid #c3c3c3;
}
@media screen and (max-width: 768px) {
  .modular-content .module--separator-top {
    padding-top: 15px;
  }
}
.modular-content .module--separator-top .module__editlink {
  top: 30px;
}
@media screen and (max-width: 768px) {
  .modular-content .module--separator-top .module__editlink {
    top: 15px;
  }
}
.modular-content .module--separator-bottom {
  padding-bottom: 30px;
  border-bottom: 1px solid #c3c3c3;
}
@media screen and (max-width: 768px) {
  .modular-content .module--separator-bottom {
    padding-bottom: 15px;
  }
}
.modular-content .module:first-of-type.module--separator-top {
  padding-top: 0;
  border-top: 0;
}
.modular-content .module:last-of-type {
  margin-bottom: 0;
}
.modular-content .module:last-of-type.module--separator-bottom {
  padding-bottom: 0;
  border-bottom: 0;
}
.modular-content .module__title {
  margin-bottom: 30px;
  text-transform: uppercase;
}
@media screen and (max-width: 768px) {
  .modular-content .module__title {
    margin-bottom: 15px;
  }
}
.modular-content .module__title:after {
  content: "";
  height: 2px;
  width: 90px;
  background: #3f3b48;
  margin-top: 15px;
  display: block;
}
.modular-content .module__title h3 {
  font-size: 1.625rem;
}
@media screen and (max-width: 1036px) {
  .modular-content .module__title h3 {
    font-size: 1.5rem;
  }
}
.modular-content .modular-content-columns {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .modular-content .modular-content-columns {
    margin-bottom: 15px;
  }
}
.modular-content .modular-content-columns .modular-content-column {
  opacity: 1;
}
.modular-content > *:last-child {
  margin-bottom: 0;
}
.modular-content.featherlight-inner {
  margin-top: 0 !important;
}
.modular-content .module--accordion .accordion__item {
  border: 1px solid #c3c3c3;
  padding: 2px;
  margin-bottom: 7.5px;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.15);
}
.modular-content .module--accordion .accordion__item:last-of-type {
  margin-bottom: 0;
}
.modular-content .module--accordion .accordion__item--open .accordion__content {
  display: block;
}
.modular-content .module--accordion .accordion__item--open .accordion__title {
  background: #efefef;
}
.modular-content .module--accordion .accordion__title {
  cursor: pointer;
  padding: 15px;
}
.modular-content .module--accordion .accordion__title:hover {
  background: #efefef;
}
.modular-content .module--accordion .accordion__title:hover i {
  color: #00599b;
}
.modular-content .module--accordion .accordion__title strong {
  display: block;
  float: left;
  margin-right: 7.5px;
  width: calc(100% - 7.5px - 1rem);
}
.modular-content .module--accordion .accordion__title i {
  display: block;
  float: right;
  color: #0068b4;
  font-size: 1rem;
  width: 1rem;
  text-align: center;
}
.modular-content .module--accordion .accordion__content {
  padding: 15px;
  display: none;
}
.modular-content .modular-content-column {
  width: 48.7179487179%;
  margin-right: 2.5641025641%;
  float: left;
  opacity: 0;
}
@media screen and (max-width: 1020px) {
  .modular-content .modular-content-column {
    width: 100%;
    margin-right: 0;
    float: none;
  }
  .modular-content .modular-content-column + .modular-content-column {
    margin-top: 30px;
  }
}
.modular-content .modular-content-column + .modular-content-column {
  margin-right: 0;
}
.modular-content .modular-content-column .module__title {
  margin-bottom: calc(30px - 0.5rem);
}
.modular-content .modular-content-column .module__inner {
  width: 100% !important;
}
.modular-content .modular-content-column .module--bg-dark, .modular-content .modular-content-column .module--bg-light {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background: #ffffff !important;
}
.modular-content .modular-content-column .module--bg-dark .module__title h3, .modular-content .modular-content-column .module--bg-light .module__title h3 {
  color: #656565;
}
.modular-content .modular-content-column .module--bg-dark .module__title strong, .modular-content .modular-content-column .module--bg-light .module__title strong {
  color: #acacac;
}
.modular-content .modular-content-column .module--bg-dark .textimage__title,
.modular-content .modular-content-column .module--bg-dark .formatted-text, .modular-content .modular-content-column .module--bg-light .textimage__title,
.modular-content .modular-content-column .module--bg-light .formatted-text {
  color: #656565;
}
.modular-content .modular-content-column .module--bg-dark .textimage__sub-title,
.modular-content .modular-content-column .module--bg-dark .text--discreet, .modular-content .modular-content-column .module--bg-light .textimage__sub-title,
.modular-content .modular-content-column .module--bg-light .text--discreet {
  color: #acacac !important;
}
.modular-content .modular-content-column .module--bg-dark.module--opinions .opinion, .modular-content .modular-content-column .module--bg-light.module--opinions .opinion {
  background: #ffffff;
}
.modular-content .modular-content-column .module--bg-dark.module--accordion .accordion__item, .modular-content .modular-content-column .module--bg-light.module--accordion .accordion__item {
  border-color: #c3c3c3;
}
.modular-content .modular-content-column .module--bg-dark.module--accordion .accordion__item--open .accordion__title, .modular-content .modular-content-column .module--bg-light.module--accordion .accordion__item--open .accordion__title {
  background: #efefef;
}
.modular-content .modular-content-column .module--bg-dark.module--accordion .accordion__item--open .accordion__title i, .modular-content .modular-content-column .module--bg-light.module--accordion .accordion__item--open .accordion__title i {
  color: #0068b4;
}
.modular-content .modular-content-column .module--bg-dark.module--accordion .accordion__item--open .accordion__title,
.modular-content .modular-content-column .module--bg-dark.module--accordion .accordion__title, .modular-content .modular-content-column .module--bg-light.module--accordion .accordion__item--open .accordion__title,
.modular-content .modular-content-column .module--bg-light.module--accordion .accordion__title {
  color: #656565;
}
.modular-content .modular-content-column .module--bg-dark.module--accordion .accordion__item--open .accordion__title:hover,
.modular-content .modular-content-column .module--bg-dark.module--accordion .accordion__title:hover, .modular-content .modular-content-column .module--bg-light.module--accordion .accordion__item--open .accordion__title:hover,
.modular-content .modular-content-column .module--bg-light.module--accordion .accordion__title:hover {
  background: #efefef;
}
.modular-content .modular-content-column .module--bg-dark .text--background-discreet, .modular-content .modular-content-column .module--bg-light .text--background-discreet {
  color: #656565;
}
.modular-content .modular-content-column .module--bg-dark.module--grid .grid__item, .modular-content .modular-content-column .module--bg-light.module--grid .grid__item {
  background: #efefef;
}
.modular-content .modular-content-column .module--bg-dark.module--grid .grid__content,
.modular-content .modular-content-column .module--bg-dark.module--grid .grid__title, .modular-content .modular-content-column .module--bg-light.module--grid .grid__content,
.modular-content .modular-content-column .module--bg-light.module--grid .grid__title {
  color: #656565;
}
.modular-content .modular-content-column .module--bg-dark.module--downloads ul li, .modular-content .modular-content-column .module--bg-light.module--downloads ul li {
  border-bottom-color: #c3c3c3;
}
.modular-content .modular-content-column .module--bg-dark.module--downloads ul li > a, .modular-content .modular-content-column .module--bg-light.module--downloads ul li > a {
  color: #656565;
}
.modular-content .modular-content-column .module--bg-dark.module--downloads ul li > a i, .modular-content .modular-content-column .module--bg-light.module--downloads ul li > a i {
  color: #0068b4;
}
.modular-content .modular-content-column .module--bg-dark.module--newsletter .newsletter-form, .modular-content .modular-content-column .module--bg-dark.module--newsletter .newsletter-form *, .modular-content .modular-content-column .module--bg-light.module--newsletter .newsletter-form, .modular-content .modular-content-column .module--bg-light.module--newsletter .newsletter-form * {
  color: #656565;
}
.modular-content .modular-content-column .module--bg-dark.module--newsletter .newsletter-form.cr_button, .modular-content .modular-content-column .module--bg-dark.module--newsletter .newsletter-form *.cr_button, .modular-content .modular-content-column .module--bg-light.module--newsletter .newsletter-form.cr_button, .modular-content .modular-content-column .module--bg-light.module--newsletter .newsletter-form *.cr_button {
  color: #ffffff !important;
}
.modular-content .modular-content-column .module:last-of-type.module--bg-light, .modular-content .modular-content-column .module:last-of-type.module--bg-dark {
  margin-bottom: 0 !important;
}
.modular-content .modular-content-column .module--bg-light + .module--bg-light, .modular-content .modular-content-column .module--bg-light + .module--bg-dark, .modular-content .modular-content-column .module--bg-dark + .module--bg-light, .modular-content .modular-content-column .module--bg-dark + .module--bg-dark {
  margin-top: 0 !important;
}
.modular-content .module--downloads ul {
  margin: 0;
  padding: 0;
}
.modular-content .module--downloads ul li {
  list-style: none !important;
  display: block;
  border-bottom: 1px dashed #c3c3c3;
  margin-bottom: 7.5px;
  padding-bottom: 7.5px;
}
.modular-content .module--downloads ul li:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
}
.modular-content .module--downloads ul li a {
  display: block;
  width: calc(100% - 100px);
  float: left;
  color: #656565;
  text-decoration: none;
}
.modular-content .module--downloads ul li a:hover {
  color: #0068b4;
}
.modular-content .module--downloads ul li a i {
  margin-right: 0.5em;
  font-size: 1.5rem;
  color: #0068b4;
}
.modular-content .module--downloads ul li span {
  display: block;
  width: 100px;
  text-align: right;
  float: right;
  color: #acacac;
}
@media screen and (min-width: 1021px) {
  .modular-content .modular-content-column > .module--form .module__title {
    margin-bottom: calc(45px - 0.5rem);
  }
  .modular-content .modular-content-column > .module--form div.field.size-1-of-3 {
    width: 29.8245614035%;
    margin-right: 5.2631578947%;
  }
  .modular-content .modular-content-column > .module--form div.field.size-1-of-2 {
    width: 47.3684210526%;
    margin-right: 5.2631578947%;
  }
  .modular-content .modular-content-column > .module--form div.field.size-2-of-3 {
    float: left;
    width: 64.9122807018%;
    margin-right: 5.2631578947%;
  }
}
.modular-content .module--gallery .gallery {
  margin: 0;
  padding: 0;
}
.modular-content .module--gallery .gallery li {
  display: block;
  float: left;
}
.modular-content .module--gallery .gallery li a {
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
.modular-content .module--gallery .gallery li a:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00e";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -60px;
  bottom: 0;
  z-index: 10;
  color: #0068b4;
  background: rgba(37, 37, 37, 0.85);
  text-align: right;
  padding: 15px 19px 0 0;
  font-size: 1.5rem;
  width: 100%;
  transition: all 0.2s linear;
}
.modular-content .module--gallery .gallery li a:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 4px;
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  background: rgba(0, 104, 180, 0.9);
  transition: all 0.2s linear;
  z-index: 20;
}
.modular-content .module--gallery .gallery li a:hover:before, .modular-content .module--gallery .gallery li a:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.modular-content .module--gallery .gallery li a:hover:after {
  left: 4px;
}
.modular-content .module--gallery .gallery li a img {
  display: block;
  width: 100%;
  height: auto;
}
@media screen and (max-width: 350px) {
  .modular-content .module--gallery .gallery--ipr-2 li {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .modular-content .module--gallery .gallery--ipr-2 li:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .modular-content .module--gallery .gallery--ipr-2 li:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--gallery .gallery--ipr-2 li:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 351px) {
  .modular-content .module--gallery .gallery--ipr-2 li {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .modular-content .module--gallery .gallery--ipr-2 li:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .modular-content .module--gallery .gallery--ipr-2 li:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--gallery .gallery--ipr-2 li:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (max-width: 350px) {
  .modular-content .module--gallery .gallery--ipr-3 li {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .modular-content .module--gallery .gallery--ipr-3 li:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .modular-content .module--gallery .gallery--ipr-3 li:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--gallery .gallery--ipr-3 li:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 351px) and (max-width: 768px) {
  .modular-content .module--gallery .gallery--ipr-3 li {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .modular-content .module--gallery .gallery--ipr-3 li:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .modular-content .module--gallery .gallery--ipr-3 li:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--gallery .gallery--ipr-3 li:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 769px) {
  .modular-content .module--gallery .gallery--ipr-3 li {
    float: left;
    width: 31.6239316239%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .modular-content .module--gallery .gallery--ipr-3 li:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  .modular-content .module--gallery .gallery--ipr-3 li:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--gallery .gallery--ipr-3 li:nth-of-type(3n+4) {
    clear: both;
  }
}
@media screen and (max-width: 350px) {
  .modular-content .module--gallery .gallery--ipr-4 li {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .modular-content .module--gallery .gallery--ipr-4 li:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .modular-content .module--gallery .gallery--ipr-4 li:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--gallery .gallery--ipr-4 li:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 351px) and (max-width: 768px) {
  .modular-content .module--gallery .gallery--ipr-4 li {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .modular-content .module--gallery .gallery--ipr-4 li:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .modular-content .module--gallery .gallery--ipr-4 li:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--gallery .gallery--ipr-4 li:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 769px) {
  .modular-content .module--gallery .gallery--ipr-4 li {
    float: left;
    width: 23.0769230769%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .modular-content .module--gallery .gallery--ipr-4 li:nth-of-type(-n + 4) {
    margin-top: 0;
  }
  .modular-content .module--gallery .gallery--ipr-4 li:nth-of-type(4n+4) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--gallery .gallery--ipr-4 li:nth-of-type(4n+5) {
    clear: both;
  }
}
@media screen and (max-width: 350px) {
  .modular-content .module--gallery .gallery--ipr-6 li {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .modular-content .module--gallery .gallery--ipr-6 li:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .modular-content .module--gallery .gallery--ipr-6 li:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--gallery .gallery--ipr-6 li:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 351px) and (max-width: 480px) {
  .modular-content .module--gallery .gallery--ipr-6 li {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .modular-content .module--gallery .gallery--ipr-6 li:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .modular-content .module--gallery .gallery--ipr-6 li:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--gallery .gallery--ipr-6 li:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 481px) and (max-width: 1036px) {
  .modular-content .module--gallery .gallery--ipr-6 li {
    float: left;
    width: 23.0769230769%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .modular-content .module--gallery .gallery--ipr-6 li:nth-of-type(-n + 4) {
    margin-top: 0;
  }
  .modular-content .module--gallery .gallery--ipr-6 li:nth-of-type(4n+4) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--gallery .gallery--ipr-6 li:nth-of-type(4n+5) {
    clear: both;
  }
}
@media screen and (min-width: 1037px) {
  .modular-content .module--gallery .gallery--ipr-6 li {
    float: left;
    width: 14.5299145299%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .modular-content .module--gallery .gallery--ipr-6 li:nth-of-type(-n + 6) {
    margin-top: 0;
  }
  .modular-content .module--gallery .gallery--ipr-6 li:nth-of-type(6n+6) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--gallery .gallery--ipr-6 li:nth-of-type(6n+7) {
    clear: both;
  }
}
.modular-content .module--grid .grid__item {
  display: block;
  float: left;
  border: 3px solid #3f3b48;
  padding: 15px;
}
.modular-content .module--grid .grid__header {
  text-align: center;
  margin-bottom: 15px;
}
.modular-content .module--grid .grid__header img {
  display: inline-block;
  width: 100%;
  height: auto;
}
.modular-content .module--grid .grid__header i {
  font-size: 2.375rem;
}
.modular-content .module--grid .grid__header a {
  color: #656565;
}
.modular-content .module--grid .grid__header a:hover {
  color: #0068b4;
}
.modular-content .module--grid .grid__title {
  margin-bottom: 15px;
}
.modular-content .module--grid .grid__title h4 {
  font-size: 0.75rem;
  text-transform: uppercase;
}
.modular-content .module--grid .grid__title:after {
  content: "";
  width: 90px;
  height: 2px;
  background: #3f3b48;
  display: block;
  margin-top: 15px;
}
.modular-content .module--grid .grid__link {
  display: inline-block;
  margin-top: 15px;
  text-decoration: none;
}
.modular-content .module--grid .grid--align-center {
  text-align: center;
}
@media screen and (max-width: 480px) {
  .modular-content .module--grid .grid--ipr-2 .grid__item {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .modular-content .module--grid .grid--ipr-2 .grid__item:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .modular-content .module--grid .grid--ipr-2 .grid__item:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--grid .grid--ipr-2 .grid__item:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 481px) {
  .modular-content .module--grid .grid--ipr-2 .grid__item {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .modular-content .module--grid .grid--ipr-2 .grid__item:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .modular-content .module--grid .grid--ipr-2 .grid__item:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--grid .grid--ipr-2 .grid__item:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (max-width: 480px) {
  .modular-content .module--grid .grid--ipr-3 .grid__item {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .modular-content .module--grid .grid--ipr-3 .grid__item:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .modular-content .module--grid .grid--ipr-3 .grid__item:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--grid .grid--ipr-3 .grid__item:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .modular-content .module--grid .grid--ipr-3 .grid__item {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .modular-content .module--grid .grid--ipr-3 .grid__item:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .modular-content .module--grid .grid--ipr-3 .grid__item:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--grid .grid--ipr-3 .grid__item:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 769px) {
  .modular-content .module--grid .grid--ipr-3 .grid__item {
    float: left;
    width: 31.6239316239%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .modular-content .module--grid .grid--ipr-3 .grid__item:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  .modular-content .module--grid .grid--ipr-3 .grid__item:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--grid .grid--ipr-3 .grid__item:nth-of-type(3n+4) {
    clear: both;
  }
}
@media screen and (max-width: 480px) {
  .modular-content .module--grid .grid--ipr-4 .grid__item {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .modular-content .module--grid .grid--ipr-4 .grid__item:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .modular-content .module--grid .grid--ipr-4 .grid__item:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--grid .grid--ipr-4 .grid__item:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .modular-content .module--grid .grid--ipr-4 .grid__item {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .modular-content .module--grid .grid--ipr-4 .grid__item:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .modular-content .module--grid .grid--ipr-4 .grid__item:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--grid .grid--ipr-4 .grid__item:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 769px) {
  .modular-content .module--grid .grid--ipr-4 .grid__item {
    float: left;
    width: 23.0769230769%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .modular-content .module--grid .grid--ipr-4 .grid__item:nth-of-type(-n + 4) {
    margin-top: 0;
  }
  .modular-content .module--grid .grid--ipr-4 .grid__item:nth-of-type(4n+4) {
    margin-right: 0;
    float: right;
  }
  .modular-content .module--grid .grid--ipr-4 .grid__item:nth-of-type(4n+5) {
    clear: both;
  }
}
.modular-content .module--grid .grid--as-slider {
  border: 3px solid #3f3b48;
  padding: 30px 75px;
}
.modular-content .module--grid .grid--as-slider a.grid__link {
  color: #0068b4;
}
.modular-content .module--grid .grid--as-slider a.grid__link:hover {
  color: #00599b;
}
.modular-content .module--grid .grid--as-slider a.grid__link:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f178";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  margin-left: 0.5em;
}
.modular-content .module--grid .grid--as-slider .grid__item {
  border: 0;
  padding: 0;
}
.modular-content .module--grid .grid--as-slider .grid__title h4 {
  font-size: 1.25rem;
}
.modular-content .module--grid .grid--as-slider .slick-list {
  margin-right: -1.2820512821%;
  margin-left: -1.2820512821%;
}
.modular-content .module--grid .grid--as-slider .slick-arrow {
  position: absolute;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: color 0.2s linear;
  color: #ffffff;
  font-size: 0.875rem;
  padding: 15px 17px;
  background: #3f3b48;
}
.modular-content .module--grid .grid--as-slider .slick-arrow.slick-disabled {
  color: #acacac;
  cursor: default;
}
.modular-content .module--grid .grid--as-slider .slick-arrow:not(.slick-disabled) {
  cursor: pointer;
}
.modular-content .module--grid .grid--as-slider .slick-arrow:not(.slick-disabled):hover {
  background: #0068b4;
}
.modular-content .module--grid .grid--as-slider .slick-arrow.fa-chevron-left {
  left: 0;
}
.modular-content .module--grid .grid--as-slider .slick-arrow.fa-chevron-right {
  right: 0;
}
.modular-content .module--grid .grid--as-slider .slick-dots {
  margin-top: 15px;
  font-size: 0;
  text-align: center;
}
.modular-content .module--grid .grid--as-slider .slick-dots li {
  display: inline-block;
  margin-right: 2px;
}
.modular-content .module--grid .grid--as-slider .slick-dots li:last-of-type {
  margin-right: 0;
}
.modular-content .module--grid .grid--as-slider .slick-dots li:last-of-type:first-of-type {
  display: none;
}
.modular-content .module--grid .grid--as-slider .slick-dots li button {
  cursor: pointer;
  display: block;
  width: 12px;
  height: 12px;
  background: #efefef;
  border-radius: 100%;
  font-size: 0;
  border: 0;
  box-shadow: none;
  line-height: 1;
}
.modular-content .module--grid .grid--as-slider .slick-dots li button:hover {
  background: #3f3b48;
}
.modular-content .module--grid .grid--as-slider .slick-dots li.slick-active button {
  background: #0068b4;
}
.modular-content .module--grid .grid--as-slider .slick-dots li.slick-active button:hover {
  background: #004b81;
}
.modular-content .module--grid .grid__content-wrapper .grid__header {
  float: right;
  width: 23.0769230769%;
}
.modular-content .module--grid .grid__content-wrapper .grid__header img {
  display: block;
  width: 100%;
  height: auto;
}
.modular-content .module--grid .grid__content-wrapper .grid__content {
  float: left;
  width: 74.358974359%;
  margin-right: 2.5641025641%;
}
.modular-content .module--headline h2 {
  font-size: 1.625rem;
}
.modular-content .module--latestnewspage .module__inner {
  background-color: #efefef;
  padding: 30px;
}
.modular-content .module--latestnewspage .module__title {
  margin-bottom: 15px;
}
.modular-content .module--latestnewspage .module__title h3 {
  font-size: 1.25rem;
}
.modular-content .module--latestnewspage .module__title:after {
  content: none;
}
.modular-content .module--latestnewspage .latest-news-page .news-preview {
  padding: 0;
  background-color: unset;
}
.modular-content .module--latestnewspage .latest-news-page .news-preview h2 {
  font-size: 1.25rem;
}
@media screen and (max-width: 480px) {
  .modular-content .module--latestnewspage .latest-news-page .news-preview__image {
    width: 100%;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .modular-content .module--latestnewspage .latest-news-page .news-preview__image img {
    width: 75%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 769px) {
  .modular-content .module--latestnewspage .latest-news-page .news-preview {
    display: flex;
  }
  .modular-content .module--latestnewspage .latest-news-page .news-preview__image {
    width: 25%;
  }
  .modular-content .module--latestnewspage .latest-news-page .news-preview__text {
    flex-grow: 1;
  }
}
.modular-content .module--latestnewspage .latest-news-page__archive-link > a {
  margin-top: 10px;
  color: #0068b4;
  display: block;
}
.modular-content .module--latestnewspage .latest-news-page__archive-link > a:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f178";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  margin-left: 0.5em;
}
.modular-content .module--newsletter.module--as-popup {
  display: none !important;
}
.modular-content .module--newsletter .newsletter-form {
  overflow: hidden;
}
.modular-content .module--newsletter .newsletter-form--fpr-1 .cr_ipe_item {
  width: 100%;
}
.modular-content .module--newsletter .newsletter-form .submit_container,
.modular-content .module--newsletter .newsletter-form div[rel=recaptcha] {
  float: none !important;
  margin-right: 0 !important;
  clear: both !important;
  width: 100%;
}
.modular-content .module--newsletter .newsletter-form div[rel=recaptcha] br {
  display: none !important;
}
.modular-content .module--newsletter .newsletter-form .badge {
  display: none;
}
.modular-content .module--newsletter .newsletter-form .clever_form_error {
  border-color: #dd3c3c;
}
.modular-content .module--newsletter .newsletter-form .cr_button {
  width: auto;
  font-size: 1rem;
  text-transform: uppercase;
}
.modular-content .module--newsletter.module--bg-dark .newsletter-form, .modular-content .module--newsletter.module--bg-dark .newsletter-form * {
  color: #ffffff;
}
@media screen and (min-width: 481px) {
  .modular-content > .module--newsletter .newsletter-form--fpr-2 .cr_ipe_item,
.modular-content .module--newsletter.module--breakpoint-reached .newsletter-form--fpr-2 .cr_ipe_item {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
  }
  .modular-content > .module--newsletter .newsletter-form--fpr-2 .cr_ipe_item:nth-of-type(2n+2),
.modular-content .module--newsletter.module--breakpoint-reached .newsletter-form--fpr-2 .cr_ipe_item:nth-of-type(2n+2) {
    margin-right: 0;
  }
  .modular-content > .module--newsletter .newsletter-form--fpr-2 .cr_ipe_item:nth-of-type(2n+3),
.modular-content .module--newsletter.module--breakpoint-reached .newsletter-form--fpr-2 .cr_ipe_item:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 481px) {
  .modular-content > .module--newsletter .newsletter-form--fpr-3 .cr_ipe_item,
.modular-content .module--newsletter.module--breakpoint-reached .newsletter-form--fpr-3 .cr_ipe_item {
    float: left;
    width: 31.6239316239%;
    margin-right: 2.5641025641%;
  }
  .modular-content > .module--newsletter .newsletter-form--fpr-3 .cr_ipe_item:nth-of-type(3n+3),
.modular-content .module--newsletter.module--breakpoint-reached .newsletter-form--fpr-3 .cr_ipe_item:nth-of-type(3n+3) {
    margin-right: 0;
  }
  .modular-content > .module--newsletter .newsletter-form--fpr-3 .cr_ipe_item:nth-of-type(3n+4),
.modular-content .module--newsletter.module--breakpoint-reached .newsletter-form--fpr-3 .cr_ipe_item:nth-of-type(3n+4) {
    clear: both;
  }
}
@media screen and (min-width: 1021px) and (min-width: 1021px) {
  .modular-content .modular-content-column > .module--newsletter .newsletter-form--fpr-2 .cr_ipe_item {
    float: left;
    width: 47.3684210526%;
    margin-right: 5.2631578947%;
  }
  .modular-content .modular-content-column > .module--newsletter .newsletter-form--fpr-2 .cr_ipe_item:nth-of-type(2n+2) {
    margin-right: 0;
  }
  .modular-content .modular-content-column > .module--newsletter .newsletter-form--fpr-2 .cr_ipe_item:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 1021px) and (min-width: 1021px) {
  .modular-content .modular-content-column > .module--newsletter .newsletter-form--fpr-3 .cr_ipe_item {
    float: left;
    width: 29.8245614035%;
    margin-right: 5.2631578947%;
  }
  .modular-content .modular-content-column > .module--newsletter .newsletter-form--fpr-3 .cr_ipe_item:nth-of-type(3n+3) {
    margin-right: 0;
  }
  .modular-content .modular-content-column > .module--newsletter .newsletter-form--fpr-3 .cr_ipe_item:nth-of-type(3n+4) {
    clear: both;
  }
}
.modular-content .module--textimage .module__inner .textimage__image a,
.modular-content .module--textimage .module__inner a.textimage__image {
  display: block;
}
.modular-content .module--textimage .module__inner .textimage__image a[data-lightbox],
.modular-content .module--textimage .module__inner a.textimage__image[data-lightbox] {
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
.modular-content .module--textimage .module__inner .textimage__image a[data-lightbox]:after,
.modular-content .module--textimage .module__inner a.textimage__image[data-lightbox]:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00e";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -60px;
  bottom: 0;
  z-index: 10;
  color: #0068b4;
  background: rgba(37, 37, 37, 0.85);
  text-align: right;
  padding: 15px 19px 0 0;
  font-size: 1.5rem;
  width: 100%;
  transition: all 0.2s linear;
}
.modular-content .module--textimage .module__inner .textimage__image a[data-lightbox]:before,
.modular-content .module--textimage .module__inner a.textimage__image[data-lightbox]:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 4px;
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  background: rgba(0, 104, 180, 0.9);
  transition: all 0.2s linear;
  z-index: 20;
}
.modular-content .module--textimage .module__inner .textimage__image a[data-lightbox]:hover:before, .modular-content .module--textimage .module__inner .textimage__image a[data-lightbox]:hover:after,
.modular-content .module--textimage .module__inner a.textimage__image[data-lightbox]:hover:before,
.modular-content .module--textimage .module__inner a.textimage__image[data-lightbox]:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.modular-content .module--textimage .module__inner .textimage__image a[data-lightbox]:hover:after,
.modular-content .module--textimage .module__inner a.textimage__image[data-lightbox]:hover:after {
  left: 4px;
}
.modular-content .module--textimage .module__inner .textimage__image a:not([data-lightbox]),
.modular-content .module--textimage .module__inner a.textimage__image:not([data-lightbox]) {
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
.modular-content .module--textimage .module__inner .textimage__image a:not([data-lightbox]):after,
.modular-content .module--textimage .module__inner a.textimage__image:not([data-lightbox]):after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f061";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -60px;
  bottom: 0;
  z-index: 10;
  color: #0068b4;
  background: rgba(37, 37, 37, 0.85);
  text-align: right;
  padding: 15px 19px 0 0;
  font-size: 1.5rem;
  width: 100%;
  transition: all 0.2s linear;
}
.modular-content .module--textimage .module__inner .textimage__image a:not([data-lightbox]):before,
.modular-content .module--textimage .module__inner a.textimage__image:not([data-lightbox]):before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 4px;
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  background: rgba(0, 104, 180, 0.9);
  transition: all 0.2s linear;
  z-index: 20;
}
.modular-content .module--textimage .module__inner .textimage__image a:not([data-lightbox]):hover:before, .modular-content .module--textimage .module__inner .textimage__image a:not([data-lightbox]):hover:after,
.modular-content .module--textimage .module__inner a.textimage__image:not([data-lightbox]):hover:before,
.modular-content .module--textimage .module__inner a.textimage__image:not([data-lightbox]):hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.modular-content .module--textimage .module__inner .textimage__image a:not([data-lightbox]):hover:after,
.modular-content .module--textimage .module__inner a.textimage__image:not([data-lightbox]):hover:after {
  left: 4px;
}
.modular-content .module--textimage .module__inner .textimage__image a img,
.modular-content .module--textimage .module__inner a.textimage__image img {
  display: block;
  width: 100%;
  height: auto;
}
.modular-content .module--textimage .textimage--image-top .textimage__image {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .modular-content .module--textimage .textimage--image-top .textimage__image {
    margin-bottom: 15px;
  }
}
.modular-content .module--textimage .textimage--image-top .textimage__image img {
  display: block;
  width: 100%;
  height: auto;
  border: 1px solid #efefef;
  padding: 15px;
}
.modular-content .module--textimage .textimage--image-bottom .textimage__image {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .modular-content .module--textimage .textimage--image-bottom .textimage__image {
    margin-top: 15px;
  }
}
.modular-content .module--textimage .textimage--image-bottom .textimage__image img {
  display: block;
  width: 100%;
  height: auto;
  border: 1px solid #efefef;
  padding: 15px;
}
.modular-content .module--textimage .textimage .formatted-text .textimage__title {
  margin-top: 0 !important;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.modular-content .module--textimage .textimage .formatted-text .textimage__title:after {
  content: "";
  height: 2px;
  width: 90px;
  background: #3f3b48;
  margin-top: 15px;
  display: block;
}
.modular-content .module--textimage .textimage--image-left .textimage__image, .modular-content .module--textimage .textimage--image-right .textimage__image {
  width: 23.0769230769%;
}
.modular-content .module--textimage .textimage--image-left .textimage__image img, .modular-content .module--textimage .textimage--image-right .textimage__image img {
  display: block;
  width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid #efefef;
  padding: 15px;
}
.modular-content .module--textimage .textimage--image-left .formatted-text, .modular-content .module--textimage .textimage--image-right .formatted-text {
  width: 74.358974359%;
  float: left;
}
@media screen and (max-width: 480px) {
  .modular-content .module--textimage .textimage--image-left .textimage__image, .modular-content .module--textimage .textimage--image-right .textimage__image {
    float: none !important;
    margin: 0;
    width: 100%;
  }
  .modular-content .module--textimage .textimage--image-left .textimage__image + .formatted-text, .modular-content .module--textimage .textimage--image-right .textimage__image + .formatted-text {
    margin-top: 15px !important;
  }
  .modular-content .module--textimage .textimage--image-left .formatted-text, .modular-content .module--textimage .textimage--image-right .formatted-text {
    float: none;
    margin: 0;
    width: 100%;
  }
  .modular-content .module--textimage .textimage--image-left .formatted-text + .textimage__image, .modular-content .module--textimage .textimage--image-right .formatted-text + .textimage__image {
    margin-top: 15px !important;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .modular-content .module--textimage .textimage--image-left .textimage__image, .modular-content .module--textimage .textimage--image-right .textimage__image {
    width: 48.7179487179%;
  }
  .modular-content .module--textimage .textimage--image-left .formatted-text, .modular-content .module--textimage .textimage--image-right .formatted-text {
    width: 48.7179487179%;
  }
}
.modular-content .module--textimage .textimage--image-left .textimage__image {
  float: left;
  margin-right: 2.5641025641%;
}
.modular-content .module--textimage .textimage--image-left .formatted-text {
  float: right;
}
.modular-content .module--textimage .textimage--image-right .textimage__image {
  float: right;
  margin-left: 2.5641025641%;
}
.modular-content .module--textimage .textimage--text-floating .formatted-text {
  width: 100%;
}
.modular-content .module--textimage .textimage--text-floating .formatted-text p {
  overflow: visible;
}
.modular-content .module--textimage .textimage--text-floating .textimage__image {
  display: block;
  float: left;
  margin-bottom: 7.5px;
}
@media screen and (max-width: 480px) {
  .modular-content .module--textimage .textimage--text-floating .textimage__image {
    margin: 0 !important;
    margin-bottom: 15px !important;
  }
}
.modular-content .module--textimage .textimage--text-floating.textimage--image-right .textimage__image {
  float: right;
}

.banner-caption-block {
  margin-bottom: 30px;
  margin-top: -1px;
}
.banner-caption-block .slick-dots li {
  float: left;
  display: block;
  border: 1px solid #c3c3c3;
  padding: 15px;
  margin-right: -1px;
  margin-top: -1px;
}
@media screen and (min-width: 769px) {
  .banner-caption-block .slick-dots li {
    width: calc(33.33333% + 1px);
  }
  .banner-caption-block .slick-dots li:nth-of-type(3n+3) {
    width: 33.33333%;
  }
  .banner-caption-block .slick-dots li:nth-of-type(-n+3) {
    border-top: 0;
  }
}
@media screen and (max-width: 768px) {
  .banner-caption-block .slick-dots li {
    width: calc(50% + 1px);
  }
  .banner-caption-block .slick-dots li:nth-of-type(2n+2) {
    width: 50%;
  }
  .banner-caption-block .slick-dots li:nth-of-type(-n+2) {
    border-top: 0;
  }
}
.banner-caption-block .slick-dots li h3 {
  font-size: 1.25rem;
  margin-bottom: 7.5px;
}
.banner-caption-block .slick-dots li p {
  font-size: 0.875rem;
  margin-bottom: 7.5px;
}
.banner-caption-block .slick-dots li a {
  font-size: 0;
  display: inline-block;
  margin-left: -7.5px;
  margin-top: -7.5px;
}
.banner-caption-block .slick-dots li a:after {
  font-size: 1rem;
  padding: 7.5px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f178";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
}
.banner-caption-block .slick-dots li.slick-active {
  background: #0068b4;
  border-color: #0068b4;
}
.banner-caption-block .slick-dots li.slick-active, .banner-caption-block .slick-dots li.slick-active * {
  color: #FFFFFF;
}
@media screen and (max-width: 1036px) {
  .banner-caption-block .slick-dots li {
    padding: 10px;
  }
}

.modular-content .module--form {
  border: 3px solid #3f3b48;
  padding: 30px;
}
@media screen and (max-width: 1036px) {
  .modular-content .module--form {
    padding: 20px;
  }
}
.modular-content .module--form .module__title {
  margin-bottom: 15px;
  text-transform: uppercase;
}
.modular-content #UserForm_Form_40 .form-step .field {
  width: calc(50% - 15px);
  float: left;
}
@media screen and (max-width: 950px) {
  .modular-content #UserForm_Form_40 .form-step .field {
    width: 100%;
    margin-right: 0;
  }
}
.modular-content #UserForm_Form_40 .form-step .field:first-of-type {
  margin-right: 30px;
}
.modular-content #UserForm_Form_40 .form-step .field input {
  width: 100%;
}
.modular-content #UserForm_Form_40 .form-step .checkbox {
  width: 100% !important;
}
.modular-content #UserForm_Form_40 nav {
  text-align: right;
}

.contact-person {
  position: fixed;
  right: 45px;
  bottom: 45px;
  z-index: 99999;
}
.contact-person__toggle {
  display: block;
  position: relative;
  z-index: 10;
  cursor: pointer;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  background: #0068b4;
  border-radius: 100%;
  transition: background 0.2s linear;
  color: #ffffff;
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.15);
}
.contact-person__toggle:hover {
  background: #00599b;
}
.contact-person__details {
  display: none;
  position: absolute;
  padding: 15px;
  width: 350px;
  top: 15px;
  left: 15px;
  transform: translateX(-100%) translateY(-100%);
  background: #efefef;
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.15);
}
.contact-person__details .team-member__image {
  float: left;
  width: 100px;
  margin-right: 15px;
}
.contact-person__details .team-member__info {
  float: left;
  width: 205px;
}
.contact-person__details .team-member__info li.team-member__separator {
  display: block;
  width: 60px;
  height: 2px;
  margin: 7.5px 0 10px 0;
  background: #c3c3c3;
  line-height: 1;
}
.contact-person__details .team-member__info li i {
  margin-right: 0.5em;
  color: #0068b4;
}
.contact-person__details .team-member__info li a {
  text-decoration: none;
  color: #656565;
  font-weight: 400;
}
.contact-person__details .team-member__info li a:hover {
  color: #0068b4;
}

html .klaro .cookie-modal .cm-modal.cm-klaro {
  background-color: white;
  border-radius: 0;
  border: 0px;
  padding: 2rem;
  z-index: 1000002;
  box-sizing: border-box;
  box-shadow: 0 0 20px -10px #000000;
}
html .klaro .cookie-modal .cm-modal.cm-klaro h1, html .klaro .cookie-modal .cm-modal.cm-klaro p {
  color: black;
}
html .klaro .cookie-modal .cm-modal.cm-klaro h1 a, html .klaro .cookie-modal .cm-modal.cm-klaro p a {
  color: black;
  text-decoration: underline;
}
html .klaro .cookie-modal .cm-modal.cm-klaro .cm-body .cm-purposes .cm-purpose input[type=checkbox] + label:before {
  display: none;
}
html .klaro .cookie-modal .cm-modal.cm-klaro .cm-body .cm-purposes .cm-purpose .cm-list-title {
  color: black;
  font-size: inherit;
  font-weight: 300;
}
html .klaro .cookie-modal .cm-modal.cm-klaro .cm-body .cm-purposes .cm-purpose .cm-list-description {
  padding: 0;
  font-size: var(--font-size-sm);
}
html .klaro .cookie-modal .cm-modal.cm-klaro .cm-body .cm-purposes .cm-purpose .cm-caret a {
  color: var(--blue);
}
html .klaro .cookie-modal .cm-modal.cm-klaro .cm-body .cm-toggle-all {
  display: none;
}
html .klaro .cookie-modal .cm-modal.cm-klaro .cm-body .slider {
  box-shadow: none;
}
html .klaro .cookie-modal .cm-modal.cm-klaro .cm-body .cm-list-input.required:checked + .cm-list-label .slider {
  background-color: black;
}
html .klaro .cookie-modal .cm-modal.cm-klaro .cm-body .cm-list-input:checked + .cm-list-label .slider {
  background-color: black;
}
html .klaro .cookie-modal .cm-modal.cm-klaro .cm-footer {
  padding: 1em 0;
}
html .klaro .cookie-modal .cm-modal.cm-klaro .cm-footer .cm-footer-buttons {
  flex-flow: column;
}
@media screen and (min-width: 1037px) {
  html .klaro .cookie-modal .cm-modal.cm-klaro .cm-footer .cm-footer-buttons {
    flex-flow: row;
  }
}
html .klaro .cookie-modal .cm-modal.cm-klaro .cm-footer .cm-footer-buttons button.cm-btn {
  cursor: pointer;
  padding: 6px 8px;
  justify-content: center;
}
html .klaro .cookie-modal .cm-modal.cm-klaro .cm-footer .cm-footer-buttons button.cm-btn.cm-btn-decline, html .klaro .cookie-modal .cm-modal.cm-klaro .cm-footer .cm-footer-buttons button.cm-btn.cm-btn-success {
  background-color: var(--gray-light);
  color: black;
}
html .klaro .cookie-modal .cm-modal.cm-klaro .cm-footer .cm-footer-buttons button.cm-btn.cm-btn-decline:hover, html .klaro .cookie-modal .cm-modal.cm-klaro .cm-footer .cm-footer-buttons button.cm-btn.cm-btn-success:hover {
  background-color: var(--blue-light);
}
html .klaro .cookie-modal .cm-modal.cm-klaro .cm-footer .cm-footer-buttons button.cm-btn.cm-btn-accept-all {
  background-color: black;
  color: white;
}
html .klaro .cookie-modal .cm-modal.cm-klaro .cm-footer .cm-footer-buttons button.cm-btn.cm-btn-accept-all:hover {
  background-color: black;
}
html .klaro .cm-powered-by {
  display: none;
}

.cookieconsent-config {
  cursor: pointer;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9999999999;
  padding: 10px;
  width: 40px;
  height: 40px;
  line-height: 20px;
  text-align: center;
  background: #fff;
  box-shadow: 0 0 20px -10px #000;
}

form#Form_ProductsFilterForm {
  margin-bottom: 30px;
}
form#Form_ProductsFilterForm fieldset .field {
  float: left;
  margin-right: 2.5641025641%;
}
@media screen and (min-width: 769px) {
  form#Form_ProductsFilterForm fieldset .field {
    width: 23.0769230769%;
  }
  form#Form_ProductsFilterForm fieldset .field:nth-child(4n) {
    margin-right: 0;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  form#Form_ProductsFilterForm fieldset .field {
    width: 48.7179487179%;
  }
  form#Form_ProductsFilterForm fieldset .field:nth-child(2n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 480px) {
  form#Form_ProductsFilterForm fieldset .field {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
  form#Form_ProductsFilterForm fieldset .field:last-of-type {
    margin-bottom: 0;
  }
}
form#Form_ProductsFilterForm fieldset .field label {
  font-size: 0.75rem;
  text-transform: uppercase;
  text-transform: uppercase;
}
form#Form_ProductsFilterForm fieldset .field label:after {
  content: "";
  height: 2px;
  display: block;
  width: 90px;
  background: #656565;
  margin: 15px 0 12px 0;
}
form#Form_ProductsFilterForm .btn-toolbar {
  width: 100%;
}

.footer {
  background: #efefef;
  margin-top: 30px;
  border-top: 1px solid #c3c3c3;
}
@media screen and (min-width: 769px) {
  .footer .navigation--footer {
    float: left;
  }
  .footer > span {
    float: right;
  }
}
.footer__top {
  margin: 15px 0 30px;
}
.footer__top .footer__cols .footer__col {
  float: left;
  width: 23.0769230769%;
  margin-right: 2.5641025641%;
}
@media screen and (max-width: 768px) {
  .footer__top .footer__cols .footer__col {
    width: 48.7179487179%;
  }
}
@media screen and (max-width: 480px) {
  .footer__top .footer__cols .footer__col {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
    font-size: 0.875rem;
  }
  .footer__top .footer__cols .footer__col:last-of-type {
    margin-bottom: 0;
  }
}
.footer__top .footer__cols .footer__col strong {
  font-size: 0.75rem;
  text-transform: uppercase;
}
.footer__top .footer__cols .footer__col strong:after {
  content: "";
  height: 2px;
  display: block;
  width: 90px;
  background: #656565;
  margin: 15px 0 12px 0;
}
.footer__top .footer__cols .footer__col li {
  margin-bottom: 10px;
}
.footer__top .footer__cols .footer__col li a {
  font-weight: 600;
}
.footer__top .footer__cols .footer__col:first-of-type {
  width: 48.7179487179%;
}
@media screen and (max-width: 768px) {
  .footer__top .footer__cols .footer__col:first-of-type {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 350px) {
  .footer__top .footer__cols .footer__col:first-of-type li {
    width: 100% !important;
  }
}
.footer__top .footer__cols .footer__col:first-of-type li {
  width: 50%;
  float: left;
}
.footer__top .footer__cols .footer__col:last-of-type {
  margin-right: 0;
}
.footer__bottom {
  background: #3f3b48;
  color: #ffffff;
  padding: 15px 0;
  font-size: 0.875rem;
}

.main__text + form {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .main__text + form {
    margin-top: 15px;
  }
}

button,
input,
textarea,
select {
  font-family: inherit;
  font-weight: inherit;
  font-style: inherit;
  font-size: inherit;
  color: inherit;
  line-height: 1.35em;
  outline: 0;
  border: 0;
  box-shadow: none;
}

label {
  cursor: pointer;
}
@media screen and (max-width: 1036px) {
  label {
    font-size: 0.875rem;
  }
}

input,
textarea,
select {
  border: 1px solid #c3c3c3;
  background: transparent;
}
@media screen and (max-width: 1036px) {
  input,
textarea,
select {
    font-size: 0.875rem;
  }
}
input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset]),
textarea:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset]),
select:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset]) {
  display: block;
  padding: 7.5px 10px;
  width: 100%;
}
input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset])[disabled],
textarea:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset])[disabled],
select:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=reset])[disabled] {
  cursor: not-allowed;
  opacity: 0.7;
}
input:focus,
textarea:focus,
select:focus {
  outline: 0;
  border: 1px solid #0068b4;
}

input[type=submit],
input[type=reset] {
  width: auto;
}

form .field {
  margin-bottom: 15px;
}
form .field:last-child {
  margin-bottom: 0;
}
form .field.optionset > .middleColumn > div {
  margin-top: 7.5px;
}
form .field.optionset > .middleColumn > div:first-of-type {
  margin-top: 0;
}
form .field .message {
  display: block;
  margin-top: 15px;
}
form .field .description {
  display: block;
  margin-top: 7.5px;
  font-size: 0.875rem;
}
form .field .right-title, form .field > label.right {
  display: block;
  font-size: 0.875rem;
  margin-top: 7.5px;
  color: #acacac;
}
form .field > label.left, form .field > legend.left {
  display: block;
  margin-bottom: 10px;
}
form .field.requiredField > label.left:after {
  content: " *";
  color: #dd3c3c;
}
form .Actions {
  margin-top: 15px;
}

input[type=checkbox] {
  display: none;
  cursor: pointer;
}
input[type=checkbox] + label:before {
  margin-right: 0.5em;
  cursor: pointer;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f0c8";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  color: #656565;
}
input[type=checkbox]:checked + label:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f14a";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
}
input[type=checkbox][disabled] {
  cursor: not-allowed;
}
input[type=checkbox][disabled] + label:before {
  cursor: not-allowed;
  color: #acacac;
}
input[type=radio] {
  display: none;
  cursor: pointer;
}
input[type=radio] + label:before {
  margin-right: 0.5em;
  cursor: pointer;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f111";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  color: #656565;
}
input[type=radio]:checked + label:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f192";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
}
input[type=radio][disabled] {
  cursor: not-allowed;
}
input[type=radio][disabled] + label:before {
  cursor: not-allowed;
  color: #acacac;
}

@media screen and (min-width: 769px) {
  #MemberLoginForm_LoginForm,
#MemberLoginForm_LostPasswordForm {
    width: 50%;
  }
}

#MemberLoginForm_LoginForm #ForgotPassword {
  margin-top: 7.5px;
}

.header {
  padding-top: 30px;
  margin-bottom: 30px;
}
.header__left {
  float: left;
  width: 140px;
  margin-right: 30px;
}
.header__right {
  float: left;
  text-align: right;
  width: calc(100% - 170px);
}
.header__right-top .navigation--header,
.header__right-top .language-switcher {
  float: right;
}
.header__right-bottom {
  margin-top: calc((95px - 0.875rem) / 2);
}

/*Änderungen IFrame Kontaktformular - Mail Dominik 9.10.23*/
#iframe-forms .backgroundSecBg {
  width: 100% !important;
}

#iframe-forms .backgroundBg {
  width: 100%;
}

#iframe-forms {
  width: 100%;
  height: 759px;
  float: left;
}

#iframe-forms .templateWidth {
  margin: 0 !important;
  padding: 0 !important;
  float: left;
}

#Name-li {
  padding: 0 !important;
}

#formBodyDiv {
  padding: 0 !important;
}

#iframe-forms .templateWidth, .tyTemplateWidth {
  margin: 0 !important;
  float: left !important;
}

/*ENDE Änderungen IFrame Kontaktformular*/
@media screen and (min-width: 850px) {
  #m-128 div.grid__item:nth-child(2) {
    width: 33%;
  }

  #m-128 div.grid__item:nth-child(1) {
    width: 64%;
  }
}
#m-128 div.grid__item:nth-child(1) {
  border: none;
}

.language-switcher {
  float: right;
  font-size: 0;
  margin-right: 15px;
}
.language-switcher li {
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid #c3c3c3;
}
.language-switcher li:last-of-type {
  margin-right: 0;
  padding-right: 0;
  border-right: 0;
}
.language-switcher li a {
  display: block;
  line-height: 1;
  font-weight: 600;
}
.language-switcher li[class*=-current] a {
  color: #0068b4;
}

.latest-news > h2 {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .latest-news > h2 {
    margin-bottom: 15px;
  }
}
.latest-news > a {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .latest-news > a {
    margin-top: 15px;
  }
}
@media screen and (max-width: 480px) {
  .latest-news__child {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  .latest-news__child:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  .latest-news__child:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  .latest-news__child:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .latest-news__child {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .latest-news__child:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .latest-news__child:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .latest-news__child:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 769px) {
  .latest-news__child {
    float: left;
    width: 31.6239316239%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .latest-news__child:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  .latest-news__child:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  .latest-news__child:nth-of-type(3n+4) {
    clear: both;
  }
}
.latest-news__child > a {
  display: block;
  margin-bottom: 15px;
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
@media screen and (max-width: 768px) {
  .latest-news__child > a {
    margin-bottom: 7.5px;
  }
}
.latest-news__child > a:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f178";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -60px;
  bottom: 0;
  z-index: 10;
  color: #0068b4;
  background: rgba(37, 37, 37, 0.85);
  text-align: right;
  padding: 15px 19px 0 0;
  font-size: 1.5rem;
  width: 100%;
  transition: all 0.2s linear;
}
.latest-news__child > a:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 4px;
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  background: rgba(0, 104, 180, 0.9);
  transition: all 0.2s linear;
  z-index: 20;
}
.latest-news__child > a:hover:before, .latest-news__child > a:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.latest-news__child > a:hover:after {
  left: 4px;
}
.latest-news__child img {
  display: block;
  width: 100%;
  height: auto;
}
.latest-news__child h3 {
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  .latest-news__child h3 {
    margin-bottom: 7.5px;
  }
}

.loader {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  background: rgba(255, 255, 255, 0.8);
}
.loader .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -25px;
  display: block !important;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}
.loader .spinner > div {
  display: inline-block;
  height: 100%;
  width: 4px;
  background: #0068b4;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.loader .spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.loader .spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.loader .spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.loader .spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
.mobile-navigation-scrollbarhider {
  display: none;
}
.mobile-navigation-scrollbarhider--force-deactived {
  display: none !important;
}

@media (max-resolution: 1dppx) {
  .mobile-navigation--force-active + .mobile-navigation-scrollbarhider {
    content: "";
    display: block;
    position: fixed;
    top: -10px;
    bottom: -10px;
    right: 268px;
    width: 17px;
    background: #ffffff;
    z-index: 9999;
  }
}
.mobile-navigation {
  position: fixed;
  overflow-y: scroll;
  z-index: 9998;
  top: 0;
  bottom: 0;
  width: 270px;
  transition: right 0.2s ease;
  right: -270px;
  background: #ffffff;
}
@media screen and (max-width: 950px) {
  .mobile-navigation--active, .mobile-navigation--force-active {
    right: 0;
    margin-right: 0;
  }
  .mobile-navigation:not(.mobile-navigation--active) {
    right: -270px !important;
  }
}
@media (max-resolution: 1dppx) {
  .mobile-navigation {
    width: 287px;
    margin-right: -17px;
  }
  .mobile-navigation--active, .mobile-navigation--force-active {
    margin-right: -17px;
  }
}
.mobile-navigation .navigation--header {
  display: block !important;
  padding: 15px;
  background: #efefef;
  text-transform: uppercase;
}
.mobile-navigation .navigation--header ul {
  text-align: left;
}
.mobile-navigation .navigation--header ul li,
.mobile-navigation .navigation--header ul li a {
  font-size: 0.75rem;
}

.mobile-navigation-overlay {
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9990;
  opacity: 0;
  visibility: hidden;
  background: #000000;
  transition: all 0.2s ease;
}
@media screen and (max-width: 950px) {
  .mobile-navigation-overlay--active {
    opacity: 0.8;
    visibility: visible;
  }
}
.mobile-navigation-overlay--force-active {
  opacity: 0.8;
  visibility: visible;
}

.navigation {
  position: relative;
  z-index: 500;
}
.navigation ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.navigation ul li {
  position: relative;
  line-height: 1;
  font-size: 1rem;
}
.navigation ul li[class*="--current"] > a, .navigation ul li[class*="--section"] > a, .navigation ul li:hover > a {
  color: #0068b4;
}
.navigation ul li a {
  display: block;
  color: #656565;
  white-space: nowrap;
  text-decoration: none;
  line-height: 1;
}
.navigation .navigation__sub {
  display: block;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.1s linear, visibility 0.1s linear;
  background: #0068b4;
}
.navigation .navigation__sub-header {
  display: none;
}
.navigation .navigation__sub li {
  text-align: left;
}
.navigation--vertical .navigation__sub {
  transform: translateX(100%);
  top: 0;
  right: 0;
}
.navigation--vertical ul li:hover > .navigation__sub {
  opacity: 1;
  visibility: visible;
}
.navigation--horizontal .navigation__top {
  font-size: 0;
  text-align: right;
}
.navigation--horizontal .navigation__top-link {
  display: inline-block;
}
.navigation--horizontal .navigation__top-link:hover > .navigation__sub {
  opacity: 1;
  visibility: visible;
  top: 1rem;
}
.navigation--horizontal .navigation__top-link > .navigation__sub {
  left: 0;
}
.navigation--horizontal .navigation__top-link .navigation__sub {
  min-width: 100%;
}
.navigation--horizontal .navigation__top-link .navigation__sub .navigation__sub {
  transform: translateX(100%);
  top: 0;
  right: 0;
}
.navigation--horizontal .navigation__top-link .navigation__sub li:hover > .navigation__sub {
  opacity: 1;
  visibility: visible;
}
.navigation--horizontal .navigation__top-link:nth-last-of-type(-n+2) > .navigation__sub {
  left: auto;
  right: 0;
}
.navigation--horizontal .navigation__top-link:nth-last-of-type(-n+2) > .navigation__sub .navigation__sub {
  transform: translateX(-100%);
  right: auto;
  left: 0;
}
.navigation .mobile-navigation-button {
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #656565;
  float: right;
}
.navigation .mobile-navigation-button:hover {
  color: #0068b4;
}
.navigation--main .navigation__top-link {
  font-size: 1rem;
  font-weight: 600;
  background: #3f3b48;
}
.navigation--main .navigation__top-link:hover {
  background: #0068b4;
}
.navigation--main .navigation__top-link:hover a {
  color: #FFFFFF;
}
.navigation--main .navigation__top-link--current, .navigation--main .navigation__top-link--section {
  background: #0068b4;
}
.navigation--main .navigation__top-link--current a, .navigation--main .navigation__top-link--section a {
  color: #ffffff !important;
}
.navigation--main .navigation__top-link a {
  display: block;
  color: #FFFFFF;
  line-height: 45px;
  height: 45px;
  padding: 0 15px;
}
.navigation--main.navigation--horizontal .navigation__top-link:hover > .navigation__sub {
  top: 2.8rem;
}
@media screen and (max-width: 950px) {
  .navigation--main .mobile-navigation-button {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border: 3px solid #0068b4;
    text-align: center;
  }
  .navigation--main .mobile-navigation-button i {
    line-height: 40px;
    display: block;
    margin-top: -0.125em;
  }
  .navigation--main .navigation__top {
    display: none;
  }
}
@media screen and (max-width: 950px) {
  .navigation--header {
    display: none;
  }
}
.navigation--header .navigation__top-link {
  margin-right: 15px;
}
.navigation--header .navigation__top-link:last-of-type {
  margin-right: 0;
}
.navigation--header .navigation__top-link a {
  font-weight: 600;
  font-size: 0.875rem;
}
.navigation--footer ul li {
  margin-right: 15px;
}
.navigation--footer ul li:last-of-type {
  margin-right: 0;
}
.navigation--mobile ul li:hover > .navigation__sub {
  display: none;
  transform: translateX(0);
}
.navigation--mobile .navigation__sub {
  clear: both;
  position: static !important;
  display: none;
  border-top: 1px solid #c3c3c3;
  opacity: 1 !important;
  visibility: visible !important;
  transform: none !important;
}
.navigation--mobile .navigation__sub--active {
  display: block !important;
}
.navigation--mobile .navigation__sub .navigation__sub-link {
  border-bottom: 1px solid #c3c3c3;
}
.navigation--mobile .navigation__sub .navigation__sub-link--has-sub > a {
  float: left;
  width: calc(100% - 1em - 15px);
}
.navigation--mobile .navigation__sub .navigation__sub-link--has-sub > .navigation__sub-toggle {
  padding: 7.5px;
  width: calc(1em + 15px);
}
.navigation--mobile .navigation__sub .navigation__sub-link:last-of-type {
  border-bottom: 0;
}
.navigation--mobile .navigation__sub-toggle {
  display: block;
  float: left;
  cursor: pointer;
  color: #acacac;
  line-height: 1;
}
.navigation--mobile .navigation__sub-toggle:hover, .navigation--mobile .navigation__sub-toggle--active {
  color: #656565;
}
.navigation--mobile .navigation__sub-toggle--active i:before {
  content: "\f056";
}
.navigation--mobile .navigation__top-link {
  border-bottom: 1px solid #c3c3c3;
}
.navigation--mobile .navigation__top-link > a {
  padding: 15px;
  font-weight: 600;
}
.navigation--mobile .navigation__top-link--has-sub > a {
  float: left;
  width: calc(100% - 1em - 15px);
}
.navigation--mobile .navigation__top-link > .navigation__sub-toggle {
  padding: 15px 7.5px;
  width: calc(1em + 15px);
}
.navigation--mobile .navigation__top-link > .navigation__sub a {
  padding: 7.5px 15px;
  color: white;
}
.navigation--mobile .navigation__top-link > .navigation__sub > li > .navigation__sub > li > a {
  background: #f2f2f2;
}
.navigation--mobile .navigation__top-link > .navigation__sub > li > .navigation__sub > li > .navigation__sub-toggle {
  background: #f2f2f2;
}
.navigation--mobile .navigation__top-link > .navigation__sub > li > .navigation__sub > li > .navigation__sub > li > a {
  background: #e6e6e6;
}

.page-media {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .page-media {
    margin-top: 15px;
  }
}
.page-media__images {
  margin: 0;
  padding: 0;
}
.page-media__images li {
  list-style: none !important;
}
@media screen and (max-width: 768px) {
  .page-media__images li {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .page-media__images li:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  .page-media__images li:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  .page-media__images li:nth-of-type(2n+3) {
    clear: both;
  }
}
@media screen and (min-width: 769px) and (max-width: 1036px) {
  .page-media__images li {
    float: left;
    width: 31.6239316239%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .page-media__images li:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  .page-media__images li:nth-of-type(3n+3) {
    margin-right: 0;
    float: right;
  }
  .page-media__images li:nth-of-type(3n+4) {
    clear: both;
  }
}
@media screen and (min-width: 1037px) {
  .page-media__images li {
    float: left;
    width: 14.5299145299%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  .page-media__images li:nth-of-type(-n + 6) {
    margin-top: 0;
  }
  .page-media__images li:nth-of-type(6n+6) {
    margin-right: 0;
    float: right;
  }
  .page-media__images li:nth-of-type(6n+7) {
    clear: both;
  }
}
.page-media__images li a {
  display: block;
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
.page-media__images li a:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00e";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -60px;
  bottom: 0;
  z-index: 10;
  color: #0068b4;
  background: rgba(37, 37, 37, 0.85);
  text-align: right;
  padding: 15px 19px 0 0;
  font-size: 1.5rem;
  width: 100%;
  transition: all 0.2s linear;
}
.page-media__images li a:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 4px;
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  background: rgba(0, 104, 180, 0.9);
  transition: all 0.2s linear;
  z-index: 20;
}
.page-media__images li a:hover:before, .page-media__images li a:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.page-media__images li a:hover:after {
  left: 4px;
}
.page-media__images li a img {
  display: block;
  width: 100%;
  height: auto;
}
.page-media__images + .page-media__files {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .page-media__images + .page-media__files {
    margin-top: 15px;
  }
}
.page-media__files {
  margin: 0;
  padding: 0;
}
.page-media__files li {
  list-style: none !important;
  display: block;
  border-bottom: 1px dashed #c3c3c3;
  margin-bottom: 7.5px;
  padding-bottom: 7.5px;
}
.page-media__files li:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
}
.page-media__files li a {
  display: block;
  width: calc(100% - 100px);
  float: left;
  color: #656565;
  text-decoration: none;
}
.page-media__files li a:hover {
  color: #0068b4;
}
.page-media__files li a i {
  margin-right: 0.5em;
  font-size: 1.5rem;
  color: #0068b4;
}
.page-media__files li span {
  display: block;
  width: 100px;
  text-align: right;
  float: right;
  color: #acacac;
}

#grasenhiller_homepage .page-slider .page-slider__caption {
  display: none !important;
}
#grasenhiller_homepage .page-slider .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 20;
  display: block;
  color: #ffffff;
  font-size: 0.875rem;
  padding: 15px 17px;
  background: #3f3b48;
  font-weight: 600;
  cursor: pointer;
}
@media screen and (max-width: 950px) {
  #grasenhiller_homepage .page-slider .slick-arrow {
    display: none !important;
  }
}
#grasenhiller_homepage .page-slider .slick-arrow:hover {
  background: #0068b4;
}
#grasenhiller_homepage .page-slider .slick-arrow.fa-chevron-left {
  left: 3px;
}
#grasenhiller_homepage .page-slider .slick-arrow.fa-chevron-right {
  right: 3px;
}
#grasenhiller_homepage .page-slider .slick-dots {
  position: absolute;
  z-index: 20;
  bottom: 15px;
  top: 20px;
  right: 20px;
  font-size: 0;
  text-align: center;
}
#grasenhiller_homepage .page-slider .slick-dots li {
  display: inline-block;
  margin-right: 5px;
}
#grasenhiller_homepage .page-slider .slick-dots li:last-of-type {
  margin-right: 0;
}
#grasenhiller_homepage .page-slider .slick-dots li:last-of-type:first-of-type {
  display: none;
}
#grasenhiller_homepage .page-slider .slick-dots li button {
  cursor: pointer;
  display: block;
  width: 20px;
  height: 20px;
  background: #3f3b48;
  border-radius: 100%;
  font-size: 0;
  border: 0;
  box-shadow: none;
}
#grasenhiller_homepage .page-slider .slick-dots li button:hover {
  background: #0068b4;
}
#grasenhiller_homepage .page-slider .slick-dots li.slick-active button {
  background: #0068b4;
}
#grasenhiller_homepage .page-slider .slick-dots li.slick-active button:hover {
  background: #004b81;
}
#grasenhiller_homepage .page-slider__slide {
  position: relative;
}
#grasenhiller_homepage .page-slider__slide img {
  display: block;
  width: 100%;
  height: auto;
  border: 3px solid #0068b4;
}
#grasenhiller_homepage .page-slider__slide img[data-lazy] {
  display: none;
}

.page--banner-small .page-slider {
  margin-bottom: 30px;
}
.page--banner-small .page-slider .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 20;
  color: #ffffff;
  font-size: 1.5rem;
  display: none;
}
.page--banner-small .page-slider .slick-arrow:hover {
  color: #0068b4;
}
.page--banner-small .page-slider .slick-arrow.fa-chevron-left {
  left: 15px;
}
.page--banner-small .page-slider .slick-arrow.fa-chevron-right {
  right: 15px;
}
.page--banner-small .page-slider .slick-dots {
  position: absolute;
  z-index: 20;
  bottom: 15px;
  left: 0;
  right: 0;
  font-size: 0;
  text-align: center;
  display: none;
}
.page--banner-small .page-slider .slick-dots li {
  display: inline-block;
  margin-right: 2px;
}
.page--banner-small .page-slider .slick-dots li:last-of-type {
  margin-right: 0;
}
.page--banner-small .page-slider .slick-dots li:last-of-type:first-of-type {
  display: none;
}
.page--banner-small .page-slider .slick-dots li button {
  cursor: pointer;
  display: block;
  width: 10px;
  height: 10px;
  background: #efefef;
  border-radius: 100%;
  font-size: 0;
  border: 0;
  box-shadow: none;
}
.page--banner-small .page-slider .slick-dots li button:hover {
  background: #3f3b48;
}
.page--banner-small .page-slider .slick-dots li.slick-active button {
  background: #0068b4;
}
.page--banner-small .page-slider .slick-dots li.slick-active button:hover {
  background: #004b81;
}
.page--banner-small .page-slider__slide {
  position: relative;
  background: #3f3b48;
}
.page--banner-small .page-slider__slide img {
  display: block;
  height: auto;
  float: right;
  width: 50%;
}
@media screen and (max-width: 768px) {
  .page--banner-small .page-slider__slide img {
    width: 100%;
  }
}
.page--banner-small .page-slider__caption {
  width: 50%;
  float: left;
  background: #3f3b48;
  padding: 30px;
}
@media screen and (max-width: 768px) {
  .page--banner-small .page-slider__caption {
    padding: 20px;
  }
}
@media screen and (max-width: 768px) {
  .page--banner-small .page-slider__caption {
    width: 100%;
  }
}
.page--banner-small .page-slider__caption-content .banner-description {
  display: none;
}
.page--banner-small .page-slider__caption-content > h3, .page--banner-small .page-slider__caption-content > h1 {
  font-size: 2.375rem;
  font-weight: 600;
  font-family: "Titillium Web", sans-serif;
  color: #ffffff;
  text-transform: uppercase;
}
.page--banner-small .page-slider__caption-content > h3:after, .page--banner-small .page-slider__caption-content > h1:after {
  content: "";
  width: 90px;
  height: 2px;
  background: #ffffff;
  display: block;
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .page--banner-small .page-slider__caption-content > h3:after, .page--banner-small .page-slider__caption-content > h1:after {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  .page--banner-small .page-slider__caption-content > h3, .page--banner-small .page-slider__caption-content > h1 {
    font-size: 1.625rem;
  }
}
.page--banner-small .page-slider__caption-content > p {
  margin-top: 30px;
  font-size: 1.125rem;
  font-weight: 400;
  color: #ffffff;
  max-width: 780px;
}
@media screen and (max-width: 768px) {
  .page--banner-small .page-slider__caption-content > p {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  .page--banner-small .page-slider__caption-content > p {
    font-size: 1rem;
  }
}
.page--banner-small .page-slider__caption-content > p span {
  box-decoration-break: clone;
}
.page--banner-small .page-slider__caption-content > a {
  margin-top: 30px;
  display: inline-block;
  color: #ffffff;
  font-size: 1.125rem;
}
@media screen and (max-width: 768px) {
  .page--banner-small .page-slider__caption-content > a {
    margin-top: 15px;
  }
}
.page--banner-small .page-slider__caption-content > a:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f30b";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  font-weight: 600;
  margin-right: 5px;
}
@media screen and (max-width: 768px) {
  .page--banner-small .page-slider__caption-content > a {
    font-size: 1rem;
  }
}
.page--banner-small .page-slider__caption-content.page-slider__caption-content--font-color-light > h3, .page--banner-small .page-slider__caption-content.page-slider__caption-content--font-color-light > h1, .page--banner-small .page-slider__caption-content.page-slider__caption-content--font-color-light > p, .page--banner-small .page-slider__caption-content.page-slider__caption-content--font-color-light > a {
  color: #ffffff;
}

.page-title {
  margin-bottom: 30px;
  background: #3f3b48;
  color: #ffffff;
  padding: 30px;
  display: none;
}
@media screen and (max-width: 768px) {
  .page-title {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  .page-title {
    padding: 20px;
  }
}
.page-title h1 {
  line-height: 1;
  font-size: 1.625rem;
  text-transform: uppercase;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .page-title h1 {
    font-size: 1.625rem;
  }
}
.page-title p {
  font-size: 1.125rem;
}
@media screen and (max-width: 1036px) {
  .page-title p {
    font-size: 1rem;
  }
}
.page-title .breadcrumbs {
  margin-bottom: 7.5px;
  color: #acacac;
}
.page-title .breadcrumbs a {
  color: #acacac;
}
.page-title .breadcrumbs a:hover {
  color: #0068b4;
}

.page--no-banner .page-title {
  display: block;
}

/*.page-title {
	@include resp-margin(bottom);
	@extend %cf;

	&__left{
		background: $box-color-dark;
		color: $page-bg;
		padding: $dist;
		@extend %formatted-text;
		width: 50%;
		float: left;

		@include breakpoint(null, $bp-l) {
			padding: 20px;
		}

		@include breakpoint(null, $bp-nav) {
			width: 100%;
		}

		h1 {
			line-height: 1;
			font-size: $fs-h1;
			font-weight: $fw-h-bold;
			text-transform: uppercase;
			font-weight: $fw-h-light;

			@include breakpoint(null, $bp-m) {
				font-size: $fs-h2;
			}

			&:after {
				content: "";
				width: $dist*3;
				height: 2px;
				background: $page-bg;
				display: block;
				margin: $dist 0;
			}
		}

		p {
			font-size: $fs-h5;

			@include breakpoint(null, $bp-l) {
				font-size: $fs;
			}
		}
		.breadcrumbs {
			margin-bottom: $dist/4;
			color: $fc-light;

			a {
				color: $fc-light;

				&:hover {
					color: $ac;
				}
			}
		}
	}

	&__right {
		float: left;
		width: 50%;

		img {
			width: 100%;
			height: 100%;
			min-height: 470px;
		}

		@include breakpoint(null, $bp-nav) {
			width: 100%;
		}
	}
}*/
.pagination {
  text-align: center;
  padding-top: 30px;
}
@media screen and (max-width: 768px) {
  .pagination {
    padding-top: 15px;
  }
}
.pagination:before {
  display: block;
  content: "";
  border-top: 1px solid #c3c3c3;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .pagination:before {
    margin-bottom: 15px;
  }
}
.pagination a,
.pagination span {
  display: inline-block;
  width: 42px;
  height: 42px;
  line-height: 42px;
  background: #3f3b48;
  color: #ffffff;
}
@media screen and (max-width: 1036px) {
  .pagination a,
.pagination span {
    font-size: 0.875rem;
  }
}
.pagination a {
  text-decoration: none;
  transition: all 0.2s;
}
.pagination a:hover {
  background: #0068b4;
  color: #FFFFFF;
}
.pagination span.pagination__current {
  background: #0068b4;
  color: #FFFFFF;
}

.products-per-page {
  float: left;
  margin-top: 30px;
}
.products-per-page + .pagination {
  float: left;
  margin-left: 30px;
}
.product-information {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .product-information {
    margin-bottom: 15px;
  }
}
.product-information table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
}
.product-information table thead th {
  border-bottom: 2px solid #c3c3c3 !important;
  border-right: 0 !important;
}
.product-information table thead + tbody tr th:first-child {
  border-right: 0;
}
.product-information table tfoot th {
  border-top: 2px solid #c3c3c3;
  border-right: 0 !important;
  border-bottom: 0 !important;
}
.product-information table td {
  border-bottom: 1px solid #c3c3c3;
}
.product-information table th,
.product-information table td {
  text-align: left;
  padding: 0.5rem 0.75rem;
}
.product-information table th > *:first-child,
.product-information table td > *:first-child {
  margin-top: 0 !important;
}
.product-information table tr th:first-child {
  border-right: 2px solid #c3c3c3;
  border-bottom: 1px solid #c3c3c3;
}
.product-information .table--two-heads table tbody tr th:first-child {
  border-right: 2px solid #c3c3c3 !important;
  border-bottom: 1px solid #c3c3c3;
}
.product-information .table--striped tbody tr:nth-child(odd) {
  background-color: #efefef;
}
.product-information .table--unstyled {
  width: 100%;
}
.product-information .table--unstyled tr, .product-information .table--unstyled th, .product-information .table--unstyled td {
  border: 0 !important;
  background: 0 !important;
  padding: 0 !important;
}
.product-information .table--unstyled tr td,
.product-information .table--unstyled tr th {
  padding: 0 0.25rem 0 0 !important;
}
.product-information .table--unstyled tr td:last-of-type,
.product-information .table--unstyled tr th:last-of-type {
  padding-right: 0 !important;
}
.product-information .fa-exclamation-triangle {
  color: #dd3c3c;
  margin-top: 0.25em;
}
.product-information h4 {
  font-size: 1.25rem;
}
.product-information table {
  margin-top: 15px;
  border-left: 1px solid #efefef;
  border-top: 1px solid #efefef;
  border-right: 1px solid #efefef;
}
.product-information table td, .product-information table th {
  border-color: #efefef !important;
}

.step-button-wrapper .step-button-next,
.step-button-wrapper .inquiry-back-link {
  float: right;
}
.step-button-wrapper .inquiry-back-link {
  margin-right: 5px;
}
.step-button-wrapper .step-button-next[style*="display: none"] + .inquiry-back-link {
  display: none;
}

.navigation__box {
  position: relative;
  width: 100%;
  border: 3px solid #0068b4;
  border-bottom: 2px solid #0068b4;
  margin-bottom: 30px;
}
.navigation__box:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: #0068b4;
  z-index: 50;
}
.navigation__box-item--current {
  background: #0068b4;
  color: #FFFFFF;
}
.navigation__box-item--current * {
  color: #FFFFFF;
}
.navigation__box-item--current span:after {
  background: #FFFFFF !important;
}
.navigation__box .mobile-box-button {
  width: 100%;
  text-align: center;
  padding: 10px;
}
@media screen and (min-width: 951px) {
  .navigation__box .mobile-box-button {
    display: none;
  }
}
.navigation__box .mobile-box-button span {
  color: #0068b4;
}
.navigation__box i {
  display: block;
  color: #0068b4;
  font-size: 1.125rem;
  width: 100%;
  text-align: center;
  padding-bottom: 10px;
}
@media screen and (min-width: 951px) {
  .navigation__box i {
    display: none;
  }
}
.navigation__box i.fa-angle-up {
  display: none;
}
@media screen and (max-width: 950px) {
  .navigation__box ul {
    display: none;
  }
}
.navigation__box ul li {
  float: left;
  width: 25%;
  margin: 0 !important;
  display: block;
  border-right: 1px solid #c3c3c3;
  border-bottom: 1px solid #c3c3c3;
}
@media screen and (min-width: 769px) {
  .navigation__box ul li:nth-child(4n) {
    border-right: 0;
  }
}
@media screen and (min-width: 951px) and (max-width: 768px) {
  .navigation__box ul li {
    width: 33.3333333333%;
  }
  .navigation__box ul li:nth-child(3n) {
    border-right: 0;
  }
}
@media screen and (max-width: 950px) {
  .navigation__box ul li {
    width: 50%;
  }
  .navigation__box ul li:nth-child(2n) {
    border-right: 0;
  }
}
.navigation__box ul li a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 15px;
}
@media screen and (max-width: 1036px) {
  .navigation__box ul li a {
    padding: 10px;
  }
}
.navigation__box ul li a:hover {
  background: #0068b4;
  color: #ffffff;
}
.navigation__box ul li a:hover span:after {
  background: #ffffff;
}
.navigation__box ul li a span {
  font-size: 0.75rem;
  font-weight: 600;
}
.navigation__box ul li a span:after {
  content: "";
  height: 2px;
  display: block;
  width: 90px;
  background: #656565;
  margin: 15px 0 12px 0;
}
@media screen and (max-width: 1036px) {
  .navigation__box ul li a span:after {
    margin: 10px 0;
  }
}
.navigation__box ul li a h3 {
  text-transform: uppercase;
  font-size: 1.5rem;
}
@media screen and (max-width: 1036px) {
  .navigation__box ul li a h3 {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 768px) {
  .navigation__box ul li a h3 {
    font-size: 1rem;
  }
}
@media screen and (max-width: 480px) {
  .navigation__box ul li a h3 {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 950px) {
  .navigation__box-active .mobile-box-button {
    border-bottom: 1px solid #c3c3c3;
  }
  .navigation__box-active ul {
    display: block;
  }
  .navigation__box-active i.fa-angle-up {
    border-top: 1px solid #c3c3c3;
    padding-top: 10px;
    display: block;
    margin-top: -1px;
  }
  .navigation__box-active i.fa-angle-down {
    display: none;
  }
}
.products-listing .fa-exclamation-triangle {
  color: #dd3c3c;
  cursor: help;
}
.products-listing + .modular-content {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #e2e2e2;
}
.products-listing > .pagination {
  margin-top: 30px;
}
.products-listing__suffix-for-prev {
  display: none;
}
@media screen and (min-width: 769px) {
  .products-listing__resp-details {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .products-listing__resp-details ul {
    display: none;
    padding: 15px 0 15px 0;
    border-top: 1px dashed #e2e2e2;
  }
}
.products-listing--without-images table tr td:first-of-type,
.products-listing--without-images table tr th:first-of-type {
  text-align: left;
}
@media screen and (max-width: 768px) {
  .products-listing--without-images table tr td:first-of-type {
    cursor: pointer;
  }
  .products-listing--without-images table tr td:first-of-type:before {
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    content: "\f107";
    display: inline-block;
    font-style: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 1em;
    text-align: center;
    margin: 0 15px 0 0;
    color: #0068b4;
    vertical-align: middle;
    font-size: 1.25rem;
    font-weight: 600;
    padding: 22px 0;
  }
  .products-listing--without-images table tr th,
.products-listing--without-images table tr td {
    display: none;
  }
  .products-listing--without-images table tr th:first-of-type, .products-listing--without-images table tr th:nth-last-child(-n+2),
.products-listing--without-images table tr td:first-of-type,
.products-listing--without-images table tr td:nth-last-child(-n+2) {
    display: table-cell;
  }
}
.products-listing--with-images table tr td:nth-of-type(1),
.products-listing--with-images table tr th:nth-of-type(1) {
  width: 130px;
}
.products-listing--with-images table tr td:nth-of-type(2),
.products-listing--with-images table tr th:nth-of-type(2) {
  text-align: left;
}
@media screen and (max-width: 768px) {
  .products-listing--with-images table tr td:nth-of-type(2) {
    cursor: pointer;
  }
  .products-listing--with-images table tr td:nth-of-type(2):before {
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    content: "\f107";
    display: inline-block;
    font-style: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 1em;
    text-align: center;
    margin: 0 15px 0 0;
    color: #0068b4;
    vertical-align: middle;
    font-size: 1.25rem;
    font-weight: 600;
    padding: 22px 0;
  }
  .products-listing--with-images table tr th,
.products-listing--with-images table tr td {
    display: none;
  }
  .products-listing--with-images table tr th:nth-of-type(2), .products-listing--with-images table tr th:nth-last-child(-n+2),
.products-listing--with-images table tr td:nth-of-type(2),
.products-listing--with-images table tr td:nth-last-child(-n+2) {
    display: table-cell;
  }
}
.products-listing table {
  width: 100%;
}
.products-listing table tr th,
.products-listing table tr td {
  padding: 0 15px;
  text-align: center;
  height: 80px;
  vertical-align: middle;
}
@media screen and (max-width: 768px) {
  .products-listing table tr td img {
    border: 1px solid #c3c3c3;
  }
}
@media screen and (max-width: 480px) {
  .products-listing table tr td img {
    display: none;
  }
}
.products-listing table tr td:first-of-type {
  text-align: left;
}
.products-listing table tr td .attribute__suffix {
  font-size: 0.875rem;
}
.products-listing table thead tr th {
  font-size: 0.75rem;
  color: #ffffff;
  background: #3f3b48;
  text-align: center;
  height: 37.5px;
}
@media screen and (max-width: 480px) {
  .products-listing table thead tr th {
    font-size: 0.75rem;
  }
}
.products-listing table tbody tr:nth-of-type(odd) {
  background: #efefef;
}
.products-listing table tbody tr td:first-of-type {
  font-weight: 600;
}
.products-listing table tfoot tr td {
  padding: 0;
}
.products-listing table tfoot tr td .col-left {
  float: left;
  padding-top: 30px;
  width: 200px;
}
.products-listing table tfoot tr td .col-right {
  float: right;
  padding-top: 30px;
  width: calc(100% - 200px);
}
.products-listing .pagination {
  padding-top: 0;
  text-align: left;
}
.products-listing .pagination > * {
  text-align: center;
}
.products-listing .pagination:before {
  display: none;
}
.products-listing__datasheet {
  width: 90px;
}
.products-listing__datasheet a {
  display: block;
  margin-top: -15px;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: -15px;
  font-size: 1.25rem;
  color: #ffffff;
}
.products-listing__datasheet a:hover i {
  background: #0068b4;
}
.products-listing__datasheet a i {
  padding: 15px 17px;
  background: #3f3b48;
  font-size: 1rem;
}
.products-listing__inquiry {
  width: 60px;
}
.products-listing__inquiry a {
  display: block;
  margin-top: -15px;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: -15px;
  font-size: 1.25rem;
  color: #656565;
}
.products-listing__inquiry a:hover i {
  background: #0068b4;
  color: #FFFFFF;
}
.products-listing__inquiry a i {
  padding: 15px 17px;
  font-size: 1rem;
  color: #656565;
}
.products-listing__inquiry a {
  display: block;
  margin-top: -15px;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: -15px;
  color: #ffffff;
  font-size: 1.25rem;
}

.team-members .team-member__image,
.search-results__list--TeamMember .search-results__list-items .team-member__image {
  position: relative;
  float: left;
  margin-right: 15px;
  width: 29.8245614035%;
  background: #efefef;
  text-align: center;
}
.team-members .team-member__image img,
.search-results__list--TeamMember .search-results__list-items .team-member__image img {
  display: block;
  width: 100%;
  height: auto;
}
.team-members .team-member__image--no-image,
.search-results__list--TeamMember .search-results__list-items .team-member__image--no-image {
  position: relative !important;
  overflow: hidden !important;
  padding-top: 133.5294117647% !important;
}
.team-members .team-member__image--no-image > *:first-child,
.search-results__list--TeamMember .search-results__list-items .team-member__image--no-image > *:first-child {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.team-members .team-member__image--no-image > *:first-child,
.search-results__list--TeamMember .search-results__list-items .team-member__image--no-image > *:first-child {
  font-size: 2.5rem;
  color: #c3c3c3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  right: initial;
  bottom: initial;
}
.team-members .team-member__info,
.search-results__list--TeamMember .search-results__list-items .team-member__info {
  float: left;
  width: 64.9122807018%;
}
.team-members .team-member__info i,
.search-results__list--TeamMember .search-results__list-items .team-member__info i {
  margin-right: 0.5em;
  color: #0068b4;
}
.team-members .team-member__info a,
.search-results__list--TeamMember .search-results__list-items .team-member__info a {
  font-weight: 400;
}
.team-members .team-member__separator,
.search-results__list--TeamMember .search-results__list-items .team-member__separator {
  line-height: 1;
  height: 15px;
}
.team-members .team-member__title,
.search-results__list--TeamMember .search-results__list-items .team-member__title {
  font-weight: 600;
}

.back-to-top {
  display: none;
  position: fixed;
  z-index: 9999;
  right: 15px;
  bottom: 60px;
  width: 2em;
  height: 2em;
  line-height: 2em;
  text-align: center;
  background: #0068b4;
  color: #ffffff !important;
  border-radius: 3px;
}
.back-to-top:hover {
  background: #00599b;
}
.back-to-top i:before {
  position: relative;
  top: -1px;
}

html .userform-fields {
  margin-top: 0 !important;
}
html .userform-progress {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  html .userform-progress {
    margin-bottom: 15px;
  }
}
html .userform-progress > p {
  display: none;
}
html .userform-progress .progress {
  background: #efefef;
  height: 20px;
}
html .userform-progress .progress-bar {
  background: #3f3b48;
  height: 20px;
}
html .userform-progress > nav {
  margin-top: -20px;
}
html .userform-progress > nav ul {
  font-size: 0;
}
html .userform-progress > nav ul li {
  font-size: 1rem;
  height: 20px;
}
html .userform-progress > nav ul li button {
  display: block;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  height: 20px;
  min-width: 30px;
  text-align: center;
  background: #ffffff;
  border-right: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  color: #656565;
}
html .userform-progress > nav ul li:first-of-type button {
  border-left: 0 !important;
}
html .userform-progress > nav ul li:last-of-type button {
  border-right: 0 !important;
}
html .userform-progress > nav ul li.viewed button {
  cursor: pointer;
}
html .userform .progress-title {
  margin-top: 30px;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  html .userform .progress-title {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
html .userform .FormHeading {
  margin-bottom: 15px;
}
html .userform .step-navigation ul {
  font-size: 0;
}
html .userform .step-navigation ul li {
  font-size: 1rem;
}
html .userform .step-navigation ul li button.step-button-prev:not([style="display: none;"]) {
  margin-right: 7.5px;
}
html .userform .userformsgroup {
  margin-top: 15px;
  border: 1px solid #c3c3c3;
  border-radius: 3px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 30px;
  padding-left: 30px;
}
@media screen and (max-width: 768px) {
  html .userform .userformsgroup {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  html .userform .userformsgroup {
    padding-right: 15px;
    padding-left: 15px;
  }
}
html .userform .userformsgroup > legend {
  padding-left: 7.5px;
  padding-right: 7.5px;
  margin-left: -7.5px;
  border: 0;
  width: auto;
  font-weight: 600;
}
html .userform .step-button-wrapper.Actions {
  margin-top: 0;
  float: right;
}

#UserForm_Form_3 .userform-fields .field .field:first-of-type {
  width: calc(50% - (30px / 2));
  float: left;
  margin-right: 30px;
}
@media screen and (max-width: 768px) {
  #UserForm_Form_3 .userform-fields .field .field:first-of-type {
    width: 100%;
    margin-right: 0;
  }
}
#UserForm_Form_3 .userform-fields .field .field:nth-child(2) {
  width: calc(50% - (30px / 2));
  float: left;
}
@media screen and (max-width: 768px) {
  #UserForm_Form_3 .userform-fields .field .field:nth-child(2) {
    width: 100%;
  }
}

#grasenhiller_aboutuspage .main__text {
  margin-bottom: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 30px;
  padding-left: 30px;
  background: #efefef;
}
@media screen and (max-width: 768px) {
  #grasenhiller_aboutuspage .main__text {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  #grasenhiller_aboutuspage .main__text {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  #grasenhiller_aboutuspage .main__text {
    padding-right: 15px;
    padding-left: 15px;
  }
}
#grasenhiller_aboutuspage .main__text > h2:first-of-type:after {
  margin-bottom: 30px;
  margin-top: 15px;
  display: block;
  content: "";
  height: 2px;
  width: 90px;
  background: #3f3b48;
}
@media screen and (max-width: 768px) {
  #grasenhiller_aboutuspage .main__text > h2:first-of-type:after {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  #grasenhiller_aboutuspage .main__text > h2:first-of-type:after {
    margin-top: 7.5px;
  }
}
#grasenhiller_aboutuspage .about-us-grid {
  margin-bottom: 30px;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
  #grasenhiller_aboutuspage .about-us-grid {
    margin-bottom: 15px;
  }
}
#grasenhiller_aboutuspage .about-us-grid > div {
  margin-right: 2.5641025641%;
  border: 3px solid #3f3b48;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 30px;
  padding-left: 30px;
}
@media screen and (max-width: 768px) {
  #grasenhiller_aboutuspage .about-us-grid > div {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  #grasenhiller_aboutuspage .about-us-grid > div {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media screen and (min-width: 769px) {
  #grasenhiller_aboutuspage .about-us-grid > div {
    width: 31.6239316239%;
  }
  #grasenhiller_aboutuspage .about-us-grid > div:nth-of-type(3) {
    margin-right: 0;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  #grasenhiller_aboutuspage .about-us-grid > div {
    width: 48.7179487179%;
  }
  #grasenhiller_aboutuspage .about-us-grid > div:nth-of-type(2) {
    margin-right: 0;
  }
  #grasenhiller_aboutuspage .about-us-grid > div:nth-of-type(3) {
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
  }
}
@media screen and (max-width: 480px) {
  #grasenhiller_aboutuspage .about-us-grid > div {
    float: none;
    width: 100%;
    margin-right: 0;
    margin-top: 30px;
  }
  #grasenhiller_aboutuspage .about-us-grid > div:first-of-type {
    margin-top: 0;
  }
}
#grasenhiller_aboutuspage .about-us-grid > div img {
  display: block;
  width: 100%;
  height: auto;
}
#grasenhiller_aboutuspage .about-us-grid__title {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  #grasenhiller_aboutuspage .about-us-grid__title {
    margin-bottom: 15px;
  }
}
#grasenhiller_aboutuspage .about-us-grid__title > small {
  display: block;
  float: left;
  width: 7rem;
  overflow: hidden;
  border-bottom: 2px solid #3f3b48;
  padding-bottom: 15px;
  line-height: 1;
  text-transform: uppercase;
}
#grasenhiller_aboutuspage .about-us-grid__controls {
  float: right;
  width: calc(100% - 7rem);
  text-align: right;
  margin-top: -30px;
  margin-right: -30px;
}
@media screen and (max-width: 768px) {
  #grasenhiller_aboutuspage .about-us-grid__controls {
    margin-top: -15px;
  }
}
@media screen and (max-width: 768px) {
  #grasenhiller_aboutuspage .about-us-grid__controls {
    margin-right: -15px;
  }
}
#grasenhiller_aboutuspage .about-us-grid__controls i {
  background: #3f3b48;
  color: #ffffff;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  cursor: pointer;
}
#grasenhiller_aboutuspage .about-us-grid__controls i:hover {
  background: #0068b4;
}
#grasenhiller_aboutuspage .about-us-grid__controls i.slick-disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
#grasenhiller_aboutuspage .team-slider .team-member__info:first-of-type {
  margin-bottom: 15px;
}
#grasenhiller_aboutuspage .team-slider .team-member__info:last-of-type {
  margin-top: 15px;
}
#grasenhiller_aboutuspage .team-slider .team-member__title {
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
}
#grasenhiller_aboutuspage .certificates-slider .certificate strong {
  display: block;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 15px;
}
#grasenhiller_aboutuspage .partner-slider .partner strong {
  display: block;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 15px;
}
#grasenhiller_aboutuspage .partner-slider .partner img {
  margin-bottom: 15px;
}
#grasenhiller_aboutuspage .partner-slider .partner a {
  display: inline-block;
  margin-top: 15px;
}

html .basic-holder .basic-holder__text + .basic-holder__children {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #c3c3c3;
}
@media screen and (max-width: 768px) {
  html .basic-holder .basic-holder__text + .basic-holder__children {
    margin-top: 15px;
  }
}
@media screen and (max-width: 768px) {
  html .basic-holder .basic-holder__text + .basic-holder__children {
    padding-top: 15px;
  }
}
html .basic-holder__child {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  html .basic-holder__child {
    margin-top: 15px;
  }
}
html .basic-holder__child:first-of-type {
  margin-top: 0;
}
html .basic-holder__child > h3 {
  display: block;
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  html .basic-holder__child > h3 {
    margin-bottom: 7.5px;
  }
}
html .basic-holder__child > h3 a {
  color: #656565;
}
html .basic-holder__child > h3 a:hover {
  color: #0068b4;
}
html .basic-holder__child > p {
  margin: 0;
}
html .basic-holder__grand-children {
  margin: 0 !important;
  margin-top: 7.5px;
}
@media screen and (max-width: 480px) {
  html .basic-holder__grand-children {
    margin-top: 3.75px;
  }
}
html .basic-holder__grand-children li {
  display: block;
}
@media screen and (min-width: 769px) {
  html .basic-holder__grand-children li {
    float: left;
    width: calc(50% - 7.5px);
  }
  html .basic-holder__grand-children li:nth-of-type(2n+2) {
    margin-left: 15px;
  }
  html .basic-holder__grand-children li:nth-of-type(2n+3) {
    clear: both;
  }
}
html .basic-holder__pagination {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  html .basic-holder__pagination {
    margin-top: 15px;
  }
}

#grasenhiller_homepage .modular-content .module--textimage {
  background: #3f3b48;
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media screen and (max-width: 768px) {
  #grasenhiller_homepage .modular-content .module--textimage {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media screen and (max-width: 768px) {
  #grasenhiller_homepage .modular-content .module--textimage {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
#grasenhiller_homepage .modular-content .module--textimage, #grasenhiller_homepage .modular-content .module--textimage * {
  color: #FFFFFF;
}
#grasenhiller_homepage .modular-content .module--textimage .textimage__title:after {
  content: "";
  display: block;
  width: 90px;
  margin: 30px 0;
  height: 2px;
  background: #ffffff;
}
@media screen and (max-width: 768px) {
  #grasenhiller_homepage .modular-content .module--textimage .textimage__title:after {
    margin: 15px 0;
  }
}
#grasenhiller_homepage .modular-content .module--textimage .textimage__image img {
  background: #3f3b48;
  border: 0;
}

.news-preview {
  margin-bottom: 30px;
  background: #efefef;
  padding: 30px;
}
@media screen and (max-width: 768px) {
  .news-preview {
    margin-bottom: 15px;
  }
}
.news-preview:last-of-type {
  margin-bottom: 0;
}
@media screen and (max-width: 1036px) {
  .news-preview {
    padding: 20px;
  }
}
@media screen and (min-width: 769px) {
  .news-preview__image {
    width: 31.6239316239%;
    float: left;
    margin-right: 2.5641025641%;
  }
  .news-preview__text {
    width: 65.811965812%;
    float: left;
  }
}
@media screen and (max-width: 768px) {
  .news-preview__image {
    margin-bottom: 15px;
  }
}
.news-preview__image a {
  display: block;
}
.news-preview__image a:hover {
  text-decoration: none;
  border-bottom: 0;
}
.news-preview__image img {
  display: block;
  width: 100%;
  height: auto;
}
.news-preview__text small {
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
}
.news-preview__text > h2 {
  margin-bottom: 0;
  margin-top: 0 !important;
  text-transform: uppercase;
}
.news-preview__text > h2:before {
  content: "";
  height: 2px;
  background: #3f3b48;
  width: 90px;
  display: block;
  margin: 15px 0;
}
.news-preview__text > h2 a {
  color: #656565 !important;
  text-decoration: none !important;
}
.news-preview__text > h2 a:hover {
  background: none !important;
}
.news-preview__text > p:last-of-type {
  margin-bottom: 0;
}
.news-preview__text > a {
  display: inline-block;
  margin-top: 15px;
  font-size: 0;
  color: #656565 !important;
}
@media screen and (max-width: 768px) {
  .news-preview__text > a {
    margin-top: 7.5px;
  }
}
.news-preview__text > a:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f30b";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  font-weight: 600;
  font-size: 1rem;
}
.news-preview__text > a:hover {
  background: none !important;
  color: #0068b4 !important;
}

.news-page {
  background: #efefef;
  padding: 30px;
}
@media screen and (max-width: 1036px) {
  .news-page {
    padding: 20px;
  }
}
.news-page__preview-text {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .news-page__preview-text {
    margin-bottom: 15px;
  }
}
.news-page__preview-text p {
  font-size: 1.5rem;
}
.news-page small {
  font-size: 0.75rem;
  font-weight: 600;
}
.news-page small:after {
  content: "";
  height: 2px;
  width: 90px;
  background: #3f3b48;
  display: block;
  margin-bottom: 15px;
  margin-top: 15px;
}
.news-page__text .news-page__image {
  display: block;
  width: 31.6239316239%;
  float: right;
  margin-left: 15px;
  margin-bottom: 7.5px;
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
.news-page__text .news-page__image:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00e";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -60px;
  bottom: 0;
  z-index: 10;
  color: #0068b4;
  background: rgba(37, 37, 37, 0.85);
  text-align: right;
  padding: 15px 19px 0 0;
  font-size: 1.5rem;
  width: 100%;
  transition: all 0.2s linear;
}
.news-page__text .news-page__image:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 4px;
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  background: rgba(0, 104, 180, 0.9);
  transition: all 0.2s linear;
  z-index: 20;
}
.news-page__text .news-page__image:hover:before, .news-page__text .news-page__image:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.news-page__text .news-page__image:hover:after {
  left: 4px;
}
@media screen and (max-width: 480px) {
  .news-page__text .news-page__image {
    width: 100%;
    margin-left: 0;
    margin-bottom: 0;
    float: none;
  }
}
.news-page__text .news-page__image img {
  display: block;
  width: 100%;
  height: auto;
  max-width: unset !important;
}
.news-page__meta {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 2px solid #3f3b48;
  font-size: 0.75rem;
  color: #656565;
}
.news-page__meta a {
  color: #656565;
  text-decoration: none;
}
.news-page__meta a:hover {
  color: #0068b4;
}
.news-page__meta ul {
  margin: 7.5px 0 0 0;
  padding: 0;
}
.news-page__meta ul li {
  display: block;
}
.news-page__meta ul li.news-page__prev {
  float: left;
  font-size: 0;
}
.news-page__meta ul li.news-page__prev a:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f30a";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  font-weight: 600;
  position: relative;
  bottom: -1px;
  font-size: 1rem;
}
.news-page__meta ul li.news-page__next {
  float: right;
  font-size: 0;
}
.news-page__meta ul li.news-page__next a:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f30b";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  font-weight: 600;
  position: relative;
  bottom: -1px;
  font-size: 1rem;
}

.cr_form .cr_ipe_item {
  margin-top: 15px;
}
.cr_form .cr_ipe_item:nth-of-type(-n+2) {
  margin-top: 0;
}
.cr_form .cr_ipe_item label {
  display: block;
  font-weight: 600;
  margin-bottom: 7.5px;
}
.cr_form .cr_ipe_item input[type=checkbox],
.cr_form .cr_ipe_item input[type=radio] {
  display: inline-block;
}

#grasenhiller_productlandingholder .pages {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}
#grasenhiller_productlandingholder .pages .page {
  margin-right: 2.5641025641%;
  cursor: pointer;
  margin-bottom: 30px;
  border: 2px solid #0068b4;
}
@media screen and (min-width: 769px) {
  #grasenhiller_productlandingholder .pages .page {
    width: 23.0769230769%;
  }
  #grasenhiller_productlandingholder .pages .page:nth-of-type(4n+4) {
    margin-right: 0;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  #grasenhiller_productlandingholder .pages .page {
    width: 48.7179487179%;
  }
  #grasenhiller_productlandingholder .pages .page:nth-of-type(2n+2) {
    margin-right: 0;
  }
}
@media screen and (max-width: 480px) {
  #grasenhiller_productlandingholder .pages .page {
    width: 100%;
    margin-right: 0;
  }
}
#grasenhiller_productlandingholder .pages .page img {
  display: block;
  width: 100%;
  height: auto;
  width: calc(100% + 4px);
  margin-left: -2px;
  margin-right: -2px;
  margin-top: -2px;
}
#grasenhiller_productlandingholder .pages .page__content {
  padding: 15px;
}
#grasenhiller_productlandingholder .pages .page__content h3 {
  font-size: 1.2rem;
}
#grasenhiller_productlandingholder .pages .page__content p {
  color: #acacac;
  margin-top: 7.5px;
}
#grasenhiller_productlandingholder .pages .page__content p::after {
  color: #0068b4;
  margin-left: 0.25em;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f178";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
}

#grasenhiller_productlandingpage .page-title {
  display: none;
}
#grasenhiller_productlandingpage main .page-title {
  display: block;
}
#grasenhiller_productlandingpage .page-body {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
#grasenhiller_productlandingpage .page-header {
  margin-top: 30px;
}
#grasenhiller_productlandingpage .page-header img {
  display: block;
  width: 100%;
  height: auto;
}
#grasenhiller_productlandingpage .page-content .page-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media screen and (max-width: 1230px) {
  #grasenhiller_productlandingpage .page-content .page-wrapper {
    flex-direction: column-reverse;
  }
}
#grasenhiller_productlandingpage .page-content .page-sidebar {
  margin-top: 30px;
}
@media screen and (min-width: 1231px) {
  #grasenhiller_productlandingpage .page-content .page-sidebar {
    width: 150px;
  }
}
@media screen and (max-width: 1230px) {
  #grasenhiller_productlandingpage .page-content .page-sidebar {
    position: static;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
}
#grasenhiller_productlandingpage .page-content .page-sidebar a {
  width: 100%;
  margin-bottom: 15px;
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none !important;
}
#grasenhiller_productlandingpage .page-content .page-sidebar a :last-child {
  margin-bottom: 0;
}
#grasenhiller_productlandingpage .page-content .page-sidebar a:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f00e";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: -60px;
  bottom: 0;
  z-index: 10;
  color: #0068b4;
  background: rgba(37, 37, 37, 0.85);
  text-align: right;
  padding: 15px 19px 0 0;
  font-size: 1.5rem;
  width: 100%;
  transition: all 0.2s linear;
}
#grasenhiller_productlandingpage .page-content .page-sidebar a:before {
  visibility: hidden;
  opacity: 0;
  content: "";
  display: block;
  width: 4px;
  position: absolute;
  top: 0;
  left: -4px;
  bottom: 0;
  background: rgba(0, 104, 180, 0.9);
  transition: all 0.2s linear;
  z-index: 20;
}
#grasenhiller_productlandingpage .page-content .page-sidebar a:hover:before, #grasenhiller_productlandingpage .page-content .page-sidebar a:hover:after {
  visibility: visible;
  opacity: 1;
  left: 0;
}
#grasenhiller_productlandingpage .page-content .page-sidebar a:hover:after {
  left: 4px;
}
@media screen and (max-width: 1230px) {
  #grasenhiller_productlandingpage .page-content .page-sidebar a {
    width: 23%;
    height: 100%;
  }
}
#grasenhiller_productlandingpage .page-content .page-sidebar a img {
  display: block;
  width: 100%;
  height: auto;
}
@media screen and (max-width: 1230px) {
  #grasenhiller_productlandingpage .page-content .page-sidebar a img {
    width: 100%;
    height: 100%;
  }
}
#grasenhiller_productlandingpage .page-content .page-sidebar a p {
  padding: 7.5px;
  background: #efefef;
}
#grasenhiller_productlandingpage .page-content .page-text {
  margin-top: 30px;
}
@media screen and (min-width: 1231px) {
  #grasenhiller_productlandingpage .page-content .page-text {
    max-width: calc(100% - 150px - 30px);
  }
}
#grasenhiller_productlandingpage .page-content .page-modules {
  margin-top: 30px;
}
#grasenhiller_productlandingpage .page-content .page-products__item {
  margin-top: 30px;
}
#grasenhiller_productlandingpage .page-content .page-products__item h2 {
  font-size: 1.25rem;
  margin-bottom: 15px;
}
#grasenhiller_productlandingpage .page-content .page-products__item .products-per-page {
  display: none;
}
#grasenhiller_productlandingpage .contact-box {
  border: 2px solid #3f3b48;
  padding: 15px;
  margin-top: 30px;
  background: #efefef;
}

#grasenhiller_attributegroup .main__text {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  #grasenhiller_attributegroup .main__text {
    margin-bottom: 15px;
  }
}

#teampage .main__text {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  #teampage .main__text {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 480px) {
  #teampage .team-members .team-member {
    float: left;
    width: 100%;
    margin-right: 2.5641025641%;
    margin-top: 5.1282051282%;
  }
  #teampage .team-members .team-member:nth-of-type(-n + 1) {
    margin-top: 0;
  }
  #teampage .team-members .team-member:nth-of-type(1n+1) {
    margin-right: 0;
    float: right;
  }
  #teampage .team-members .team-member:nth-of-type(1n+2) {
    clear: both;
  }
}
@media screen and (min-width: 481px) {
  #teampage .team-members .team-member {
    float: left;
    width: 48.7179487179%;
    margin-right: 2.5641025641%;
    margin-top: 2.5641025641%;
  }
  #teampage .team-members .team-member:nth-of-type(-n + 2) {
    margin-top: 0;
  }
  #teampage .team-members .team-member:nth-of-type(2n+2) {
    margin-right: 0;
    float: right;
  }
  #teampage .team-members .team-member:nth-of-type(2n+3) {
    clear: both;
  }
}

@media screen and (min-width: 769px) {
  #silverstripe_userdefinedform .main form {
    float: left;
    margin-right: 2.5641025641%;
    width: 65.811965812%;
    clear: none;
    margin-top: 0;
    padding-right: 7.7922077922%;
  }
  #silverstripe_userdefinedform .main form + .main__text {
    width: 31.6239316239%;
    float: left;
  }
}
@media screen and (max-width: 768px) {
  #silverstripe_userdefinedform .main form + .main__text {
    margin-top: 30px;
  }
}

#grasenhiller_wikiholder .wikiholder-navigation {
  margin-bottom: 45px;
  position: relative;
  padding: 15px;
  border: 1px solid #c3c3c3;
}
@media screen and (max-width: 768px) {
  #grasenhiller_wikiholder .wikiholder-navigation {
    margin-bottom: 22.5px;
  }
}
#grasenhiller_wikiholder .wikiholder-navigation ul {
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
  margin: 0 7.5px;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none !important;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
#grasenhiller_wikiholder .wikiholder-navigation ul::-webkit-scrollbar {
  display: none;
}
#grasenhiller_wikiholder .wikiholder-navigation ul li {
  position: relative;
  flex-grow: 1;
  text-align: center;
  cursor: default;
  color: #acacac;
}
#grasenhiller_wikiholder .wikiholder-navigation ul li:not(:first-of-type) {
  margin-left: 15px;
}
#grasenhiller_wikiholder .wikiholder-navigation ul li a {
  position: relative;
  display: block;
  transition: transform 0.2s ease-in;
  font-weight: 600;
}
#grasenhiller_wikiholder .wikiholder-navigation ul li a:hover {
  cursor: pointer;
  transform: scale(1.5);
}
@media screen and (max-width: 700px) {
  #grasenhiller_wikiholder .wikiholder-navigation {
    padding-right: 39px;
  }
  #grasenhiller_wikiholder .wikiholder-navigation:after {
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    content: "\f061";
    display: inline-block;
    font-style: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 1em;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    right: 0;
    margin-right: 15px;
    font-size: 0.9em;
  }
}
#grasenhiller_wikiholder .wikiholder-items {
  padding: 15px;
}
#grasenhiller_wikiholder .wikiholder-items--grouped:not(:first-child) {
  margin-top: 45px;
}
@media screen and (max-width: 768px) {
  #grasenhiller_wikiholder .wikiholder-items--grouped:not(:first-child) {
    margin-top: 22.5px;
  }
}
#grasenhiller_wikiholder .wikiholder-items--grouped h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  display: inline-block;
  position: relative;
}
#grasenhiller_wikiholder .wikiholder-items--grouped h3:after {
  content: "";
  position: relative;
  min-width: 30px;
  width: 100%;
  height: 2px;
  background: #656565;
  display: block;
  margin-top: 15px;
}
#grasenhiller_wikiholder .wikiholder-items--grouped ul li {
  font-size: 1rem;
  font-weight: 600;
}
#grasenhiller_wikiholder .wikiholder-items--grouped ul li:not(:first-of-type) {
  margin-top: 10px;
}
#grasenhiller_wikiholder .wikiholder-items--grouped ul li a:after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f178";
  display: inline-block;
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  text-align: center;
  margin-left: 0.5em;
  opacity: 0;
  transition: all 0.2s ease-in;
}
#grasenhiller_wikiholder .wikiholder-items--grouped ul li a:hover:after {
  opacity: 1;
  margin-left: 1em;
}
@media screen and (min-width: 481px) {
  #grasenhiller_wikiholder .wikiholder-items--grouped ul {
    column-count: 2;
    column-gap: 15px;
  }
}

#grasenhiller_wikipage .page-media {
  padding-top: 30px;
  border-top: 1px solid #c3c3c3;
}
@media screen and (max-width: 768px) {
  #grasenhiller_wikipage .page-media {
    padding-top: 15px;
  }
}