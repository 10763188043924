.page-title {
	@include resp-margin(bottom);
	@extend %formatted-text;
	background: $box-color-dark;
	color: $page-bg;
	padding: $dist;
	display: none;

	@include breakpoint(null, $bp-m) {
		padding: 20px;
	}

	h1 {
		line-height: 1;
		font-size: $fs-h2;
		text-transform: uppercase;
		font-weight: $fw-h-bold;

		@include breakpoint(null, $bp-m) {
			font-size: $fs-h2;
		}
	}

	p {
		font-size: $fs-h5;

		@include breakpoint(null, $bp-l) {
			font-size: $fs;
		}
	}
	.breadcrumbs {
		margin-bottom: $dist*0.25;
		color: $fc-light;

		a {
			color: $fc-light;

			&:hover {
				color: $ac;
			}
		}
	}
}

.page--no-banner {
	.page-title {
		display: block;
	}
}

/*.page-title {
	@include resp-margin(bottom);
	@extend %cf;

	&__left{
		background: $box-color-dark;
		color: $page-bg;
		padding: $dist;
		@extend %formatted-text;
		width: 50%;
		float: left;

		@include breakpoint(null, $bp-l) {
			padding: 20px;
		}

		@include breakpoint(null, $bp-nav) {
			width: 100%;
		}

		h1 {
			line-height: 1;
			font-size: $fs-h1;
			font-weight: $fw-h-bold;
			text-transform: uppercase;
			font-weight: $fw-h-light;

			@include breakpoint(null, $bp-m) {
				font-size: $fs-h2;
			}

			&:after {
				content: "";
				width: $dist*3;
				height: 2px;
				background: $page-bg;
				display: block;
				margin: $dist 0;
			}
		}

		p {
			font-size: $fs-h5;

			@include breakpoint(null, $bp-l) {
				font-size: $fs;
			}
		}
		.breadcrumbs {
			margin-bottom: $dist/4;
			color: $fc-light;

			a {
				color: $fc-light;

				&:hover {
					color: $ac;
				}
			}
		}
	}

	&__right {
		float: left;
		width: 50%;

		img {
			width: 100%;
			height: 100%;
			min-height: 470px;
		}

		@include breakpoint(null, $bp-nav) {
			width: 100%;
		}
	}
}*/