.products-per-page {
	float: left;
	margin-top: $dist;

	& + .pagination {
		float: left;
		margin-left: $dist;
	}

	label {

	}
}

.products-listing {
	@extend %cf;
}