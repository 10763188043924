@use "sass:math";

.main__text + form {
	@include resp-margin(top);
}

// - Reset styling
button,
input,
textarea,
select {
	font-family: inherit;
	font-weight: inherit;
	font-style: inherit;
	font-size: inherit;
	color: inherit;
	line-height: $line-height;
	outline: 0;
	border: 0;
	box-shadow: none;
}

// - Default styling
label {
	cursor: pointer;

	@include breakpoint(null, $bp-l) {
		font-size: $fs-small;
	}
}

input,
textarea,
select {
	border: 1px solid $border-color;
	background: transparent;

	@include breakpoint(null, $bp-l) {
		font-size: $fs-small;
	}

	&:not([type='checkbox']):not([type='radio']):not([type='submit']):not([type='reset']) {
		display: block;
		padding: $dist*0.25 math.div($dist, 3);
		width: 100%;

		&[disabled] {
			cursor: not-allowed;
			opacity: 0.7;
		}
	}

	&:focus {
		outline: 0;
		border: 1px solid $ac;
	}
}

input[type="submit"],
input[type="reset"] {
	@extend %button;
	width: auto;
}

input[type="reset"] {
	@extend %button--neutral;
}

form {
	.field {
		margin-bottom: $dist*0.5;

		&:last-child {
			margin-bottom: 0;
		}

		.middleColumn {
			@extend %cf;
		}

		&.optionset {
			& > .middleColumn {
				& > div {
					margin-top: $dist*0.25;

					&:first-of-type {
						margin-top: 0;
					}
				}
			}
		}

		.message {
			display: block;
			margin-top: $dist*0.5;
		}

		.description {
			display: block;
			margin-top: $dist*0.25;
			font-size: $fs-small;
		}

		.right-title,
		& > label.right {
			display: block;
			font-size: $fs-small;
			margin-top: $dist*0.25;
			color: $fc-light;
		}

		& > label.left,
		& > legend.left {
			display: block;
			margin-bottom: math.div($dist, 3);
		}

		&.requiredField {
			& > label.left {
				&:after {
					content: ' *';
					color: $invalid-color;
				}
			}
		}
	}

	.Actions {
		margin-top: $dist*0.5;
	}
}

// - Checkbox and Radio
input {
	&[type="checkbox"] {
		display: none;
		cursor: pointer;

		& + label:before {
			margin-right: 0.5em;
			cursor: pointer;
			@include fontawesome($fa-var-square);
			color: $fc;
		}

		&:checked + label:before {
			@include fontawesome($fa-var-check-square, regular);
		}

		&[disabled] {
			cursor: not-allowed;

			& + label:before {
				cursor: not-allowed;
				color: $fc-light;
			}
		}
	}

	&[type="radio"] {
		display: none;
		cursor: pointer;

		& + label:before {
			margin-right: 0.5em;
			cursor: pointer;
			@include fontawesome($fa-var-circle);
			color: $fc;
		}

		&:checked + label:before {
			@include fontawesome($fa-var-dot-circle, regular);
		}

		&[disabled] {
			cursor: not-allowed;

			& + label:before {
				cursor: not-allowed;
				color: $fc-light;
			}
		}
	}
}

//  - Special forms
#MemberLoginForm_LoginForm,
#MemberLoginForm_LostPasswordForm {
	@include breakpoint($bp-m, null) {
		width: 50%;
	}
}

#MemberLoginForm_LoginForm {
	#ForgotPassword {
		margin-top: $dist*0.25;
	}
}