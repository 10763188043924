.module--downloads {
  ul {
    margin: 0;
    padding: 0;

    li {
      @extend %cf;
      list-style: none !important;
      display: block;
      border-bottom: 1px dashed $border-color;
      margin-bottom: $dist*0.25;
      padding-bottom: $dist*0.25;

      &:last-of-type {
        border-bottom: 0;
        margin-bottom: 0;
      }

      a {
        display: block;
        width: calc(100% - 100px);
        float: left;
        color: $fc;
        text-decoration: none;

        &:hover {
          color: $ac;
        }

        i {
          margin-right: 0.5em;
          font-size: $fs-h3;
          color: $ac;
        }
      }

      span {
        display: block;
        width: 100px;
        text-align: right;
        float: right;
        color: $fc-light;
      }
    }
  }
}