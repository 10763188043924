@use "sass:math";

.module--grid {
  .grid {
    @extend %cf;

    &__item {
      display: block;
      float: left;
      border: 3px solid $border-color-dark;
      padding: $dist*0.5;
    }

    &__header {
      text-align: center;
      margin-bottom: $dist*0.5;

      img {
        display: inline-block;
        width: 100%;
        height: auto;
      }

      i {
        font-size: $fs-h1;
      }

      a {
        color: $fc;

        &:hover {
          color: $ac;
        }
      }
    }

    &__title {
      margin-bottom: $dist*0.5;

      h4 {
        font-size: $fs-tiny;
        text-transform: uppercase;
      }

      &:after {
        content: "";
        width: $dist*3;
        height: 2px;
        background: $box-color-dark;
        display: block;
        margin-top: $dist*0.5;
      }
    }

    &__link {
      display: inline-block;
      margin-top: $dist*0.5;
      text-decoration: none;
    }

    &--align-center {
      text-align: center;
    }

    &--ipr-2 {
      .grid__item {
        $grid-conf: (
          (null, $bp-s, 1, 2),
          ($bp-s, null, 2, 2)
        );

        @include resp-gallery-layout($grid-conf);
      }
    }

    &--ipr-3 {
      .grid__item {
        $grid-conf: (
          (null, $bp-s, 1, 2),
          ($bp-s, $bp-m, 2, 2),
          ($bp-m, null, 3, 2)
        );

        @include resp-gallery-layout($grid-conf);
      }
    }

    &--ipr-4 {
      .grid__item {
        $grid-conf: (
          (null, $bp-s, 1, 2),
          ($bp-s, $bp-m, 2, 2),
          ($bp-m, null, 4, 2)
        );

        @include resp-gallery-layout($grid-conf);
      }
    }

    &--as-slider {
      border: 3px solid $border-color-dark;
      padding: $dist $dist*2.5;

      a.grid__link {
        color: $ac;

        &:hover {
          color: $ac-hover;
        }

        &:after {
          @include fontawesome($fa-var-long-arrow-right);
          margin-left: 0.5em;
        }
      }

      .grid__item {
        border: 0;
        padding: 0;
      }

      .grid__title {
        h4 {
          font-size: $fs-h4;
        }
      }

      .slick-list {
        margin-right: - gutter(12)*0.5;
        margin-left: - gutter(12)*0.5;
      }

      .slick-arrow {
        position: absolute;
        @include center(v);
        transition: color $duration linear;

        color: #ffffff;
        font-size: $fs-small;
        padding: $dist*0.5 17px;
        background: $box-color-dark;

        &.slick-disabled {
          color: $fc-light;
          cursor: default;
        }

        &:not(.slick-disabled) {
          cursor: pointer;

          &:hover {
            background: $ac;
          }
        }

        &.fa-chevron-left {
          left: 0;
        }

        &.fa-chevron-right {
          right: 0;
        }
      }

      .slick-dots {
        margin-top: $dist*0.5;
        font-size: 0;
        text-align: center;

        li {
          display: inline-block;
          margin-right: 2px;

          &:last-of-type {
            margin-right: 0;

            &:first-of-type {
              display: none;
            }
          }

          button {
            cursor: pointer;
            display: block;
            width: math.div($dist, 2.5);
            height: math.div($dist, 2.5);
            background: $box-color;
            border-radius: 100%;
            font-size: 0;
            border: 0;
            box-shadow: none;
            line-height: 1;

            &:hover {
              background: $box-color-dark;
            }
          }

          &.slick-active {
            button {
              background: $hc;

              &:hover {
                background: darken($hc, 10%);
              }
            }
          }
        }
      }
    }
  }

  .grid__content-wrapper {
    @extend %cf;

    .grid__header {
      float: right;
      width: span(3 of 12);

      img {
        @include resp-img;
      }
    }

    .grid__content {
      float: left;
      width: span(9 of 12);
      margin-right: gutter(12);
    }
  }
}