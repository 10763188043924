.latest-news {
  & > h2 {
    @include resp-margin(bottom);
  }

  & > a {
    @include resp-margin(top);
  }

  &__children {
    @extend %cf;
  }

  &__child {
    $grid-conf: (
                    (null, $bp-s, 1, 2),
                    ($bp-s, $bp-m, 2),
                    ($bp-m, null, 3)
    );

    @include resp-gallery-layout($grid-conf);

    & > a {
      display: block;
      @include resp-margin(bottom, 0.5);
      @include image-hover($fa-var-long-arrow-right);
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    h3 {
      @include resp-margin(bottom, 0.5);
    }
  }
}