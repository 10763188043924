.modular-content-column {
  width: span(6 of 12);
  margin-right: gutter(12);
  float: left;
  opacity: 0;

  @include breakpoint(null, $bp-columns) {
    width: 100%;
    margin-right: 0;
    float: none;

    & + .modular-content-column {
      margin-top: $dist;
    }
  }

  & + .modular-content-column {
    margin-right: 0;
  }

  .module {
    &__title {
      margin-bottom: calc(#{$dist} - 0.5rem);
    }

    &__inner {
      width: 100% !important;
    }

    &--bg-dark,
    &--bg-light {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      background: #ffffff !important;

      .module__title {
        h3 {
          color: $fc;
        }

        strong {
          color: $fc-light;
        }
      }

      .textimage__title,
      .formatted-text {
        color: $fc;
      }

      .textimage__sub-title,
      .text--discreet {
        color: $fc-light !important;
      }

      &.module--opinions {
        .opinion {
          background: #ffffff;
        }
      }

      &.module--accordion {
        .accordion__item {
          border-color: $border-color;
        }

        .accordion__item--open .accordion__title {
          & {
            background: $box-color;
          }

          i {
            color: $ac;
          }
        }

        .accordion__item--open .accordion__title,
        .accordion__title {
          color: $fc;

          &:hover {
            background: $box-color;
          }
        }
      }

      .text--background-discreet {
        color: $fc;
      }

      &.module--grid {
        .grid__item {
          background: $box-color;
        }

        .grid__content,
        .grid__title {
          color: $fc;
        }
      }

      &.module--downloads {
        ul li {
          border-bottom-color: $border-color;

          & > a {
            & {
              color: $fc;
            }

            i {
              color: $ac;
            }
          }
        }
      }

      &.module--newsletter .newsletter-form {
        &,
        & * {
          color: $fc;

          &.cr_button {
            color: #ffffff !important;
          }
        }
      }
    }

    &:last-of-type {
      &.module--bg-light,
      &.module--bg-dark {
        margin-bottom: 0 !important;
      }
    }

    &--bg-light + .module--bg-light,
    &--bg-light + .module--bg-dark,
    &--bg-dark + .module--bg-light,
    &--bg-dark + .module--bg-dark {
      margin-top: 0 !important;
    }
  }
}